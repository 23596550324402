import { createSlice } from '@reduxjs/toolkit';
import { index } from 'd3-array';
import axiosRequest from 'src/utils/axiosRequest';

const monthNames = {
  1: 'janvier',
  2: 'février',
  3: 'mars',
  4: 'avril',
  5: 'mai',
  6: 'juin',
  7: 'juillet',
  8: 'août',
  9: 'septembre',
  10: 'octobre',
  11: 'novembre',
  12: 'décembre'
};

function secondsToHMS(totalSeconds) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${hours}h : ${minutes}m : ${seconds}s`;
}

function calculateTotalOnlineTime(timers) {
  let totalOnlineTime = 0;
  let lastOnlineTimestamp = null;

  for (const timer of timers) {
    if (timer.state === 'online') {
      lastOnlineTimestamp = timer.last_changed;
    } else if (timer.state === 'offline' && lastOnlineTimestamp) {
      const onlineTimeInSeconds =
        timer.last_changed._seconds -
        lastOnlineTimestamp._seconds +
        (timer.last_changed._nanoseconds - lastOnlineTimestamp._nanoseconds) / 1e9;

      totalOnlineTime += onlineTimeInSeconds;

      lastOnlineTimestamp = null;
    }
  }

  return totalOnlineTime;
}

function calculateOnlineTimePerDay(timers) {
  let onlineTimePerDay = {};
  let lastOnlineTime = null;

  timers.forEach((timer) => {
    const currentTime = timer.last_changed._seconds;
    const currentDate = new Date(currentTime * 1000).toISOString().split('T')[0];

    if (timer.state === 'online') {
      lastOnlineTime = currentTime;
    } else if (timer.state === 'offline' && lastOnlineTime) {
      const timeSpentOnline = currentTime - lastOnlineTime;

      if (onlineTimePerDay[currentDate]) {
        onlineTimePerDay[currentDate] += timeSpentOnline;
      } else {
        onlineTimePerDay[currentDate] = timeSpentOnline;
      }

      lastOnlineTime = null;
    }
  });

  return onlineTimePerDay;
}

const initialState = {
  isLoading: false,
  error: false,
  monitoring_key: null,
  monitoring_user: null,
  monitorings: [],
  groupByUserByMonth: null,
  groupByUserByDay: null
};

const slice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateMonitoringKey(state, action) {
      state.monitoring_key = action.payload;
    },

    // GET MONITORING

    upldateSelectUSerMonitoring(state, action) {
      state.isLoading = false;
      state.monitoring_user = action.payload;
    },

    groupMonitoringByUserByMonth(state) {
      state.isLoading = false;

      const monitorings = state.monitorings;

      state.groupByUserByMonth = monitorings.map((monitoring) => {
        const times = monitoring.times;
        const monthTimes = [...(times || [])].map((time) => {
          const totalOnlineTime = calculateTotalOnlineTime(time.timers);
          const formattedTime = secondsToHMS(totalOnlineTime);

          const [month, year] = time.id.split('_');
          const monthText = monthNames[parseInt(month)];

          return {
            month: `${monthText} ${year}`,
            times: formattedTime,
            year: parseInt(year),
            monthNumber: parseInt(month)
          };
        });

        const monthTimesSorted = monthTimes.sort((a, b) => {
          if (b.year === a.year) {
            return b.monthNumber - a.monthNumber;
          }

          return b.year - a.year;
        });

        return {
          ...monitoring,
          times: monthTimesSorted.map((time, index) => ({ id: index, ...time }))
        };
      });
    },

    groupMonitoringByUserByDay(state) {
      state.isLoading = false;

      const monitorings = state.monitorings;

      const groupMonitiring = monitorings.map((monitoring) => {
        const times = monitoring.times;

        const monthTimes = [...(times || [])].map((time) => {
          const totalOnlineTime = calculateOnlineTimePerDay(time.timers);

          const formattedTime = Object.entries(totalOnlineTime).map(([key, value]) => {
            const [year, month, day] = key.split('-');
            const monthText = monthNames[parseInt(month)];

            return {
              date: `${day} ${monthText} ${year}`,
              year: parseInt(year),
              month: parseInt(month),
              day: parseInt(day),
              times: secondsToHMS(value)
            };
          });

          return formattedTime;
        });

        const monthTimesSorted = monthTimes
          .flatMap((time) => time)
          .sort((a, b) => {
            if (b.year === a.year) {
              if (b.month === a.month) {
                return b.day - a.day;
              }
              return b.month - a.month;
            }
            return b.year - a.year;
          });

        return {
          ...monitoring,
          times: monthTimesSorted.map((time, index) => ({ id: index, ...time }))
        };
      });

      state.groupByUserByDay = groupMonitiring;
    },

    getMonitoringSuccess(state, action) {
      state.isLoading = false;
      state.monitorings = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateMonitoringKey,
  groupMonitoringByUserByMonth,
  groupMonitoringByUserByDay,
  upldateSelectUSerMonitoring
} = slice.actions;

export const getMonitoring = () => async (dispatch, getState) => {
  dispatch(slice.actions.startLoading());
  try {
    const monitoring_key = getState().monitoring.monitoring_key;
    const monitorings = await axiosRequest.get(`/monitoring?key=${monitoring_key}`);
    dispatch(slice.actions.getMonitoringSuccess(monitorings.times));
  } catch (error) {
    console.error(error.response);
    dispatch(slice.actions.hasError(error.response));
  }
};
