// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const DOC_DASHBORD = '/doc';
const PROJECT_DASHBORD = '/projects';
const Mail_DASHBOARD = '/mail';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  audienceAppointment: path(ROOTS_AUTH, '/audience-appointment/')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  edit_document: '/document-edit',
  video: {
    root: '/video',
    conversation: '/video/:roomId'
  }
};

export const DOC_PATH = {
  root: DOC_DASHBORD,
  recent: path(DOC_DASHBORD, '/recent'),
  shareWithMe: path(DOC_DASHBORD, '/share-with-me'),
  myShare: path(DOC_DASHBORD, '/share-by-me'),
  folders: path(DOC_DASHBORD, '/folders'),
  trash: path(DOC_DASHBORD, '/trash'),
  favorit: path(DOC_DASHBORD, '/favorite'),
  tags: path(DOC_DASHBORD, '/tags'),
  my_auth: path(DOC_DASHBORD, '/send-auth'),
  auth: path(DOC_DASHBORD, '/auth-request')
};

export const MAIL_PATH = {
  root: Mail_DASHBOARD
};

export const PROJECT_PATH = {
  root: PROJECT_DASHBORD,
  projects: PROJECT_DASHBORD,
  projectsTask: path(PROJECT_DASHBORD, '/tasklist'),
  tasks: path(PROJECT_DASHBORD, '/tasks'),
  agenda: path(PROJECT_DASHBORD, '/agenda')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    accueil: {
      root: path(ROOTS_DASHBOARD, '/accueil'),
      fiche: path(ROOTS_DASHBOARD, '/accueil/fiche'),
      audience: path(ROOTS_DASHBOARD, '/accueil/audience'),
      stage: path(ROOTS_DASHBOARD, '/accueil/stage')
    },
    affectation: path(ROOTS_DASHBOARD, '/affectation'),
    contacts: {
      root: path(ROOTS_DASHBOARD, '/contact'),
      new: path(ROOTS_DASHBOARD, '/contact/new'),
      list: path(ROOTS_DASHBOARD, '/contact/list')
    },
    report: path(ROOTS_DASHBOARD, '/report'),
    tasks: path(ROOTS_DASHBOARD, '/task'),
    taskscalendaire: path(ROOTS_DASHBOARD, '/task-calendar'),
    tasksProject: path(ROOTS_DASHBOARD, '/task/:projectId'),
    opportunity: {
      root: path(ROOTS_DASHBOARD, '/opportunity'),
      new: path(ROOTS_DASHBOARD, '/opportunity/new')
    },
    oldtask: {
      root: path(ROOTS_DASHBOARD, '/oldtask'),
      new: path(ROOTS_DASHBOARD, '/oldtask/new')
    },
    taskRecap: {
      root: path(ROOTS_DASHBOARD, '/recap')
    },
    workflow: {
      root: path(ROOTS_DASHBOARD, '/workflow')
    },
    gestionSuivit: {
      root: path(ROOTS_DASHBOARD, '/gestion-Suivit'),
      new: path(ROOTS_DASHBOARD, '/gestion-Suivit-new')
    },
    billings: path(ROOTS_DASHBOARD, '/billings'),
    project: {
      mytask: path(ROOTS_DASHBOARD, '/task/my-tasks'),
      myworkspace: path(ROOTS_DASHBOARD, '/task/my-workspace')
    },
    meeting_survey: {
      root: path(ROOTS_DASHBOARD, '/meeting_survey')
    },

    archive: {
      root: path(ROOTS_DASHBOARD, '/archive')
    }
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },

  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  monitoring: {
    root: path(ROOTS_DASHBOARD, '/monitoring')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
    editPost: path(ROOTS_DASHBOARD, '/blog/edit-post/:id')
  },
  document: {
    root: path(ROOTS_DASHBOARD, '/document'),
    myFolders: path(ROOTS_DASHBOARD, '/doc/folders'),
    shareWithMe: path(ROOTS_DASHBOARD, '/doc/share-with-me'),
    folder: [
      path(ROOTS_DASHBOARD, '/document')
      // path(ROOTS_DASHBOARD, '/document/:folderId')
    ]
  },
  gestion_client: {
    root: path(ROOTS_DASHBOARD, '/clients')
  },

  notifications: {
    root: path(ROOTS_DASHBOARD, '/notifications')
  }
};
