import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggleV2 } from 'src/hooks/useToggle';
import {
  addFileAction,
  addFolderAction,
  copyElementAction,
  createTagAction,
  editTagAction,
  makeElementAsPrivateOrPublic,
  moveElementAction,
  restoreFromTrashElementAction,
  shareElementAction,
  toTrashElementAction,
  updateElementFavorisAction,
  updateElementNameAction,
  updateElementTagsAction,
  wipeTrashElementsAction,
  updateElementDescriptionAction,
  removeSharedUserAction,
  updateElementAction,
  downloadItems,
  sendAutorizationForElement,
  updateAutorizationForElement,
  updateAuthorizationState,
  getFolderById,
  removeAutorizationForElement,
  resendAutorizationForElement
} from 'src/redux/slices/driver/driver';
import { dispatch } from 'src/redux/store';
import { multipleFilesSave } from 'src/redux/slices/document';
import UploadingFileView from '../annexes/UploadingFileView';

import {
  V4_DOC_DRIVER_SORT,
  V4_DOC_DRIVER_SORT_ORDER,
  V4_DOC_DRIVER_FILTER_TYPE
} from 'src/constants/docDriverConstant';
import ZippingFilesView from '../annexes/ZippingFilesView';
import { nanoid } from '@reduxjs/toolkit';
import { ZIPPING_STATES } from '../annexes/helpers';
import useOnlyOffice from 'src/hooks/useOnlyOffice';
import DriverFileViewer from '../views/folder/body/DriverFileViewer';
import { IOkyDriverFile } from 'src/models/IOkyDriver';
import { isEmpty } from 'lodash';

const DocDriverProvider = createContext({
  items: [],
  rename: null,
  details: null,
  /**
   * @type {
   * Array<IOkyDriverFile | IOkyDriverFolder>
   * }
   * */
  selection: [],
  topLevel: null,
  docPath: '/doc',
  currentTag: null,
  editingDoc: null,
  setItems: () => {},
  viewDocument: null,
  loadingEdting: false,
  openNewFolder: false,
  menuContextuel: null,
  currentFolderId: null,
  defaultFolderId: null,
  setTopLevel: () => {},
  setSort: (type) => {},
  setRename: (item) => {},
  handleToggleAll: () => {},
  OnOpenNewFolder: () => {},
  closeViewDocument: () => {},
  onCloseNewFolder: () => {},
  onDownloadElement: () => {},
  setCurrentTag: (item) => {},
  removeDownloading: (id) => {},
  handleSelection: (item) => {},
  handleCloseSelections: () => {},
  handleShowDetails: (item) => {},
  sortType: V4_DOC_DRIVER_SORT.NAME,
  setMenuContextuel: (anchor) => {},
  handleOpenFolder: (folderId) => {},
  handleFilterTypeChange: (type) => {},
  setCurrentFolderId: (folderId) => {},
  setDefaultFolderId: (folderId) => {},
  updateDownloading: (id, values) => {},
  sortOrder: V4_DOC_DRIVER_SORT_ORDER.ASC,
  filterType: V4_DOC_DRIVER_FILTER_TYPE.ALL,
  /**
   * @param {IOkyDriverFile} item
   * @param {'edit' | 'view'} [openMode]
   * */
  onOpenViewDocument: (item, openMode) => {},
  onHandleUploadFolders: (files) => {},
  addTag: ({ tag, onSuccess, onError }) => {},
  addToTrash: ({ elements, onSuccess, onError }) => {},
  wipeFromTrash: ({ elements, onSuccess, onError }) => {},
  restoreFromTrash: ({ elements, onSuccess, onError }) => {},
  handleDownloadElement: ({ element, onSuccess, onError }) => {},
  addFolder: ({ name, parentId = null, onSuccess, onError }) => {},
  copyElements: ({ elements, parentId, onSuccess, onError }) => {},
  moveElements: ({ elements, parentId, onSuccess, onError }) => {},
  addTagsToElement: ({ elementId, tags, onSuccess, onError }) => {},
  shareElement: ({ element, sharedWith, onSuccess, onError }) => {},
  removeSharedUser: ({ element, userId, onSuccess, onError }) => {},
  updateElementName: ({ elementId, name, onSuccess, onError }) => {},
  addFile: ({ files, folderId, onSuccess, onError, allSuccess }) => {},
  updateTag: ({ tag: { id, label, color }, onSuccess, onError }) => {},
  updateSharedUser: ({ element, sharedWith, onSuccess, onError }) => {},
  updateElementDescription: ({ elementId, description, onSuccess, onError }) => {},
  updateElementFavoris: ({ favorite: { elementId, favoris }, onSuccess, onError }) => {},
  handleResendAuthorization: ({ element, item, autorisationType, onSuccess, onError }) => {},
  handleRemoveAuthorization: ({ element, item, autorisationType, onSuccess, onError }) => {},
  makeAsPublicOrPrivate: ({ element, privacy = [], isPublic = false, onSuccess, onError }) => {},
  handleSendAuthorizationDemand: ({ element, sharedWith, autorisationType, onSuccess, onError }) => {},
  handleUpdateAuthorizationState: ({ element, autorisation, autorisationType, onSuccess, onError }) => {},
  handleUpdateAuthorization: ({
    element,
    sendId,
    autorisationType,
    value,
    note,
    attachements,
    onSuccess,
    onError
  }) => {}
});

export const useDriverContext = () => useContext(DocDriverProvider);

export default function DriverContext({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [viewDocument, handleOpenViewDocument, closeViewDocument] = useToggleV2();
  const { loading: loadingEdting, doc: editingDoc } = useOnlyOffice(viewDocument?.item?.id || null);

  //#region STATE
  const { pathname } = useLocation();
  const [items, setItems] = useState([]);
  const [rename, setRename] = useState(null);
  const [details, setDetails] = useState(null);
  const [selection, setSelection] = useState([]);
  const [currentTag, setCurrentTag] = useState(null);
  const [topLevel, setTopLevel] = useState(user.uid);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [menuContextuel, setMenuContextuel] = useState(null);
  const [downloadingFiles, setDownloadingFiles] = useState({});
  const [showUpload, onShowUpload, onCloseUpload] = useToggleV2();
  const [currentFolderId, setCurrentFolderId] = useState(user.uid);
  const [defaultFolderId, setDefaultFolderId] = useState(user.uid);
  const [sortType, setSortType] = useState(V4_DOC_DRIVER_SORT.NAME);
  const [openNewFolder, OnOpenNewFolder, onCloseNewFolder] = useToggleV2();
  const [sortOrder, setSortOrder] = useState(V4_DOC_DRIVER_SORT_ORDER.ASC);
  const [filterType, setFilterType] = useState(V4_DOC_DRIVER_FILTER_TYPE.ALL);
  const [uploadLenght, setUploadLenght] = useState({});
  //#endregion

  //#region ACTION
  const docPath = useMemo(() => {
    const split = pathname.split('/');
    if (split.length > 2) {
      return `${split[0]}/${split[1]}/${split[2]}`;
    }
    return pathname;
  }, [pathname]);

  useEffect(() => {
    setSelection([]);
    setDetails(null);
  }, [pathname]);
  //#endregion

  //#region HANDLER
  const handleToggleAll = () => {
    if (selection.length === items.length) {
      return setSelection([]);
    }
    setSelection(items);
  };

  const handleSelection = (item) => {
    const cloneSelection = [...selection];

    const existIndex = cloneSelection.findIndex((one) => one?.id === item?.id);

    if (existIndex !== -1) {
      cloneSelection.splice(existIndex, 1);
      return setSelection(cloneSelection);
    }
    setSelection((old) => [...old, item]);
  };

  const handleOpenFolder = (folderId) => {
    setSelection([]);
    navigate(`${docPath}/${folderId}`);
  };

  const handleCloseUpload = () => {
    onCloseUpload();
    setUploadingFiles([]);
  };

  const handleShowDetails = (_details) => {
    setDetails(_details);
    if (_details === null) setSelection([]);
  };

  const handleCloseSelections = () => setSelection([]);

  const onDownloadElement = () => {
    const downloadId = nanoid();
    setDownloadingFiles((old) => ({ ...old, [downloadId]: { state: ZIPPING_STATES.START, items: selection } }));
  };

  const updateDownloading = (id, values) => {
    setDownloadingFiles((old) => ({ ...old, [id]: { ...values } }));
  };

  const removeDownloading = (id) => {
    const data = { ...downloadingFiles };
    delete data[id];
    setDownloadingFiles(data);
  };

  const handleCloseDownloading = () => {
    setDownloadingFiles({});
  };

  const onOpenViewDocument = (item, openMode = 'view') => {
    handleOpenViewDocument({ item, openMode });
  };
  //#endregion

  //#region DISPATCHER
  const addFolder = ({ name, parentId = null, onSuccess, onError }) => {
    dispatch(addFolderAction({ parentId: parentId || currentFolderId, name, items, onSuccess, onError }));
  };

  const updateElementName = ({ elementId, name, onSuccess, onError }) => {
    dispatch(updateElementNameAction({ elementId, name, onSuccess, onError }));
  };

  const updateElementDescription = ({ elementId, description, onSuccess, onError }) => {
    dispatch(updateElementDescriptionAction({ elementId, description, onSuccess, onError }));
  };

  const updateElementFavoris = ({ favorite, onSuccess, onError }) => {
    const _callback = () => {
      onSuccess();
      setSelection([]);
    };
    dispatch(updateElementFavorisAction({ favorite, onSuccess: _callback, onError }));
  };

  const addFile = ({ files, folderId, onSuccess, onError, allSuccess }) => {
    const onSave = (allUploads) => {
      allSuccess(allUploads);
    };

    const _parentId = folderId || currentFolderId;

    const onEveryFileUpload = (file) => {
      dispatch(
        addFileAction({
          parentId: _parentId,
          name: file?.name,
          url: file?.url,
          type: file?.type,
          size: file?.size,
          items,
          onSuccess,
          onError
        })
      );
    };

    onShowUpload();

    multipleFilesSave(files, onSave, null, setUploadingFiles, `okydook_driver/${_parentId}`, onEveryFileUpload, true);
  };

  const copyElements = ({ elements, parentId, onSuccess, onError }) => {
    dispatch(copyElementAction({ items: elements, parentId, onSuccess, onError }));
  };
  const moveElements = ({ elements, parentId, onSuccess, onError }) => {
    dispatch(moveElementAction({ items: elements, parentId, onSuccess, onError }));
  };

  const addToTrash = ({ elements, onSuccess, onError }) => {
    dispatch(toTrashElementAction({ items: elements, onSuccess, onError }));
  };

  const restoreFromTrash = ({ elements, onSuccess, onError }) => {
    dispatch(restoreFromTrashElementAction({ items: elements, onSuccess, onError }));
  };

  const wipeFromTrash = ({ elements, onSuccess, onError }) => {
    dispatch(wipeTrashElementsAction({ items: elements, onSuccess, onError }));
  };

  const shareElement = ({ element, sharedWith, onSuccess, onError }) => {
    dispatch(shareElementAction({ item: element, sharedWith, onSuccess, onError }));
  };

  const removeSharedUser = ({ element, userId, onSuccess, onError }) => {
    dispatch(removeSharedUserAction({ item: element, userId, onSuccess, onError }));
  };

  const updateSharedUser = ({ element, sharedWith, onSuccess, onError }) => {
    dispatch(updateElementAction({ item: element, sharedWith, onSuccess, onError }));
  };

  const addTag = ({ tag, onSuccess, onError }) => {
    dispatch(createTagAction({ tag, onSuccess, onError }));
  };

  const updateTag = ({ tag, onSuccess, onError }) => {
    dispatch(editTagAction({ tag, onSuccess, onError }));
  };

  const addTagsToElement = ({ elementId, tags, onSuccess, onError }) => {
    dispatch(updateElementTagsAction({ elementId, tags, onSuccess, onError }));
  };

  const setSort = (type) => {
    setSortType(type);
    setSortOrder(
      sortOrder === V4_DOC_DRIVER_SORT_ORDER.ASC ? V4_DOC_DRIVER_SORT_ORDER.DESC : V4_DOC_DRIVER_SORT_ORDER.ASC
    );
  };

  const handleFilterTypeChange = (type) => {
    setFilterType(type);
  };

  const makeAsPublicOrPrivate = ({ element, privacy, isPublic, onSuccess, onError }) => {
    dispatch(makeElementAsPrivateOrPublic({ item: element, privacy, isPublic, onSuccess, onError }));
  };

  const handleDownloadElement = ({ element, onSuccess, onError }) => {
    dispatch(downloadItems({ item: element, callback: onSuccess, onError }));
  };

  const handleSendAuthorizationDemand = ({ element, sharedWith, autorisationType, onSuccess, onError }) => {
    dispatch(sendAutorizationForElement({ item: element, sharedWith, autorisationType, onSuccess, onError }));
  };

  const handleUpdateAuthorizationState = ({ element, autorisation, autorisationType, onSuccess, onError }) => {
    dispatch(updateAuthorizationState({ element, autorisationType, autorisation, onSuccess, onError }));
  };

  const handleRemoveAuthorization = ({ element, item, autorisationType, onSuccess, onError }) => {
    dispatch(removeAutorizationForElement({ element, item, autorisationType, onSuccess, onError }));
  };

  const handleUpdateAuthorization = ({
    element,
    sendId,
    autorisationType,
    value,
    note,
    attachements,
    onSuccess,
    onError
  }) => {
    dispatch(
      updateAutorizationForElement({ element, sendId, autorisationType, value, note, attachements, onSuccess, onError })
    );
  };

  const handleResendAuthorization = ({ element, item, autorisationType, onSuccess, onError }) => {
    dispatch(resendAutorizationForElement({ element, item, autorisationType, onSuccess, onError }));
  };

  //#endregion

  //#region FOLDER AND FILE UPLAPDING
  const buildFolderStructure = (currentFolder, filePath, currentFile) => {
    const folders = (filePath || []).slice(0, -1);

    if (isEmpty(folders)) {
      return;
    }

    let current = currentFolder;

    if (folders?.length) {
      folders.forEach((folderName, index) => {
        if (!current[folderName]) {
          current[folderName] = { files: [], subFolders: {} };
        }

        if (index === folders.length - 1) {
          current[folderName].files.push(currentFile);
        } else {
          current = current[folderName].subFolders;
        }
      });
    }
  };

  const onFolderUploading = ({ folderStructure, parentId }) => {
    let currentParentId = parentId;

    if (Object.values(folderStructure)?.length) {
      Object.keys(folderStructure).forEach((folderName) => {
        const currentFolder = folderStructure[folderName];
        const subFolders = currentFolder?.subFolders;
        const currentFiles = currentFolder?.files;

        addFolder({
          name: folderName,
          parentId: currentParentId,
          onSuccess: (folderId) => {
            currentParentId = folderId;

            if (currentFiles?.length) {
              addFile({
                files: currentFiles,
                folderId,
                allSuccess: () => {
                  if (Object.values(subFolders)?.length) {
                    onFolderUploading({ folderStructure: subFolders, parentId: currentParentId });
                  }
                },
                onError: () => {}
              });
            } else {
              if (Object.values(subFolders)?.length) {
                onFolderUploading({ folderStructure: subFolders, parentId: currentParentId });
              }
            }
          }
        });
      });
    }
  };

  const handleOnDropFiles = (files = []) => {
    const folderStructure = {};
    let currentParentId = currentFolderId;

    Array.from(files).forEach((file) => {
      const filePath = file.webkitRelativePath.split('/');
      buildFolderStructure(folderStructure, filePath, file);
    });

    onFolderUploading({ folderStructure, parentId: currentParentId });
  };
  //#endregion

  const store = {
    items,
    rename,
    addTag,
    docPath,
    details,
    addFile,
    setSort,
    setItems,
    topLevel,
    sortType,
    addFolder,
    setRename,
    updateTag,
    sortOrder,
    filterType,
    selection,
    addToTrash,
    currentTag,
    editingDoc,
    setTopLevel,
    shareElement,
    copyElements,
    moveElements,
    viewDocument,
    wipeFromTrash,
    setCurrentTag,
    openNewFolder,
    loadingEdting,
    menuContextuel,
    currentFolderId,
    handleSelection,
    OnOpenNewFolder,
    handleToggleAll,
    defaultFolderId,
    handleOpenFolder,
    updateSharedUser,
    removeSharedUser,
    addTagsToElement,
    onCloseNewFolder,
    restoreFromTrash,
    setMenuContextuel,
    closeViewDocument,
    updateDownloading,
    removeDownloading,
    onDownloadElement,
    updateElementName,
    handleShowDetails,
    setDefaultFolderId,
    onOpenViewDocument,
    setCurrentFolderId,
    updateElementFavoris,
    handleCloseSelections,
    makeAsPublicOrPrivate,
    handleDownloadElement,
    handleFilterTypeChange,
    updateElementDescription,
    handleUpdateAuthorization,
    handleRemoveAuthorization,
    handleResendAuthorization,
    handleUpdateAuthorizationState,
    handleSendAuthorizationDemand,
    onHandleUploadFolders: handleOnDropFiles
  };

  return (
    <DocDriverProvider.Provider value={store}>
      {children}
      {Boolean(uploadingFiles.length && showUpload) ? (
        <UploadingFileView totalFiles={uploadingFiles.length} uploadings={uploadingFiles} onClose={handleCloseUpload} />
      ) : null}
      {Boolean(Object.entries(downloadingFiles).length) ? (
        <ZippingFilesView selection={downloadingFiles} onClose={handleCloseDownloading} />
      ) : null}
      {Boolean(viewDocument) && (
        <DriverFileViewer
          onClose={closeViewDocument}
          open={Boolean(viewDocument)}
          item={viewDocument?.item}
          openMode={viewDocument?.openMode}
        />
      )}
    </DocDriverProvider.Provider>
  );
}
