import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

import { keys, isFunction } from 'lodash';
import { MICROSOFT_CONFIG } from 'src/config';
import { gDate } from 'src/utils/formatTime';
import {
  deleteMsMail,
  disconnectAccount,
  getMails,
  getMsMailAttachment,
  markMailAsRead,
  persistToken,
  sendMsMail,
  toggleMsMailImportance,
  updateAccountData,
  updateMailsInFolder,
  authCustomMail,
  updateCustomMailsOnFolder,
  saveToDraft,
  updateDraftMail,
  deleteDraftMail,
  markMailAsUnread,
  updateCustomMailFolder,
  updateFlag
} from 'src/redux/slices/customMail';
import { popupWindow } from 'src/utils/windows';
import { MAIL_FLAG } from 'src/section/mail/MailContext';

const CustomMailContext = createContext({
  accounts: [],
  mailsSnap: {},
  draftsSnap: {},
  folderSnap: {},
  loginBtnProps: {},
  updateAccount: (email = '', data = {}) => Promise.all(),

  loadingSnap: [],
  loginCustomMail: (data, onResolve, onReject) => Promise.all(),

  /**
   * @param onResolve
   * @param email {string}
   * @param mailData {{attachments: string[], subject: string, from: string, to: string, cc: string, body: string}} * @returns {*}
   */
  sendMail: (email, mailData, onResolve, onReject) => Promise.all(),
  getEmails: (email, folderType) => Promise.all(),
  markAsRead: (email, folderType, mail) => Promise.all(),
  markAsUnread: (email, folderType, mailId) => Promise.all(),
  deleteMail: (account, folderType, folderId, mailId, mailUID, onResolve) => Promise.all(),
  getMailAttachments: (account, folder, mailId, onResolve) => Promise.all(),
  updateMail: (account, folder, maiId, values) => Promise.all(),

  addListeners: (key, callback) => Promise.all(),
  removeListener: (key) => Promise.all(),
  isNewDraft: (email) => Promise.all(),

  onClickAuthBtn: () => Promise.all(),
  toggleMailImportance: (account, folder, mailId, isImportant, onResolve, onReject) => Promise.all(),
  markAsSpam: (account, mailId, onResolve, onReject) => Promise.all(),
  updateCustomMailsOnFolder: (account, mail, currentFlag, nextFlag, onResolve, onReject) => Promise.all(),
  updateCustomMailFolder: (account, mail, currentFolder, currentFolderId, nextFolder, nextFolderId, onResolve, onReject) => Promise.all(),
  saveToDraft: (account, mail, onResolve, onReject) => Promise.all(),
  updateDraftMail: (account, mail, folder, onResolve, onReject) => Promise.all(),
  deleteDraftMail: (account, mail, onResolve, onReject) => Promise.all(),
  updateMailFlag: (account, mail, currentFlag, nextFlag) => Promise.all()
});

export const useCustomMail = () => useContext(CustomMailContext);

export const CustomMailProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const loadingSnap = useSelector((state) => state?.customMail?.loading || []);

  const accountsSnap = useSelector((state) => {
    const accounts = state?.customMail?.accounts ?? {};
    //map acounts and if un acount have not id or it empty, delete it
    accounts &&
      keys(accounts)?.forEach((email) => {
        const account = accounts[email];
        if (!account?.id || account?.id === '') {
          delete accounts[email];
        }
      });
    return accounts;

    // accounts;
  });
  const mailsSnap = useSelector((state) => state?.customMail?.mails || {});
  const folderSnap = useSelector((state) => state?.customMail?.folders || {});
  const draftsSnap = useSelector((state) => state?.customMail?.drafts || {});

  const accounts = useMemo(
    () => keys(accountsSnap)?.map((email) => ({ ...accountsSnap[email], email })),
    [accountsSnap]
  );

  const [btnInteraction, setBtnInteraction] = useState({ clicked: 0, reacted: 0 });

  const [listeners, setListeners] = useState({});

  const loginCustomMail = (data, onResolve, onReject) => {
    dispatch(authCustomMail(data, onResolve, onReject));
  };

  const authHandler = (err, data, msal) => {
    if (!err) {
      const email = data?.idTokenClaims?.email;
      const isExpired = hasAccountExpired(accountsSnap[email]);

      const base = {
        token: data?.accessToken || null,
        expire: gDate(data?.expiresOn),
        platform: 'microsoft',
        tenantId: data?.tenantId,
        scopes: data?.scopes || [],
        user: {
          displayName: data?.account?.name,
          email: data?.idTokenClaims?.email
        }
      };
      const result = {
        [email]: base
      };

      if (isExpired) {
        dispatch(
          persistToken({
            email,
            result,
            userId: user?.id
          })
        );
        // console.log("btnInteraction", btnInteraction)

        if (btnInteraction.reacted === 0 || btnInteraction.clicked > btnInteraction.reacted) {
          emitChangeToListeners('login', { ...base, email });
        }
      } else {
        if (btnInteraction.reacted === 0 || btnInteraction.clicked > btnInteraction.reacted) {
          enqueueSnackbar('Ce compte a déjà été lié', { variant: 'warning' });
        }
      }

      setBtnInteraction((prevState) => {
        const reacted = prevState.reacted + 1;

        return {
          ...prevState,
          clicked: reacted,
          reacted
        };
      });
    }
  };

  const logout = (account) => {
    dispatch(disconnectAccount(account));
    // popupWindow(`${MICROSOFT_CONFIG.BASE_URL}/logout?post_logout_redirect_uri=${window.location.origin}`, 'logout', window, 400, 500)
  };

  const login = () => {
    const clientId = MICROSOFT_CONFIG.clientId;
    const redirectUri = 'https://oky-cloud-mail.vercel.app/api/google_signin';
    const scope = 'openid User.Read email Mail.ReadWrite Mail.Send offline_access'.toLowerCase();
    const state = JSON.stringify({
      userId: user?.id,
      redirect_uri: `${window.location.origin}/mail/refresh`,
      parent: 'https://oky-cloud-mail.vercel.app/api/google_signin'
    });

    const url = `${MICROSOFT_CONFIG.BASE_URL}/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=${scope}&state=${state}`;

    popupWindow(url, 'logout', window, 400, 500);
  };
  const updateAccount = (email = '', data = {}) => {
    dispatch(updateAccountData({ email, data, userId: user?.id }));
  };

  /**
   *
   * @param email {string}
   * @param mailData {{attachments: string[], subject: string, from: string, cc: string, to: string, body: string}}
   * @param onResolve
   */
  const sendMail = (email, mailData, onResolve, onReject) => {
    dispatch(
      sendMsMail({
        account: accountsSnap[email],
        email: mailData,
        onResolve,
        onReject
      })
    );
  };

  const getEmails = (email, folderType) => {
    dispatch(
      getMails({
        account: accountsSnap[email],
        folderType
      })
    );
  };

  const getMailAttachments = (account, folderType, mailId, onResolve) => {
    // console.log('level 2')
    dispatch(
      getMsMailAttachment({
        account,
        folderType,
        mailId,
        onResolve
      })
    );
  };

  const updateMail = (account, folder, mailId, values) => {
    dispatch(
      updateMailsInFolder({
        email: account?.user?.email,
        folder,
        mailId,
        values
      })
    );
  };
  const deleteMail = (account, folderType, folderId, mailId, mailUID, onResolve) => {
    const mail = mailsSnap[account?.user?.email]?.[folderType]?.[mailId];
    // console.log({ mail, mailId, mailUID, folderType, folderId, account, onResolve });
    dispatch(deleteMsMail({ account, folderType, folderId, mailUID, mailId, onResolve }));
  };

  const markAsRead = (email, folderType, mail) => {
    // isReadManager(email, folderType, mail);
    const folderId = folderSnap[email]?.[folderType]?.id;
    dispatch(
      updateFlag({
        account: accountsSnap[email],
        mailUID: mail?.mail_UID,
        mailId: mail?.id,
        currentFolder: folderId,
        currentFlag: MAIL_FLAG.UNSEEN,
        nextFlag: MAIL_FLAG.SEEN
      })
    );
  };

  const markAsUnread = (email, folderType, mail) => {
    // isReadManager(email, folderType, mail);
    const folderId = folderSnap[email]?.[folderType]?.id;
    dispatch(
      updateFlag({
        account: accountsSnap[email],
        mailUID: mail?.mail_UID,
        mailId: mail?.id,
        currentFolder: folderId,
        currentFlag: MAIL_FLAG.SEEN,
        nextFlag: MAIL_FLAG.UNSEEN
      })
    );
  };

  const updateMailFlag = (account, mail, currentFlag, nextFlag) => {
    dispatch(
      updateFlag({
        account,
        mailUID: mail?.mail_UID,
        mailId: mail?.id,
        currentFolder: mail?.folder,
        currentFlag,
        nextFlag
      })
    );
  };

  //put to spam
  const _updateCustomMailsOnFolder = (account, email, currentFlag, nextFlag, onResolve, onReject) => {
    dispatch(
      updateCustomMailsOnFolder({
        account: email?.account,
        messageUID: email?.id,
        mailUID: email?.mail_UID,
        currentFlag,
        nextFlag,
        onResolve,
        onReject
      })
    );
  };

  const _updateCustomMailFolder = (account, email, currentFolder, currentFolderId, nextFolder, nextFolderId, onResolve, onReject) => {
    // console.log({ account, email, currentFolder, nextFolder });
    dispatch(
      updateCustomMailFolder({
        account: email?.account,
        messageUID: email?.id,
        mailUID: email?.mail_UID,
        currentFolder,
        currentFolderId,
        nextFolder,
        nextFolderId,
        onResolve,
        onReject
      })
    );
  };

  const _saveToDraft = (account, mailData, folder, onResolve) => {
    dispatch(
      saveToDraft({
        account: account,
        email: mailData,
        folder,
        onResolve
      })
    );
  };

  const _updateDraftMail = (account, mailData, folder, onResolve) => {
    // console.log('updateDraftMail', account, mailData);

    dispatch(
      updateDraftMail({
        account: account,
        email: mailData,
        folder,
        onResolve
      })
    );
  };

  const _deleteDraftMail = (email, mailData, onResolve) => {
    dispatch(
      deleteDraftMail({
        account: accountsSnap[email],
        email: mailData,
        onResolve
      })
    );
  };

  function checkIdInDrafts(draftsSnap, idToCheck) {
    for (const email in draftsSnap) {
      if (draftsSnap.hasOwnProperty(email) && draftsSnap[email].hasOwnProperty(idToCheck)) {
        return email; // Renvoie l'email où l'ID a été trouvé
      }
    }
    return null; // L'ID n'a pas été trouvé dans l'objet
  }

  const isNewDraft = (email) => {
    //TODO: check if draft id already exist in draftsSnap
    //draftsSnap.filter((draft) => draft?.email === email);
    const idToCheck = email?.id;
    const emailFound = checkIdInDrafts(draftsSnap, idToCheck);

    return emailFound;
  };
  const toggleMailImportance = (account, folderType, mail, mailUID, isImportant, onResolve, onReject) => {
    dispatch(
      toggleMsMailImportance({
        account,
        folderType,
        mail,
        mailUID,
        isImportant,
        onResolve,
        onReject
      })
    );
  };

  const addListeners = (key, callback) => {
    setListeners((prevState) => ({ ...prevState, [key]: callback }));
  };

  const removeListener = (key) => {
    setListeners((prevState) => {
      const copy = { ...prevState };
      delete copy[key];
      return copy;
    });
  };

  const onClickAuthBtn = () => {
    setBtnInteraction((prevState) => ({ ...prevState, clicked: prevState.clicked + 1 }));
  };
  const emitChangeToListeners = (eventType, data) => {
    setListeners((prevState) => {
      return prevState;
    });
    keys(listeners).forEach((key) => {
      const callback = listeners[key];

      isFunction(callback) && callback(eventType, data);
    });
  };

  // useEffect(() => {
  //     const bc = new BroadcastChannel('mail_chanel');
  //
  //     bc.onmessage = ev => {
  //         const account = {
  //             ...ev?.data,
  //             email: ev?.data?.user?.email
  //         }
  //
  //         emitChangeToListeners('login', account)
  //
  //         //console.log("channel", ev?.data);
  //     }
  //
  // }, [])

  const store = {
    accounts,
    mailsSnap,
    updateAccount,
    login,
    logout,
    loadingSnap,
    draftsSnap,
    sendMail,
    getEmails,
    getMailAttachments,
    markAsRead,
    deleteMail,
    toggleMailImportance,
    updateMail,
    addListeners,
    removeListener,
    isNewDraft,
    onClickAuthBtn,
    markAsUnread,
    folderSnap,
    loginCustomMail,
    saveToDraft: _saveToDraft,
    updateDraftMail: _updateDraftMail,
    deleteDraftMail: _deleteDraftMail,
    updateCustomMailsOnFolder: _updateCustomMailsOnFolder,
    updateCustomMailFolder: _updateCustomMailFolder,
    updateMailFlag,
    loginBtnProps: {
      clientId: MICROSOFT_CONFIG.clientId,
      authCallback: authHandler,
      redirectUri: window.location.origin,
      graphScopes: ['User.Read', 'email', 'Mail.ReadWrite', 'Mail.Send', 'offline_access']
    }
  };

  return <CustomMailContext.Provider value={store}>{children}</CustomMailContext.Provider>;
};

export const hasAccountExpired = (data) => {
  return !data ? true : gDate(data?.expire) < new Date();
};
