import { CircularProgress, Sheet, Tooltip } from '@mui/joy';
import { Box, Button, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import TextMaxLine from 'src/components/TextMaxLine';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';

const SHOW = {
  DONE: 'DONE',
  ERROR: 'ERROR'
};

/**
 *
 * @param {{
 * totalFiles: number
 * uploadings: Array<{name:string, progress: number, error: any, size};
 * onClose: ()=>{},
 * elementId: string
 * }} props
 */
export default function UploadingFileView({ uploadings, totalFiles, onClose, elementId = 'oky_driver' }) {
  const { open, handleSwitch } = useToggle(true);
  const container = document.getElementById(elementId);
  const [show, onShow] = useToggleV2();

  const toShow = useMemo(() => {
    if (!show) {
      return uploadings;
    }

    if (show === SHOW.DONE) {
      return uploadings.filter((up) => up.progress === 1);
    }

    if (show === SHOW.ERROR) {
      return uploadings.filter((up) => up.error);
    }

    return uploadings;
  }, [uploadings, show]);

  console.log(show);

  return ReactDOM.createPortal(
    <Box
      sx={{
        zIndex: (t) => t.zIndex.appBar + 1500,
        position: 'absolute',
        bottom: 0,
        right: 40,
        p: 3,
        overflow: 'hidden',
        display: container ? 'initial' : 'none'
      }}
    >
      <Paper variant="elevation" elevation={2} sx={{ width: 400 }}>
        <Stack>
          <Sheet variant="soft" color="neutral" sx={{ borderRadius: 'sm' }}>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" p={1}>
              <Stack>
                <TextMaxLine fontSize={13} fontWeight="bold" noTooltip>
                  Téléchargement de {totalFiles || uploadings.length} document
                  {(totalFiles || uploadings?.length) > 1 ? 's' : ''}
                </TextMaxLine>
              </Stack>

              <Stack direction="row" spacing={1}>
                <Tooltip title={!open ? 'Ouvrir' : 'Reduire'} placement="top">
                  <IconButton size="small" onClick={handleSwitch}>
                    <Iconify icon="eva:arrow-ios-downward-fill" rotate={open ? 0 : 2} />
                  </IconButton>
                </Tooltip>
                <IconButton size="small" title="Fermer" onClick={onClose}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={3} p={0.5}>
              <Button
                size="small"
                color="inherit"
                sx={{ fontSize: 10 }}
                startIcon={
                  <Iconify
                    icon={
                      show !== SHOW.DONE && show !== SHOW.ERROR ? 'akar-icons:check-box-fill' : 'akar-icons:check-box'
                    }
                    sx={{ height: 12, width: 12 }}
                  />
                }
                onClick={() => onShow(null)}
              >
                Tout
              </Button>
              <Button
                size="small"
                color="info"
                sx={{ fontSize: 10 }}
                startIcon={
                  <Iconify
                    icon={show === SHOW.DONE ? 'akar-icons:check-box-fill' : 'akar-icons:check-box'}
                    sx={{ height: 12, width: 12 }}
                  />
                }
                onClick={() => onShow(show === SHOW.DONE ? null : SHOW.DONE)}
              >
                Terminé(s) ({uploadings.filter((up) => up.progress === 1).length})
              </Button>
              <Button
                size="small"
                color="error"
                sx={{ fontSize: 10 }}
                startIcon={
                  <Iconify
                    icon={show === SHOW.ERROR ? 'akar-icons:check-box-fill' : 'akar-icons:check-box'}
                    sx={{ height: 12, width: 12 }}
                  />
                }
                onClick={() => onShow(show === SHOW.ERROR ? null : SHOW.ERROR)}
              >
                Échoué(s) : ({uploadings.filter((up) => up.error).length})
              </Button>
            </Stack>
          </Sheet>

          {open && (
            <Stack component={Scrollbar} p={1} spacing={1} maxHeight={420} overflow="auto">
              {toShow.map((one, index) => (
                <React.Fragment key={one.name + index}>
                  <UploadingLine name={one.name} progress={one.progress} error={one.error} />
                  <Divider light />
                </React.Fragment>
              ))}
            </Stack>
          )}
        </Stack>
      </Paper>
    </Box>,
    document.body
  );
}

const UploadingLine = ({ name, progress, error }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" p={1}>
      <Stack width={1} direction="row" alignItems="center" spacing={1}>
        <TextMaxLine noTooltip fontSize={12} maxLength={45}>
          {name}
        </TextMaxLine>
        <Typography fontSize={12}>({Math.ceil(progress * 100)}%)</Typography>
      </Stack>
      {error ? (
        <IconButton size="small" title="Une erreur s'est produite">
          <Iconify icon="eva:info-fill" color="red" />
        </IconButton>
      ) : progress < 1 ? (
        <CircularProgress variant="plain" size="sm" />
      ) : (
        <IconButton size="small">
          <Iconify icon="lets-icons:done-ring-round-fill" color="green" />
        </IconButton>
      )}
    </Stack>
  );
};
