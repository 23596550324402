
/**
 * Formats an email address by removing any angle brackets (`<` or `>`) from the input string.
 *
 * @param {string} mail - The email address to format.
 * @returns {string} The formatted email address with angle brackets removed.
 */
export const formatCorrectlyMailAdresse = (mail) => {
  const email = mail?.email || mail;
  if (!email) {
    return '';
  }
  // console.log('email', email);
  const mailWithout = email?.replace(/<|>/g, '');
  return mailWithout;
};
