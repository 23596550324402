export const REPORT_TYPE={
  AUDIENCE:'audience',
  STAGE:'stage'
}

export const DOM_ELEMENT_ID = {
  CHART_DIV_ID: 'chart-div-id',
  CHART_REPORT_BY_TASK_DIV_ID: 'chart-report-by-task-div-id',
  CHART_REPORT_BY_USER_DIV_ID: 'chart-report-by-user-div-id',
}

export const taskFilterLabel = ['Terminée', 'En cours', 'En retard', 'Terminé en retard'];
