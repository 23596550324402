import { Icon } from '@iconify/react';
import { Button, Checkbox, ClickAwayListener, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState, useRef, useEffect } from 'react';
import ActionModal from 'src/components/ActionModal';
import CIconButton from 'src/components/CIconButton';
import { MuiCustomInput } from 'src/components/CustomInput';
import { handleKeyDown } from 'src/helpers/lockEnterShiftKeyDown';
import { useToggleV2 } from 'src/hooks/useToggle';
import { deleteColumn, updateColumn } from 'src/redux/slices/kanban';
import { dispatch } from 'src/redux/store';
import { useV4ProjectContext } from '..';
import V4OperationMore from '../V4OperationMore';
import TextMaxLine from 'src/components/TextMaxLine';

/**
 * Renders the toolbar for the Kanban view of a project in the V4 version.
 *
 * The toolbar includes functionality to:
 * - Rename the current column
 * - Add a new task to the current column
 * - Access more actions for the current column, such as editing or deleting it
 *
 * The toolbar's behavior and appearance is controlled by the user's permissions,
 * which are retrieved from the V4ProjectContext.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.column - The current Kanban column.
 * @param {function} props.onHover - A function called when the user hovers over the toolbar.
 * @param {function} props.endHover - A function called when the user stops hovering over the toolbar.
 * @param {boolean} props.hover - Whether the user is currently hovering over the toolbar.
 * @param {function} props.onOpenTasknew - A function called to open the task creation modal.
 * @returns {JSX.Element} - The rendered V4ProjectCotentKanbanToolbar component.
 */
export default function V4ProjectCotentKanbanToolbar({ column, onHover, endHover, hover, onOpenTasknew }) {

  const { taskRights: rights } = useV4ProjectContext();

  const canEdit = rights?.edit;
  const canCreate = rights?.create;
  const canDelete = rights?.delete;
  const anchorRef = useRef(null);
  const renameRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(column?.name);
  const [more, onMore, closeMore] = useToggleV2();
  const [openDelete, onOpenDelete, closeDelete] = useToggleV2();
  const [deleteOk, _, __, switchDeleteOK] = useToggleV2();
  const { projectId } = useV4ProjectContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      if (renameRef.current !== null) {
        renameRef.current.focus();
      }
    }
  }, [open]);

  const handleChangeColumnName = (event) => {
    setValue(event.target.value);
  };

  const handleUpdateColumn = () => {
    setOpen(false);
    if (value !== column?.name) {
      dispatch(updateColumn(column.id, { ...column, name: value }));
    }
  };

  const onClickAway = () => {
    handleUpdateColumn();
  };

  const handleKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      handleUpdateColumn();
    }
  };

  const handlEdit = () => {
    setOpen(true);
    renameRef.current?.focus();
  };

  const handleDelete = () => {
    closeDelete();
    dispatch(
      deleteColumn(column?.id, projectId, () => {
        enqueueSnackbar('Opération supprimée avec succès', { variant: 'warning' });
      })
    );
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={0.5}
        onMouseEnter={onHover}
        onMouseLeave={endHover}
      >
        <Stack width={1}>
          {!open && (
            <Stack width={1} onClick={() => canEdit && setOpen(true)} py={0.8}>
              <TextMaxLine line={1} sx={{ fontSize: 15, fontWeight: 'bold' }} ml={0.5}>
                {value}
              </TextMaxLine>
            </Stack>
          )}
          {open && (
            <ClickAwayListener onClickAway={onClickAway}>
              <MuiCustomInput
                fullWidth
                ref={renameRef}
                value={value}
                onChange={handleChangeColumnName}
                placeholder="Nom de l'opération"
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                autoFocus
                size="small"
                sx={{
                  py: 0.5,
                  border: 'none',
                  typography: 'overline',
                  fontSize: 15,
                  bgcolor: 'transparent',
                  fontWeight: 'fontWeightBold',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'
                  }
                }}
              />
            </ClickAwayListener>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CIconButton noBorder noBackground title="Ajouter une tâche" onClick={canCreate && onOpenTasknew} disabled={!canCreate}>
            <Icon icon="eva:plus-fill" />
          </CIconButton>

          <Stack ref={anchorRef}>
            <CIconButton noBorder noBackground title="Plus d'action" onClick={onMore}>
              <Icon icon="eva:more-horizontal-fill" />
            </CIconButton>
          </Stack>
        </Stack>
      </Stack>
      {more && (
        <V4OperationMore
          open={more}
          onClose={closeMore}
          anchorEl={anchorRef.current}
          onEdit={canEdit &&
            handlEdit}
          onDelete={canDelete &&
            onOpenDelete}
        />
      )}
      {openDelete && (
        <ActionModal
          open={openDelete}
          onClose={closeDelete}
          title="Supprimer l'opération"
          moreAction={
            <Stack alignItems="center" direction="row" sx={{ cursor: 'pointer' }} onClick={switchDeleteOK}>
              <Checkbox checked={deleteOk} />
              <Stack width={1} textAlign="center">
                <Typography>Êtes-vous sûr de vouloir supprimer cette opération ?</Typography>
                <Typography variant="caption" color="grey.500" fontSize={10}>
                  Une fois cette opération supprimée, il sera impossible de récuperer les tâches qu'il contient
                </Typography>
              </Stack>
            </Stack>
          }
          action={
            <Button variant="contained" onClick={handleDelete} disabled={!deleteOk}>
              Supprimer
            </Button>
          }
        />
      )}
    </>
  );
}
