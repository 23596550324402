import { gDate } from 'src/utils/formatTime';

/**
 * Class representing an email.
 */
export default class Mail {
  /**
   * Create a Mail instance.
   * @param {Object} params - The parameters for the email.
   * @param {string} params.id - The ID of the email.
   * @param {Object} params.from - The sender of the email.
   * @param {Array<Object>} params.to - The recipients of the email.
   * @param {Array<Object>} params.cc - The CC recipients of the email.
   * @param {Array<Object>} params.cci - The BCC recipients of the email.
   * @param {string} params.subject - The subject of the email.
   * @param {string} params.body - The body content of the email.
   * @param {string} params.text - The plain text version of the email.
   * @param {string} params.textAsHtml - The HTML version of the email.
   * @param {Date} params.sentDate - The date the email was sent.
   * @param {boolean} params.isRead - Indicates if the email has been read.
   * @param {boolean} params.isImportant - Indicates if the email is marked as important.
   * @param {boolean} params.hasAttachments - Indicates if the email has attachments.
   * @param {Object} params.orginalMail - The original email information (if any).
   * @param {string} params.meetingMessageType - The type of meeting message (if any).
   * @param {Date} params.startDateTime - The start date and time of the meeting (if any).
   * @param {Date} params.endDateTime - The end date and time of the meeting (if any).
   */
  constructor({ id, from, to, cc, cci, subject, body, text, textAsHtml, sentDate, isRead, isImportant, hasAttachments, orginalMail, meetingMessageType, startDateTime, endDateTime }) {
    this.id = id;
    this.from = from;
    this.to = to;
    this.cc = cc;
    this.cci = cci;
    this.subject = subject;
    this.body = body;
    this.sentDate = sentDate;
    this.isRead = isRead;
    this.text = text;
    this.textAsHtml = textAsHtml;
    this.isImportant = isImportant;
    this.hasAttachments = hasAttachments;
    this.orginalMail = orginalMail;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.meetingMessageType = meetingMessageType;
  }

  /**
   * Creates a blank Mail instance.
   * @returns {Mail} A new Mail instance with default values.
   */
  static blank() {
    return new Mail({
      id: 'bank',
      from: {
        name: 'Random user',
        email: 'random@user.com'
      },
      to: [
        {
          name: 'Random user',
          email: 'random@user.com'
        }
      ],
      cc: [],
      cci: [],
      subject: 'Blank email',
      body: 'Mail content',
      sentDate: new Date(),
      isRead: false,
      text: 'Mail content',
      textAsHtml: '<p>Mail content</p>',
      isImportant: false,
      hasAttachments: false,
      orginalMail: {},
      meetingMessageType: null,
      startDateTime: null,
      endDateTime: null
    });
  }

  /**
   * Creates a Mail instance from an Outlook mail object.
   * @param {Object} msMail - The Outlook mail object.
   * @returns {Mail} A new Mail instance created from the Outlook mail object.
   */
  static fromOutlook(msMail) {
    return new Mail({
      id: msMail?.id,
      from: transformToPerson(msMail?.from?.emailAddress),
      to: transformToPersonFromList(msMail?.toRecipients),
      cc: transformToPersonFromList(msMail?.ccRecipients),
      cci: transformToPersonFromList(msMail?.bccRecipients),
      subject: msMail?.subject,
      body: msMail?.body?.content,
      sentDate: gDate(msMail?.sentDateTime),
      isRead: msMail?.isRead,
      isImportant: msMail?.importance !== 'normal',
      hasAttachments: msMail?.hasAttachments || false,
      orginalMail: msMail?.orginalMail,
      meetingMessageType: msMail?.meetingMessageType,
      startDateTime: msMail?.startDateTime,
      endDateTime: msMail?.endDateTime
    });
  }
}

/**
 * Transforms a user object into a person object.
 * @param {Object} user - The user object to transform.
 * @param {string} user.address - The email address of the user.
 * @param {string} user.name - The name of the user.
 * @returns {Object} The transformed person object.
 */
const transformToPerson = (user) => {
  return {
    email: user?.address,
    name: user?.name
  };
};

/**
 * Transforms a list of user objects into a list of person objects.
 * @param {Array<Object>} list - The list of user objects to transform.
 * @returns {Array<Object>} The transformed list of person objects.
 */
const transformToPersonFromList = (list = []) => {
  return list?.map((el) => transformToPerson(el?.emailAddress));
};