import { useMemo } from 'react';
import useNotifications from '../hooks/useNotifications';
import { NOTIFICATION_TYPES } from 'src/constants';
import { useSelector } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';
import Label from './Label';


const filterUnreadCommunicationNotifications = (notifications) => 
  notifications.filter(
    (item) => item.isUnRead &&
      [
        NOTIFICATION_TYPES.COMMUNICATION,
        NOTIFICATION_TYPES.COMMUNICATION_ADD_TO_CHANNEL,
        NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE,
        NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE,
        NOTIFICATION_TYPES.COMMUNICATION_REMOVE_FROM_CHANNEL,
        NOTIFICATION_TYPES.COMMUNICATION_YOU_ARE_NEW_ADMIN,
      ].includes(item.type)
  );

const useUnreadNotifications = () => {
  const { combine: notifications } = useNotifications();
  return useMemo(() => filterUnreadCommunicationNotifications(notifications), [notifications]);
};


const useTotalUnreadMessages = () => {
  const { user } = useAuth();
  const { channels } = useSelector((state) => state.communication);

  return useMemo(() => {
    if (!channels || !user?.uid) return 0;

    return channels.reduce((count, channel) => {
      const userUnreadMessages = channel?.readState?.[user.uid] || [];
      return count + userUnreadMessages.length;
    }, 0);
  }, [channels, user?.uid]);
};


export default function CommunityNotificationLabel() {
  const unReadNotifications = useUnreadNotifications();
  const totalUnreadMessagesCount = useTotalUnreadMessages();
  const { isFirstLoad } = useSelector((state) => state.communication);

  if (isFirstLoad) {
    return unReadNotifications.length > 0 ? (
      <Label color="error">{unReadNotifications.length}</Label>
    ) : null;
  }

  return totalUnreadMessagesCount > 0 ? (
    <Label color="error">{totalUnreadMessagesCount}</Label>
  ) : null;
}
