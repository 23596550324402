import { fr as localFr } from 'date-fns/locale';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip
} from '@mui/material';
import { format } from 'date-fns';
import { isNumber, isString } from 'lodash';
import React, { useState } from 'react';
import CIconButton from 'src/components/CIconButton';
import Iconify from 'src/components/Iconify';
import { toDateTime } from 'src/utils/unixDateTime';
import { MuiCustomInput } from 'src/components/CustomInput';
import { dispatch } from 'src/redux/store';
import { updateChannel } from 'src/redux/slices/communication/channel';
import { ChannelType } from 'src/models/communication_types';
import { CANAL_INFO_TAB, CHANNEL_FIELD, CONFIRM_MESSAGE } from 'src/constants/community';
import { useCommunityContext } from 'src/contexts/CommunityContext';
import useComunityInfoValidator from 'src/section/communications/hooks/useComunityInfoValidator';

import { MAvatar } from 'src/components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import { nameFilter } from 'src/utils/nameFilter';
import TextMaxLine from 'src/components/TextMaxLine';
import { useSelector } from 'react-redux';

/**
 * Renders the ChannelInfos component, which displays information about a communication channel.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Whether the ChannelInfos dialog is open.
 * @param {function} props.onClose - Callback function to close the ChannelInfos dialog.
 * @param {ChannelType} props.channel - The communication channel object.
 * @param {firebase.default.UserInfo} props.user - The current user object.
 * @returns {JSX.Element} - The ChannelInfos component.
 */
const ChannelInfos = ({ open, onClose, channel: _chanel, user }) => {
  const [tab, setTab] = React.useState(CANAL_INFO_TAB.ABOUT);
  const [field, setField] = React.useState(null);
  const { handleOpenConfim } = useCommunityContext();
  const channel = useSelector((state) => state.communication.channels.find((c) => c.id === _chanel?.id));

  const [favorite, setFavorite] = useState(channel?.favorite || {});
  const [notifications, setNotifications] = useState(channel?.notifications || {});

  const handleClose = () => {
    setField(null);
  };

  const handleOpen = (_field) => () => {
    setField(_field);
  };

  const handleChange = (_, val) => {
    setTab(val);
  };

  const handleChannelChange = (name, value) => {
    // setCurrentChannel((val) => ({ ...val, [name]: value }));
  };

  const handleFavorite = () => {
    let _favorite = { ...favorite };
    const userId = user.uid;
    const val = _favorite[userId];
    _favorite[userId] = !val;

    dispatch(
      updateChannel({
        channel: { ...channel, favorite: _favorite },
        oldChannel: channel,
        callback: () => {
          setFavorite(_favorite);
        }
      })
    );
  };

  const handleNotifications = () => {
    let _notifications = { ...notifications };
    const userId = user.uid;
    const val = _notifications[userId];
    _notifications[userId] = !val;

    dispatch(
      updateChannel({
        channel: { ...channel, notifications: _notifications },
        oldChannel: channel,
        callback: () => {
          setNotifications(_notifications);
        }
      })
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2}>
            <Iconify icon="material-symbols:tag-rounded" sx={{ color: 'grey.600' }} />
            <TextMaxLine
              style={{ color: 'grey.900', variant: 'body2', fontWeight: 'bold' }}
              text={channel?.title}
              line={1}
              fontSize={16}
            >
              {channel?.title}
            </TextMaxLine>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack width={1} direction="row" spacing={3} mb={3} mt={2}>
          <ToggleButtonGroup size="small" value={tab} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value={CANAL_INFO_TAB.ABOUT}> {CANAL_INFO_TAB.ABOUT} </ToggleButton>
            <ToggleButton value={CANAL_INFO_TAB.MEMBERS}>
              {CANAL_INFO_TAB.MEMBERS} ({channel?.members?.length || 0})
            </ToggleButton>
          </ToggleButtonGroup>

          <CIconButton sx={{ fontSize: 13 }} onClick={handleFavorite}>
            <Iconify icon={favorite[user.uid] ? 'emojione:star' : 'ic:round-star-outline'} sx={{ mr: 1 }} />
            {favorite[user.uid] ? 'Retirer des favoris' : 'Ajouter aux favoris'}
          </CIconButton>
          <CIconButton sx={{ fontSize: 12 }} onClick={handleNotifications}>
            <Iconify
              icon={
                notifications[user.uid] === undefined
                  ? 'lucide:bell-ring'
                  : notifications[user.uid]
                    ? 'lucide:bell-ring'
                    : 'carbon:notification-off-filled'
              }
              sx={{ mr: 1 }}
            />
            {notifications[user.uid] === undefined
              ? 'Désactiver les notifications'
              : notifications[user.uid]
                ? 'Désactiver les notifications'
                : 'Activer les notifications'}
          </CIconButton>
        </Stack>

        <TabContext value={tab}>
          <TabPanel value={CANAL_INFO_TAB.ABOUT}>
            {channel && (
              <AboutPanel channel={channel} user={user} handleOpen={handleOpen} handleOpenConfim={handleOpenConfim} />
            )}
          </TabPanel>
          <TabPanel value={CANAL_INFO_TAB.MEMBERS}>{channel && <MemberPanel channel={channel} user={user} />}</TabPanel>
        </TabContext>
      </DialogContent>
      {field && (
        <EditChannel
          open={Boolean(field)}
          onClose={handleClose}
          channel={channel}
          field={field}
          handleChannelChange={handleChannelChange}
          editTitle={field === CHANNEL_FIELD.TITLE ? 'Modifier le titre' : 'Modifier la description'}
        />
      )}
    </Dialog>
  );
};

export default ChannelInfos;

/**
 * A React component that renders the "About" panel for a channel, displaying information such as the channel title, description, creator, and options to leave or close the channel.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.channel - The channel object containing information about the channel.
 * @param {Object} props.user - The current user object.
 * @param {function} props.handleOpen - A function to call when the user wants to edit the channel title or description.
 * @param {function} props.handleOpenConfim - A function to call when the user wants to perform an action that requires confirmation, such as leaving or closing the channel.
 * @returns {JSX.Element} - The rendered "About" panel component.
 */
const AboutPanel = ({ channel, user, handleOpen, handleOpenConfim }) => {
  const [values, setValues] = useState({ title: channel?.title, description: channel?.description });
  // console.log('channel', channel);

  const handleChange = (name, value) => {
    setValues((old) => ({ ...old, [name]: value }));
  };

  return (
    <Stack>
      <CanalInfosLine
        title="Titre du canal"
        onChange={handleChange}
        value={channel?.title}
        edit={handleOpen(CHANNEL_FIELD.TITLE)}
        addHash
      />
      <Divider />
      <CanalInfosLine
        title="Description du canal"
        value={channel?.description}
        onChange={handleChange}
        edit={handleOpen(CHANNEL_FIELD.DESC)}
      />
      <Divider />
      <CanalInfosLine
        title="Créé par"
        value={`${channel?.created_by?.name} le ${format(
          isString(channel?.created_at) || isNumber(channel.created_at)
            ? toDateTime(Number(channel?.created_at) / 1000)
            : new Date(),
          'dd MMMM yyyy HH:mm',
          {
            locale: localFr
          }
        )}`}
      />
      {!channel?.isClosed && <Divider sx={{ mb: 1 }} />}
      {!channel?.isClosed && (
        <CanalInfosLine title="Quitter le canal" color="#E01E5A" asButton={handleOpenConfim(CONFIRM_MESSAGE.LEAVE)} />
      )}
      <Divider />
      {!channel?.isClosed && channel?.admin?.id === user.uid && (
        <CanalInfosLine
          title="Cloturer le canal pour tout le monde"
          color="#E01E5A"
          asButton={handleOpenConfim(CONFIRM_MESSAGE.CLOSE)}
        />
      )}
      {channel?.isClosed && channel?.admin?.id === user.uid && (
        <CanalInfosLine
          title="Rouvrir le canal pour tout le monde"
          color="#E01E5A"
          asButton={handleOpenConfim(CONFIRM_MESSAGE.OPEN)}
        />
      )}
      {!channel?.isClosed && channel?.admin?.id === user.uid && (
        <CanalInfosLine
          title="Supprimer le canal"
          color="#E01E5A"
          asButton={handleOpenConfim(CONFIRM_MESSAGE.DELETE_CANAL)}
        />
      )}
    </Stack>
  );
};

/**
 * A component that renders a line of information for a channel, including a title, value, and optional edit button.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the information line.
 * @param {string} props.value - The value to be displayed.
 * @param {string} [props.color] - An optional color to apply to the title.
 * @param {function} [props.edit] - An optional function to call when the edit button is clicked.
 * @param {boolean} [props.addHash] - An optional flag to add a hash icon before the value.
 * @param {function} [props.asButton] - An optional function to call when the entire line is clicked.
 * @returns {JSX.Element} - The rendered component.
 */
const CanalInfosLine = ({ title, value, color, edit = null, addHash = false, asButton = null }) => {
  return (
    <Stack width={1} {...(asButton && { component: MenuItem, disableGutters: true, onClick: asButton })} py={1}>
      <Stack width={1} direction="row" justifyContent="space-between">
        <Stack>
          <TextMaxLine
            fontWeight={value ? 'bold' : '600'}
            fontSize={15}
            {...(color && { color: color })}
            maxLength={40}
          >
            {title}
          </TextMaxLine>
        </Stack>
        {edit && !asButton && (
          <Stack>
            <Button onClick={edit} size="small">
              Modifier
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" spacing={1}>
        {addHash && <Iconify icon="material-symbols:tag-rounded" sx={{ color: 'grey.800' }} />}
        <TextMaxLine fontSize={14} color="grey.800" line={1}>
          {value}
        </TextMaxLine>
      </Stack>
    </Stack>
  );
};

/**
 * A component that renders a dialog for editing a channel's properties.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the dialog is open or not.
 * @param {function} props.onClose - A function to call when the dialog is closed.
 * @param {function} props.handleChannelChange - A function to call when a channel property is changed.
 * @param {string} props.field - The field of the channel that is being edited.
 * @param {Object} props.channel - The channel object being edited.
 * @param {string} props.editTitle - The title to display in the dialog.
 * @returns {JSX.Element} - The rendered component.
 */
const EditChannel = ({ open, onClose, handleChannelChange, field, channel, editTitle }) => {
  const [value, setValue] = React.useState(channel[field]);
  const { handleValidate } = useComunityInfoValidator();
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    handleValidate({ title: channel.title, description: channel.description, [field]: value })
      .then(() => {
        dispatch(updateChannel({ channel: { ...channel, [field]: value }, oldChannel: channel }));
        handleChannelChange(field, value);
        onClose();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack width={1} direction="row" justifyContent="space-between">
          <Stack>
            <Typography fontWeight="bold" variant="h5">
              {editTitle}
            </Typography>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack width={500} pt={2}>
          <MuiCustomInput
            name={field}
            fullWidth
            {...(field === CHANNEL_FIELD.DESC && { multiline: true })}
            value={value}
            onChange={handleChange}
            {...(field === CHANNEL_FIELD.TITLE && { startAdornment: <span style={{ marginRight: 10 }}>#</span> })}
          />
          {field === CHANNEL_FIELD.TITLE ? (
            <Typography variant="caption" color={value?.length > 80 ? 'error' : 'text.disabled'}>
              Le titre de ce canal ne doit pas dépasser 80 caractères. caractères utilisés : ({value?.length || 0})
            </Typography>
          ) : (
            <Typography color="text.disabled" variant="caption">
              Quel est l’objectif de ce canal ?
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <Stack width={1} spacing={3} direction="row" justifyContent="flex-end" pb={2} px={2}>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Enregistrer
        </Button>
      </Stack>
    </Dialog>
  );
};

/** @param {{channel: ChannelType, user: firebase.default.UserInfo}} */
const MemberPanel = ({ channel, user }) => {
  const [value, setValue] = useState('');

  return (
    <Stack width={1} spacing={1}>
      <MuiCustomInput
        fullWidth
        startAdornment={<Iconify icon="ic:baseline-search" sx={{ mr: 2, height: 25, width: 25 }} />}
        placeholder="rechercher"
        size="small"
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        sx={{ py: 0.5 }}
      />
      <Stack>
        {nameFilter(channel.members, 'name', value).map((chan, index) => {
          // console.log({ chan, channel });
          return (
            <MenuItem key={chan?.id + index} disableGutters>
              <MAvatar
                variant="square"
                color={createAvatar(chan.name).color}
                sx={{ borderRadius: 1, mr: 2, height: 30, width: 30 }}
              >
                {createAvatar(chan.name).name}
              </MAvatar>
              <Typography fontWeight="bold">
                {chan.name} {user.uid === chan.id ? '(vous)' : ''}
              </Typography>
              {channel.admin?.id === chan.id && (
                <Tooltip title="Est administrateur" arrow placement="top">
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <Iconify icon="material-symbols:admin-panel-settings" sx={{ color: 'grey.600' }} />
                  </IconButton>
                </Tooltip>
              )}
            </MenuItem>
          );
        })}
      </Stack>
    </Stack>
  );
};
