import * as Yup from 'yup';
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  Tooltip,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { fr as LocalFr } from 'date-fns/locale';
import React, { useRef, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { ProjectType } from 'src/models/project_m';
import { useDatePicker } from 'src/section/tasks/kanban/KanbanTaskAdd';
import { dateConverter } from 'src/helpers/dueDateConverter';
import LabelStyle from 'src/components/LabelStyle';
import DateTineRangeSelector from 'src/components/DateTineRangeSelector';
import { format } from 'date-fns';
import { baseColors } from 'src/constants/color';
import { UserAccessView } from '../../ProjectCardOPtion';
import Scrollbar from 'src/components/Scrollbar';
import CIconButton from 'src/components/CIconButton';
import Iconify from 'src/components/Iconify';
import { isEmpty } from 'lodash';
import { dispatch } from 'src/redux/store';
import { createProject, updateProject } from 'src/redux/slices/kanban';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { PROJECT_STATE, PROJECT_STATE_FR } from 'src/constants/project';
import { getCurrentUserAccess } from 'src/helpers/user';
import OkyCalendar from 'src/components/calendar/OkyCalendar';
import { gDate } from 'src/utils/formatTime';

const schema = Yup.object().shape({
  name: Yup.string().required('Le titre du projet est obligatoire')
});

/**
 * Renders the ProjectV4Form component, which is a dialog that allows the user to create or edit a project.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Whether the dialog is open or not.
 * @param {function} props.onClose - A callback function to close the dialog.
 * @param {object} [props.edit] - The project object to edit, if any.
 * @param {function} [props.addToSpace] - A callback function to add the project to a space.
 * @param {function} [props.onSave] - A callback function to save the project.
 * @returns {JSX.Element} - The ProjectV4Form component.
 */
export default function ProjectV4Form({ open, onClose, edit = null, addToSpace = null, onSave }) {
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = getCurrentUserAccess();

  const currentUserRights = useMemo(() => {
    if (edit?.createBy?.id === currentUser.id) return { delete: true, edit: true, create: true };

    if (edit?.canAccessId?.includes(currentUser?.id)) {
      return (
        edit?.canAccess?.find((user) => user?.id === currentUser?.id)?.rights ||
        edit?.managers?.find((user) => user?.id === currentUser?.id)?.rights
      );
    }

    return { delete: false, edit: false, create: true };
  }, [edit, currentUser]);

  const userCanEdit = useMemo(() => {
    if (!edit) return true;
    if (edit && currentUserRights?.edit) return true;
    return false;
  }, [edit, currentUserRights]);

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: edit || ProjectType,
    validationSchema: schema,
    onSubmit: (values) => {
      if (!isEmpty(values?.name)) {
        setLoading(true);
        if (!edit) {
          const callback = (id) => {
            setLoading(false);
            onClose();
            enqueueSnackbar('Projet créé avec succès', { variant: 'success' });
            if (onSave) onSave(values);
            if (addToSpace) addToSpace(id);
          };
          dispatch(createProject(values, callback, () => setLoading(false)));
          return;
        }

        const callback = () => {
          enqueueSnackbar('Projet mise à jour', { variant: 'info' });
          setLoading(false);
          onClose();
          if (onSave) onSave(values);
        };

        if (JSON.stringify(edit) !== JSON.stringify(values)) {
          dispatch(updateProject({ ...values, id: edit?.id }, edit, callback));
          return;
        }

        setLoading(false);
        onClose();
      }
    }
  });

  const { handleSubmit, touched, errors, values, getFieldProps, setFieldValue } = formik;

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} scroll="paper">
      <DialogTitle>
        <Stack width={1} direction="row" justifyContent="space-between" alignItems="start">
          <Typography fontSize={18} p={1} fontWeight={700} align="center">
            {!edit ? ' Nouveau projet' : edit?.name}
          </Typography>
          <Stack>
            <CIconButton color="error" title="Fermer" onClick={onClose}>
              <Iconify icon="eva:close-fill" sx={{ color: 'red' }} />
            </CIconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit} noValidate>
            <Stack spacing={2} p={3}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <Stack width={{ xs: 1, md: 0.55 }}>
                  <InfoSection
                    touched={touched}
                    errors={errors}
                    values={values}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                    loading={loading}
                    userCanEdit={userCanEdit}
                  />
                </Stack>
                <Stack width={{ xs: 1, md: 0.5 }} sx={{ minHeight: '100%', maxHeight: '100%' }}>
                  <Paper variant="outlined" sx={{ height: '100%' }}>
                    <Typography fontSize={15} p={1} fontWeight={700} align="center">
                      Collaborateur
                    </Typography>
                    <Divider />
                    <CollabSection project={values} isNew={!edit} onChange={setFieldValue} userCanEdit={userCanEdit} />
                  </Paper>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}

const stateConverter = (state) => {
  if (state === PROJECT_STATE.OPEN) return PROJECT_STATE_FR.IN_PROGRESS;
  if (state === PROJECT_STATE.SUSPENDED) return PROJECT_STATE_FR.SUSPENDED;
  if (state === PROJECT_STATE.CLOSED) return PROJECT_STATE_FR.CLOSED;
  return state;
};

const stateReverseConverter = (state) => {
  if (state === PROJECT_STATE_FR.IN_PROGRESS) return PROJECT_STATE.OPEN;
  if (state === PROJECT_STATE_FR.SUSPENDED) return PROJECT_STATE.SUSPENDED;
  if (state === PROJECT_STATE_FR.CLOSED) return PROJECT_STATE.CLOSED;
  return state;
};

const InfoSection = ({ touched, errors, values, getFieldProps, setFieldValue, loading, userCanEdit }) => {
  const anchorEl = useRef();

  const {
    startTime,
    endTime,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker,
    dueDate,
    isSameDays,
    isSameMonths
  } = useDatePicker({
    date: dateConverter([values?.dataInterval?.start || null, values?.dataInterval?.end || null])
  });

  const handleDueDateChange = (newval) => {
    onChangeDueDate(newval);
    setFieldValue('dataInterval.start', newval?.at(0));
    setFieldValue('dataInterval.end', newval?.at(1));
    // close if both date are selected
    //if (newval?.at(0) && newval?.at(1)) onClosePicker();
  };

  const onClose = (val) => {
    onClosePicker();
    setFieldValue('dataInterval', { start: val?.at(0), end: val?.at(1) });
  };

  return (
    <Stack spacing={3}>
      <Stack>
        <LabelStyle color="black">Titre</LabelStyle>
        <TextField
          fullWidth
          label=" "
          size="small"
          inputProps={{ autoCapitalize: 'on' }}
          error={Boolean(touched.name && errors.name)}
          disabled={!userCanEdit}
          helperText={touched.name && errors.name}
          {...getFieldProps('name')}
          sx={{
            color: 'white',
            fontWeight: ' bold',
            '.MuiInputBase-input.MuiOutlinedInput-input': {
              fontSize: 12
            }
          }}
        />
      </Stack>

      <Stack width={1} direction="row" spacing={2} sx={{ cursor: 'pointer' }} ref={anchorEl}>
        <Stack width={1} onClick={() => (userCanEdit ? onOpenPicker() : undefined)}>
          <LabelStyle sx={{ width: 'max-content' }} color="black">
            Date du début
          </LabelStyle>
          <TextField
            fullWidth
            label=" "
            size="small"
            disabled={!userCanEdit}
            value={startTime ? format(gDate(startTime), 'dd MMM yyyy', { locale: LocalFr }) : ''}
            sx={{
              '.MuiInputBase-input.MuiOutlinedInput-input': {
                fontSize: 13
              }
            }}
          />
        </Stack>

        <Stack width={1} onClick={() => (userCanEdit ? onOpenPicker() : undefined)}>
          <LabelStyle sx={{ width: 'max-content' }} color="black">
            Date de fin
          </LabelStyle>
          <TextField
            fullWidth
            label=" "
            size="small"
            disabled={!userCanEdit}
            value={endTime ? format(gDate(endTime), 'dd MMM yyyy', { locale: LocalFr }) : ''}
            sx={{
              '.MuiInputBase-input.MuiOutlinedInput-input': {
                fontSize: 12
              }
            }}
          />
        </Stack>
      </Stack>
      <InfoVisibilitySection onChange={setFieldValue} disabled={!userCanEdit} value={values?.isPrivate} />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">État</InputLabel>
        <Select
          size="small"
          label="État"
          disabled={!userCanEdit}
          labelId="demo-simple-select-label"
          value={stateConverter(values?.state || PROJECT_STATE_FR.IN_PROGRESS)}
          onChange={(val) => setFieldValue('state', stateReverseConverter(val.target.value))}
          sx={{
            '.MuiInputBase-input.MuiOutlinedInput-input': {
              fontSize: 13
            }
          }}
        >
          {Object.entries(PROJECT_STATE_FR).map(([_, value]) => (
            <MenuItem key={value} value={value} sx={{ fontSize: 13 }}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Description"
        multiline
        disabled={!userCanEdit}
        rows={3}
        size="small"
        {...getFieldProps('description')}
        sx={{
          color: 'white',
          fontWeight: ' bold',
          '.MuiInputBase-input.MuiOutlinedInput-input': {
            fontSize: 13
          }
        }}
      />

      <Tooltip title="Vous n'avez pas les droits pour effectuer cette action" open={!userCanEdit}>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          disabled={!userCanEdit} // Désactive le bouton lorsque userCanEdit est false
          sx={{
            bgcolor: userCanEdit ? 'black' : 'grey', // Grise le bouton lorsque userCanEdit est false
            color: 'white',
            ':hover': { bgcolor: userCanEdit ? 'black' : 'grey', color: 'white' } // Grise le bouton au survol lorsque userCanEdit est false
          }}
        >
          Enregistrer
        </LoadingButton>
      </Tooltip>

      {openPicker && (
        <OkyCalendar
          dates={[startTime, endTime]}
          open={openPicker}
          onClose={onClosePicker}
          onChange={handleDueDateChange}
          anchorEl={anchorEl.current}
          dataInterval={values?.dataInterval}
          disabled={!userCanEdit}
        />
      )}
    </Stack>
  );
};

/**
 * Renders a section with two cards that allow the user to toggle between a public and private visibility setting for a project.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onChange - A callback function that is called when the visibility setting is changed.
 * @param {boolean} [props.disabled=false] - A flag indicating whether the visibility setting is disabled.
 * @param {Object} props.value - The current visibility setting
 * @returns {JSX.Element} - The rendered component.
 */
const InfoVisibilitySection = ({ onChange, disabled = false, value }) => {
  // console.log({ value });
  const [isPrivate, setPrivate] = useState(value);

  const handleChange = () => {
    if (disabled) return; // Ignore les interactions lorsque disabled est true
    setPrivate(!isPrivate);
    onChange('isPrivate', !isPrivate);
  };

  return (
    <Stack direction="row" spacing={2}>
      <InfoVisibilitySectionCard
        selected={!isPrivate}
        title="Public"
        description="Les tâches seront visible pour tous les collaborateurs"
        onSelected={handleChange}
        disabled={disabled} // Ajoute la prop disabled
      />
      <InfoVisibilitySectionCard
        selected={isPrivate}
        title="Privé"
        description="Les tâches seront visible seulement pour les personnes assignées"
        onSelected={handleChange}
        disabled={disabled} // Ajoute la prop disabled
      />
    </Stack>
  );
};

const InfoVisibilitySectionCard = ({ description, selected, title, onSelected, disabled }) => {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      sx={{
        p: 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.6 : 1, // Grise la carte lorsque disabled est true
        ...(selected && { outline: `2px solid ${baseColors.BLUELIGHT}` })
      }}
      onClick={!disabled && onSelected} // Ignore le clic lorsque disabled est true
    >
      <Stack>
        <Typography variant="overline"> {title} </Typography>
        <Typography variant="caption" fontWeight={100}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

const CollabSection = ({ project, isNew, onChange, userCanEdit }) => {
  const handleChange = (field, values) => {
    onChange(field, values);
  };

  return <UserAccessView project={project} isNew={isNew} handleDetailChange={handleChange} userCanEdit={userCanEdit} />;
};
