import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Iconify from 'src/components/Iconify';
import MoreMenuButtonV2 from 'src/components/MoreMenuButtonV2';
import { UploadMultiFile } from 'src/components/upload';
import { useToggleV2 } from 'src/hooks/useToggle';
import { TaskType } from 'src/models/task_m';
import { dispatch } from 'src/redux/store';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { serverTime } from 'src/utils/serverTime';
import { multipleFilesSave } from 'src/redux/slices/document';
import UploadingOneFileProgress from 'src/components/UploadingOneFileProgress';
import { deleteTask } from 'src/redux/slices/kanban';
import { useSnackbar } from 'notistack';
import { useCompletedAllSubTasksByTaskId } from 'src/section/project/v4/content/project_content/list/V4ProjectContentListTask';




/**
 * Renders the toolbar for the task item detail view.
 *
 * @param {TaskType} task - The task object.
 * @param {function} onUpdate - A function to update the task.
 * @param {function} onClose - A function to close the task detail view.
 * @param {object} user - The current user object.
 * @param {boolean} [canDelete=true] - Whether the user can delete the task.
 * @param {boolean} [canEdit=true] - Whether the user can edit the task.
 * @param {function} forceUpdate - A function to force an update.
 * @param {array} attachement - The attachments for the task.
 * @param {function} setAttachement - A function to set the attachments for the task.
 * @param {boolean} loadingAttachement - Whether the attachments are currently loading.
 * @param {function} setLoadingAttachement - A function to set the loading state of the attachments.
 * @returns {JSX.Element} The task item detail toolbar.
 */
export default function TaskItemDetailToolbar({ task, onUpdate, onClose, user, canDelete = true, canEdit = true, forceUpdate, attachement, setAttachement, loadingAttachement, setLoadingAttachement }) {

  const { email, displayName, uid: id } = user;
  const [completed, setCompleted] = useState(task?.completed);
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useToggleV2();
  const [open, handleOpenMenu, handleCloseMenu] = useToggleV2();
  const [openFile, handleOpenFile, handleCloseFile] = useToggleV2();
  const [deleting, setDeleting] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [uploadings, setUploadingFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  // const completedAllSubTasksByTaskId = useCompletedAllSubTasksByTaskId(task?.id);

  console.log({ task, onUpdate, onClose, user, canDelete, canEdit, forceUpdate, attachement, setAttachement, loadingAttachement, setLoadingAttachement });



  // useEffect(() => {
  //   setFiles(task?.attachments || []);
  // }, [task?.attachments]);

  const handleCompleted = () => {
    setCompleted(!completed);
    dispatch(onUpdate(task, { completed: !completed }, () => {
      //completedAllSubTasksByTaskId()
    }));
  };

  const handleDelete = () => {
    handleCloseMenu();
    setDeleting(true);
    dispatch(
      deleteTask({ cardId: task?.id }, () => {
        enqueueSnackbar('Tâche supprimée avec succès', { variant: 'success' });
        setDeleting(false);
        onCloseConfirm();
        onClose && onClose();
      })
    );
  };

  const handleCopyAdresse = () => {

    navigator.clipboard.writeText(window.location.href + `/${task?.id}`);
    handleCloseMenu();
    //console.log('ACTION 2', task.id);
    enqueueSnackbar("Lien copié dans le presse-papier", {
      variant: 'success',

    });
    //snack
  };

  const handleOpenConfirmation = () => {
    handleCloseMenu();
    onOpenConfirm();
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [setFiles, files]
  );

  const handleRemove = useCallback(
    (file) => {
      setFiles(files.filter((one) => one.name !== file.name));
    },
    [setFiles, files]
  );

  const handleRemoveAll = useCallback((val) => {
    setFiles([]);
  }, []);

  const handleFiles = (_files) => {
    const user_to_files = _files.map((_file) => {
      return {
        ..._file,
        email,
        userId: id,
        id: _file?.id || uuidv4(),
        displayName,
        createAt: serverTime()
      };
    });
    setUploading(false);

    dispatch(onUpdate(task, {
      attachments: [
        ...user_to_files,
        ...attachement
      ]
    }, () => { }));
  };

  const onHandleUplaodFile = () => {
    setUploading(true);
    setLoadingAttachement(true);
    handleCloseFile();
    multipleFilesSave(
      files,
      handleFiles,
      (data) => {
        //console.log('data', data);
        setAttachement(data);
        forceUpdate();
        setLoadingAttachement(false);
      },
      setUploadingFiles)
    handleRemoveAll();
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Button
          size="small"
          color="inherit"
          onClick={handleCompleted}
          variant={completed ? 'contained' : 'outlined'}
          {...(completed && { sx: { color: 'white', bgcolor: 'green' } })}
          startIcon={<Icon icon="ph:check-light" />}
          disabled={!canEdit}
        >
          {completed ? 'Terminée' : 'Marquer comme terminée'}
        </Button>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button variant="contained" color="primary" onClick={onClose} disabled={!canEdit}>
          Enregistrer
        </Button>
        <Tooltip title="Ajouter une pièce jointe" arrow placement="top">
          <IconButton size="small" onClick={handleOpenFile} disabled={!canEdit}>
            <Icon icon="fluent-mdl2:attach" />
          </IconButton>
        </Tooltip>

        <MoreMenuButtonV2
          open={open}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}

          actions={
            <>
              <MenuItem onClick={handleOpenConfirmation} disabled={!canDelete}>
                <Iconify icon="eva:trash-fill" color="red" />
                Supprimer
              </MenuItem>

              {/* <MenuItem onClick={handleCopyAdresse}>
                <Iconify icon="mdi:link-variant" />
                Copier le lien
              </MenuItem> */}
            </>
          }
        />

        <ConfirmDialog
          open={openConfirm}
          onClose={onCloseConfirm}
          title={
            <Typography>
              Êtes-vous sûr de vouloir supprimer la tâche <strong>{task?.name}</strong>?
            </Typography>
          }
          actions={
            <>
              <Button variant="outlined" color="inherit" onClick={onCloseConfirm}>
                Annuler
              </Button>
              <LoadingButton loading={deleting} variant="contained" color="error" onClick={handleDelete}>
                Supprimer
              </LoadingButton>
            </>
          }
        />
        {openFile && (
          <Dialog open={openFile} onClose={handleCloseFile}>
            <DialogContent>
              <UploadMultiFile
                files={files}
                loading={isUploading || loadingAttachement}
                onChange={setFiles}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
                onUpload={onHandleUplaodFile}
              />
            </DialogContent>
          </Dialog>
        )}
        {uploadings.map((upload, idx) => (
          <UploadingOneFileProgress key={upload?.name || '' + idx} uploading={upload} />
        ))}
      </Stack>
    </Stack>
  );
}
