import { Icon } from '@iconify/react';
import { Button, Stack, useTheme } from '@mui/material';
import React, { memo } from 'react';
import LabelStyle from 'src/components/LabelStyle';
import { useToggleV2 } from 'src/hooks/useToggle';
import { TaskType } from 'src/models/task_m';
import { SubTaskNewLine, SubTaskView } from '../kanban/KanbanTaskDetailsInfo';


/**
 * Renders a component that displays a list of sub-tasks for a task, with the ability to add a new sub-task.
 *
 * @param {Object} props - The component props.
 * @param {TaskType} props.task - The task object.
 * @param {boolean} [props.disabled=false] - Whether the component is disabled.
 * @param {any} [props.rights=null] - The user's rights for the task.
 * @param {any} [props.focusId=null] - The ID of the focused sub-task.
 * @param {function} props.setTask - A function to update the task object.
 * @param {any} [rest] - Any additional props passed to the component.
 * @returns {JSX.Element} - The rendered TaskSubTask component.
 */
const TaskSubTask = ({ task, disabled = false, rights = null, focusId = null, setTask, ...rest }) =>{
  const [open, onOpen, onClose] = useToggleV2();
  const theme = useTheme();

  // console.log({rights});

  return (
    <Stack width={1} spacing={3}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          startIcon={<Icon icon="eva:plus-fill" />}
          onClick={onOpen}
          disabled={disabled}
        >
          Ajouter une sous-tâche
        </Button>
      </Stack>
      {open && <SubTaskNewLine theme={theme} details={task} exit={onClose} task={task} />}
      <Stack>
        <SubTaskView task={task} theme={theme} parent={task} disabled={disabled} rights={rights} focusId={focusId} />
      </Stack>
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.task === nextProps.task &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.rights === nextProps.rights &&
    prevProps.focusId === nextProps.focusId
  );
};

export default memo(TaskSubTask, areEqual);