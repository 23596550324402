import { createSlice } from '@reduxjs/toolkit';
import { firestore, auth } from 'src/contexts/FirebaseContext';
import { hasAccountExpired } from 'src/contexts/MicrosoftMailContext';
import axios from 'axios';
import { MAIL_FOLDER, useMailContext } from 'src/section/mail/MailContext';
import { keys, uniq, isFunction, times } from 'lodash';
import { CLOUD_MAIL_API_KEY, CLOUD_MAIL_CUSTOM_API_KEY, MICROSOFT_CONFIG } from 'src/config';
import Timer from 'src/helpers/timer';
import Mail from 'src/section/mail_v2/models/Mail';
import { igniteCustomAccount } from 'src/redux/slices/customMail';
import { COLOR_OPTIONS } from 'src/section/project/ProjectCardSimple';
import { MAIL_TIMER } from 'src/helpers/mailTimer';

const slice = createSlice({
  name: 'microsoft-mail',
  initialState: {
    accounts: {},
    folders: {},
    mails: {},
    loading: [],
    refresher: {
      callback: null,
      lastUpdated: null,
      count: 0
    },
    notify: (mail) => {}
  },
  reducers: {
    setNotifyCallback: (state, action) => {
      state.notify = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = {
        ...state.accounts,
        ...action.payload
      };
    },
    setFolders: (state, action) => {
      state.folders = {
        ...state.folders,
        ...action.payload
      };
    },
    setMailsInFolders: (state, action) => {
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mails = action.payload.mails;
      state.mails = {
        ...(state.mails || {}),
        [email]: {
          ...(state.mails[email] || {}),
          [folder]: {
            ...((state.mails[email] || {})[folder] || {}),
            ...mails
          }
        }
      };
    },
    removeMailsInFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      

      delete ((copy[email] || {})[folder] || {})[mailId];

      state.mails = copy;
    },
    updateMailsInFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const values = action.payload.values;

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      if (!mail) return;

      Object.assign(mail, { ...values });

      state.mails = copy;
    },

    updateMailFolder: (state, action) => {
      //change the folder of the mail
      const copy = { ...state.mails };
      const email = action.payload.email;
      const currentFolder = action.payload.currentFolder;
      const nextFolder = action.payload.nextFolder;
      const mailId = action.payload.mailId;

      const mail = ((copy[email] || {})[currentFolder] || {})[mailId];

      

      if (!mail) return;

      delete ((copy[email] || {})[currentFolder] || {})[mailId];

      // push to next folder and update folder property
      //uodate folder property
      mail.folder = nextFolder;

      copy[email][nextFolder] = {
        ...copy[email][nextFolder],
        [mailId]: mail
      };

      state.mails = copy;
    },

    setDrafts: (state, action) => {
      const email = action.payload.email;
      const drafts = action.payload.drafts;
      state.mails = {
        ...(state.mails || {}),
        [email]: {
          ...(state.mails[email] || {}),
          [MAIL_FOLDER.DRAFTS]: {
            ...((state.mails[email] || {})[MAIL_FOLDER.DRAFTS] || {}),
            ...drafts
          }
        }
      };
    },

    deleteDraft: (state, action) => {
      const email = action.payload.email;
      const draftId = action.payload.draftId;
      const copy = { ...state.mails };

      delete ((copy[email] || {})[MAIL_FOLDER.DRAFTS] || {})[draftId];

      state.mails = copy;
    },

    updateDraft: (state, action) => {
      const email = action.payload.email;
      const draftId = action.payload.draftId;
      const values = action.payload.values;

      const copy = { ...state.mails };

      const draft = ((copy[email] || {})[MAIL_FOLDER.DRAFTS] || {})[draftId];

      

      if (!draft) return;

      Object.assign(draft, { ...values });

      state.mails = copy;
    },

    updateMailFlag: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const oldFlag = action.payload.oldFlag;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const flag = action.payload.flag;

      //email.flags= ['unseen', 'flagged', 'answered', 'draft', 'recent']

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      if (!mail) return;

      // add flag to mail.flags but if it already exist remove it
      if (flag.length === 0) {
        mail.flags = mail.flags.filter((el) => el !== oldFlag);
      }
      if (mail.flags.includes(flag)) {
        mail.flags = mail.flags.filter((el) => el !== flag);
      } else {
        mail.flags = [...mail.flags, flag];
      }

      state.mails = copy;
    },

    setLoading: (state, action) => {
      state.loading = uniq([...state.loading, ...action.payload]);
    },
    revokeLoading: (state, action) => {
      state.loading = state.loading?.filter((key) => !action.payload.includes(key));
    },
    removeAccount: (state, action) => {
      delete state.accounts[action.payload];
      delete state.folders[action.payload];
      delete state.mails[action.payload];
    },
    reduceUnreadMailCount: (state, action) => {
      const email = action.payload;
      const cursor = (state.folders[email] || {})[MAIL_FOLDER.INBOX];
      const prev = cursor?.unreadItemCount || 0;
      const unreadItemCount = prev - 1 < 0 ? 0 : prev - 1;
      const result = {
        ...state.folders,
        [email]: {
          ...state.folders[email],
          [MAIL_FOLDER.INBOX]: {
            ...cursor,
            unreadItemCount
          }
        }
      };

      state.folders = result;
    },
    updateMailRefresher: (state, action) => {
      if (isFunction(state.refresher.callback)) {
        state.refresher.callback();
      }

      state.refresher = {
        callback: action.payload,
        lastUpdated: new Date(),
        count: state.refresher.count + 1
      };
    }
  }
});

export const {
  setAccounts,
  setFolders,
  setMailsInFolders,
  setLoading,
  revokeLoading,
  removeMailsInFolder,
  updateMailsInFolder,
  removeAccount,
  reduceUnreadMailCount,
  updateMailRefresher,
  setNotifyCallback,
  updateMailFolder,
  setDrafts,
  deleteDraft,
  updateDraft,
  updateMailFlag
} = slice.actions;

const microsoftMailReducer = slice.reducer;

export default microsoftMailReducer;

export const persistToken = ({ email, result, userId }) => {
  return async (dispatch, getStore) => {
    //console.log('persistToken', email, result, userId);
    try {
      const accounts = getStore()?.microsoftMail?.accounts || {};
      const isExpired = hasAccountExpired(accounts[email]);

      if (isExpired) {
        await firestore.runTransaction((transaction) => {
          const docRef = firestore.collection('users').doc(userId);
          return transaction.get(docRef).then((snap) => {
            const linkedAccount = {
              ...(snap?.data()?.linkedAccount || {}),
              ...result
            };

            transaction.update(docRef, { linkedAccount });

            dispatch(setAccounts(result));
            dispatch(
              getAccountFolders({
                account: result[email],
                onResolve: () => {
                  const account = result[email];

                  dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX }));
                  dispatch(getMails({ account, folderType: MAIL_FOLDER.SENT }));
                  dispatch(getMails({ account, folderType: MAIL_FOLDER.SPAM }));
                  dispatch(getMails({ account, folderType: MAIL_FOLDER.TRASH }));
                  dispatch(getDrafts({ account }));
                }
              })
            );
          });
        });
      }
    } catch (error) {
      
    }
  };
};

export const igniteMsAccount = ({ accountSnap }) => {
  return (dispatch, getStore) => {
    try {
      //console.log('igniteMsAccount', accountSnap);
      const snap = getStore()?.microsoftMail?.accounts || {};

      dispatch(setAccounts(accountSnap));

      keys(accountSnap).forEach((email) => {
        if (snap[email]) return;

        const account = accountSnap[email];
        dispatch(
          refreshToken({
            account,
            callback: (account) => {
              dispatch(
                getAccountFolders({
                  account,
                  onResolve: () => {
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX }));
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.SENT }));
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.SPAM }));
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.TRASH }));
                    dispatch(getDrafts({ account }));
                  }
                })
              );
            }
          })
        );
      });
    } catch (error) {
      
    }
  };
};

export const updateAccountData = ({ email, data, userId }) => {
  return async (dispatch) => {
    try {
      await axios.patch(
        `${CLOUD_MAIL_API_KEY}/account`,
        {
          ...data
        },
        {
          headers: {
            userid: auth.currentUser.uid
          }
        }
      );

      const account = { [email]: data };

      dispatch(setAccounts(account));
      dispatch(refreshLinkedAccount({}));
    } catch (e) {
      
    }
  };
};

export const demoSendAttachement = ({ account, files, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      const { token } = account;

      if (!token) return account;

      const mailFiles = [...(files || [])];
      const msFiles = [];

      for (const file of mailFiles) {
        const contentBytes = await transformFileToBase64(file);
        msFiles.push({
          '@odata.type': '#microsoft.graph.fileAttachment',
          id: file?.id,
          name: file?.name,
          contentType: file?.type,
          size: file?.size,
          contentBytes: window.btoa(contentBytes)
        });
      }

      for (const file of msFiles) {
        const { id, ...rest } = file;
        const parsed = { ...rest };
        await axios.post(`${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}/attachments`, file, {
          onUploadProgress: (progressEvent) => {},
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
          }
        });
      }

      onResolve && onResolve();
    } catch (error) {
      
      onReject && onReject();
    }
  };
};

export const sendMsMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

           //console.log({ email });
            
            const snap = await axios.post(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages`,
              {
                subject: email?.subject,
                body: {
                  contentType: 'HTML',
                  content: email?.body
                },
                toRecipients: transformStringRecipientsToArray(email?.to),
                ccRecipients: transformStringRecipientsToArray(email?.cc),
                bccRecipients: transformStringRecipientsToArray(email?.cci),
                replyTo : transformStringRecipientsToArray(email?.replyTo),
                isDraft: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              },
              
            );

            

            const mailFiles = [...(email?.files || [])];
            const msFiles = [];

            for (const file of mailFiles) {
              //  const contentBytes = await transformFileToBase64(file);
              //  const contentBytes = getMicrosoftAttachmentFromFile(file)
              const contentBytes = await transformFile(file);
              msFiles.push({
                '@odata.type': '#microsoft.graph.fileAttachment',
                id: file?.id,
                name: file?.name,
                contentType: file?.type,
                size: file?.size,
                contentBytes
              });
            }

            for (const file of msFiles) {
              const { id, ...rest } = file;
              const parsed = { ...rest };
              await axios.post(`${MICROSOFT_CONFIG.GRAPH_URL}/messages/${snap.data?.id}/attachments`, file, {
                onUploadProgress: (progressEvent) => {},
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              });
            }

            await axios.post(`${MICROSOFT_CONFIG.GRAPH_URL}/messages/${snap.data?.id}/send`, null, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
              }
            });

            dispatch(getMails({ account, folderType: MAIL_FOLDER.SENT }));
            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      
      onReject && onReject();
    }
  };
};

export const saveToDraft = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

            const snap = await axios.post(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages`,
              {
                subject: email?.subject,
                body: {
                  contentType: 'HTML',
                  content: email?.body
                },
                toRecipients: transformStringRecipientsToArray(email?.to),
                ccRecipients: transformStringRecipientsToArray(email?.cc),
                bccRecipients: transformStringRecipientsToArray(email?.cci),
                isDraft: true
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            const mailFiles = [...(email?.files || [])];
            const msFiles = [];

            for (const file of mailFiles) {
              const contentBytes = await transformFileToBase64(file);
              msFiles.push({
                '@odata.type': '#microsoft.graph.fileAttachment',
                id: file?.id,
                name: file?.name,
                contentType: file?.type,
                size: file?.size,
                contentBytes: window.btoa(contentBytes)
              });
            }

            for (const file of msFiles) {
              const { id, ...rest } = file;
              const parsed = { ...rest };
              await axios.post(`${MICROSOFT_CONFIG.GRAPH_URL}/messages/${snap.data?.id}/attachments`, file, {
                onUploadProgress: (progressEvent) => {},
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              });
            }

            onResolve && onResolve();

            dispatch(getDrafts({ account }));
            
          }
        })

      );
    } catch (error) {
      
      onReject && onReject();
    }
  };
};

export const getDrafts = ({ account, onResolve, onReject }) => {
  //get normal mail and check if isDraft is true and put them in drafts folder
  return async (dispatch, getStore) => {
    try {
      const { token } = account;

      if (!token) return account;

      const snap = await axios.get(`${MICROSOFT_CONFIG.GRAPH_URL}/messages?isDraft=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        }
      });

      const drafts = snap.data?.value?.filter((el) => el?.isDraft) || [];

      const result = listToObject(drafts.map((el) => {
        const mail = transformMsMailToCustom(el);
        mail.from = { email: account?.user?.email, name: account?.user?.displayName}
        //console.log('getDrafts', mail);
        return mail
      }));

      dispatch(setDrafts({ email: account?.user?.email, drafts: result }));

      onResolve && onResolve();
    } catch (error) {
      
      onReject && onReject();
    }
  };  
}

export const deleteDraftById = ({ account, draftId, onResolve, onReject }) => {
  //console.log('deleteDraftById', draftId);
  return async (dispatch, getStore) => {
    try {
      const { token } = account;

      if (!token) return account;

     // console.log({ draftId });

      await axios.delete(`${MICROSOFT_CONFIG.GRAPH_URL}/messages/${draftId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json'
        }
      });

      dispatch(deleteDraft({ email: account?.user?.email, draftId }));

      onResolve && onResolve();
    } catch (error) {
      
      onReject && onReject();
    }
  }
}

export const updateMailFolderRemote = ({ email, currentFolder, nextFolder, mailId }) => {
  return async (dispatch, getStore) => {
    try {
      const account = getStore()?.microsoftMail?.accounts[email];
      const { token } = account;

      if (!token) return account;

      const folders = getStore()?.microsoftMail?.folders || {};
      const folderId = folders[email][nextFolder]?.id;

      

      await axios.post(
        `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}/move`,
        {
          destinationId: folderId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
          }
        }
      );

      dispatch(
        updateMailFolder({
          email,
          currentFolder,
          nextFolder,
          mailId
        })
      );
    } catch (error) {
      
    }
  };
};

export const addToSpam = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][MAIL_FOLDER.SPAM]?.id;

            

            dispatch(
              updateMailFolder({
                email: account?.user?.email,
                currentFolder: folderType,
                nextFolder: MAIL_FOLDER.SPAM,
                mailId: mailId
              })
            );

            await axios.post(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}/move`,
              {
                destinationId: folderId
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      

      onReject && onReject();
      dispatch(
        updateMailFolder({
          email: account?.user?.email,
          currentFolder: MAIL_FOLDER.SPAM,
          nextFolder: folderType,
          mailId: mailId
        })
      );
    }
  };
};

export const deleteCompletyMail = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const snap = await axios.delete(`${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
              }
            });

            dispatch(removeMailsInFolder({ email: user?.email, folder: folderType, mailId }));
            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      onReject && onReject();
      
    }
  };
};

export const getAccountFolders = ({ account, onResolve, onReject }) => {
  return async (disptach) => {
    const utils = new LoadingUtil(disptach, `getAccountFolders-${account?.user?.email} `);

    try {
      disptach(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;
            utils.start();

            const snap = await axios.get(`${MICROSOFT_CONFIG.GRAPH_URL}/mailFolders`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
              }
            });

            const value = snap.data?.value || [];

            let data = {};

            value.forEach((doc) => {
              if (doc?.displayName === 'Inbox' || doc?.displayName === 'Boîte de réception') {
                data = {
                  ...data,
                  [MAIL_FOLDER.INBOX]: doc
                };
              }

              if (doc?.displayName === 'Outbox' || doc?.displayName === 'Éléments envoyés') {
                data = {
                  ...data,
                  [MAIL_FOLDER.SENT]: doc
                };
              }

              if (doc?.displayName === 'Junk Email' || doc?.displayName === 'Courrier indésirable') {
                data = {
                  ...data,
                  [MAIL_FOLDER.SPAM]: doc
                };
              }

              if (doc?.displayName === 'Deleted Items' || doc?.displayName === 'Éléments supprimés') {
                data = {
                  ...data,
                  [MAIL_FOLDER.TRASH]: doc
                };
              }
            });

            disptach(
              setFolders({
                [user?.email]: data
              })
            );

            utils.end();
            onResolve && onResolve(data);
          }
        })
      );
    } catch (error) {
      
      onReject && onReject();
      utils.end();
    }
  };
};

export const getMails = ({ account = {}, folderType, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    const utils = new LoadingUtil(dispatch, `getMails-${account?.user?.email}-${folderType}`);
    
    try {
      const { token, user } = account;

      if (!token) return account;

      utils.start();

      //console.log('getMails', account?.user?.email, folderType);

      const notify = getStore()?.microsoftMail?.notify;
      const mails = getStore()?.microsoftMail?.mails[account?.user?.email] || {};
      const folders = getStore()?.microsoftMail?.folders || {};
      const folderId = folders[account?.user?.email][folderType]?.id;
      const  snap = await axios.get(`${MICROSOFT_CONFIG.GRAPH_URL}/mailFolders/${folderId}/messages?$top=150`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
          }
        });
      

      const result = {
        folder: folderType,
        email: account?.user?.email,
        mails: listToObject((snap.data?.value || [])?.map((el) => transformMsMailToCustom(el)))
      };

      // console.log('getMails result : ', snap);
      //console.log('getMails', result);

      dispatch(setMailsInFolders(result));
      onResolve && onResolve();
      utils.end();

      if (MAIL_FOLDER.INBOX === folderType && notify) {
        const newMail = [];

        const prev = mails[MAIL_FOLDER.INBOX];
        const current = result.mails;

        keys(current).map((id) => {
          if (!prev[id]) {
            newMail.push(current[id]);
          }
        });

        newMail.forEach((mail) => {
          notify({ ...mail, account });
        });
      }
    } catch (error) {
      
      onReject && onReject();
      utils.end();
    }
  };
};

export const updateMsMailFlag = ({ account, folderType, oldFlag, nextFlag, mailId }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              updateMailFlag({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                oldFlag,
                flag: nextFlag
              })
            );

            await axios.patch(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}`,
              {
                flag: nextFlag
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            dispatch(reduceUnreadMailCount(account?.user?.email));
          }
        })
      );
    } catch (error) {
      
    }
  };
};

export const toggleMsMailImportance = ({ account, folderType, mailId, isImportant, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

            const snap = await axios.patch(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}`,
              {
                importance: isImportant ? 'high' : 'normal'
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            dispatch(
              updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                  isImportant
                }
              })
            );

            dispatch(getMails({ account, folderType }));

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      onReject && onReject();
      
    }
  };
};

export const getMsMailAttachment = ({ account, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;
            
            if (!token) return account;

            console.info('getMsMailAttachment', mailId);

            const snap = await axios.get(`${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}/attachments`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
              }
            });

            //console.log('getMsMailAttachment', snap.data);

            const result = (snap.data.value || [])?.map((file) => {
              const raw = window.atob(file.contentBytes);
              const rawLength = raw.length;
              let array = new Uint8Array(new ArrayBuffer(rawLength)); // pass your byte response to this constructor

              for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
              }

              const blob = new Blob([array], { type: file.contentType });

              const url = window.URL.createObjectURL(blob);
              return {
                ...file,
                type: file?.contentType,
                url
              };
            });

            onResolve && onResolve(result);
          }
        })
      );
    } catch (error) {
      onReject && onReject();
      
    }
  };
};
export const updateDraftMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

            const snap = await axios.patch(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${email?.id}`,
              {
                subject: email?.subject,
                body: {
                  contentType: 'HTML',
                  content: email?.body
                },
                toRecipients: transformStringRecipientsToArray(email?.to),
                ccRecipients: transformStringRecipientsToArray(email?.cc)
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      
      onReject && onReject();
    }
  };
};

export const markMailAsRead = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                  isRead: true
                }
              })
            );

            await axios.patch(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}`,
              {
                isRead: true
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            dispatch(reduceUnreadMailCount(account?.user?.email));
          }
        })
      );
    } catch (error) {
      
    }
  };
};

export const markMailAsUnread = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                  isRead: false
                }
              })
            );

            await axios.patch(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}`,
              {
                isRead: false
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            dispatch(reduceUnreadMailCount(account?.user?.email));
          }
        })
      );
    } catch (error) {
      
    }
  };
};

export const deleteMsMail = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][MAIL_FOLDER.TRASH]?.id;
            dispatch(
              updateMailFolder({
                email: account?.user?.email,
                currentFolder: folderType,
                nextFolder: MAIL_FOLDER.TRASH,
                mailId: mailId
              })
            );

            
            await axios.post(
              `${MICROSOFT_CONFIG.GRAPH_URL}/messages/${mailId}/move`,
              {
                destinationId: folderId
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            //dispatch(getMails({ account, folderType: MAIL_FOLDER.TRASH }));

            // Implement update of the mail

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      

      onReject && onReject();
      dispatch(
        updateMailFolder({
          email: account?.user?.email,
          currentFolder: MAIL_FOLDER.TRASH,
          nextFolder: folderType,
          mailId: mailId
        })
      );
    }
  };
};

export const disconnectAccount = (account) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshToken`);
    try {
      utils.start();

      dispatch(removeAccount(account?.user?.email));

      await axios.delete(
        `${CLOUD_MAIL_API_KEY}/account?accountId=${account?.id}&token=${account?.token}&plateform=microsoft`,
        {
          headers: {
            userid: auth.currentUser.uid,
            accountId: account?.id
          }
        }
      );

      utils.end();
    } catch (error) {
      
      utils.end();
    }
  };
};
const refreshToken = ({ account, callback }) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshToken`);

    try {
      utils.start();

      if (!hasAccountExpired(account)) {
        utils.end();

        return callback(account);
      }

      const snap = await axios.get(`${CLOUD_MAIL_CUSTOM_API_KEY}/refresh-token`, {
        headers: {
          userId: auth.currentUser.uid,
          accountId: account?.id
        }
      });

      // console.log({ snap });

      const data = snap.data;
      callback(data);

      dispatch(updateAccountData({ email: data?.user?.email, data, userId: auth.currentUser.uid }));
      utils.end();
    } catch (error) {
      utils.end();
      
    }
  };
};
export const refreshLinkedAccount = ({ emmitNotification }) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshLinkedAccount`);

    try {
      utils.start();
      const snap = await axios.get(`${CLOUD_MAIL_API_KEY}/account`, {
        headers: {
          userid: auth.currentUser.uid
        }
      });

      let accountSnap = {};
      let customAccountSnap = {};

      keys(snap.data)?.forEach((id) => {
        const email = snap.data[id]?.user?.email;
        if (snap.data[id]?.platform === 'custom') {
          customAccountSnap[email] = {
            ...snap.data[id],
            email,
            color: snap.data[id]?.color || COLOR_OPTIONS[0]
          };
        } else {
          accountSnap[email] = { ...snap.data[id], email };
        }
      });

      dispatch(igniteCustomAccount({ accountSnap: customAccountSnap }));
      dispatch(igniteMsAccount({ accountSnap }));
      utils.end();

      if (emmitNotification) {
        dispatch(setNotifyCallback(emmitNotification));
      }
    } catch (error) {
      utils.end();
      
    }
  };
};

const executeQeuery = async (functions, limit , delai) => {
  const result = [];
  let count = 0;
  for (const func of functions) {
    if (count >= limit) {
      await new Promise((resolve) => setTimeout(resolve, delai));
      count = 0;
    }
    //console.log('executeQeuery', count);
    await func;
    count++;
  }

  return result;
}


export const refreshMsMails = () => {
  return async (dispatch, getStore) => {
    try {
      let count = 0;
      const MAX_CALLS_PER_MINUTE = 10; // Définissez votre limite ici
      const ONE_MINUTE = 60000; // en millisecondes

      const canceler = Timer.repeat(MAIL_TIMER, () => {
        if (count < MAX_CALLS_PER_MINUTE) {
          const accountSnap = getStore()?.microsoftMail?.accounts || {};

          keys(accountSnap).forEach((email) => {
            const account = accountSnap[email];
            //console.log('refreshMsMails');
            dispatch(
              refreshToken({
                account,
                callback: async (account) => {
                  executeQeuery([
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX })),
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.SENT })),
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.SPAM })),
                    dispatch(getMails({ account, folderType: MAIL_FOLDER.TRASH }))
                  ], 4, 1000);
                }
              })
            );
          });

          count++;
        }

        // Réinitialiser le compteur toutes les minutes
        if (Date.now() % ONE_MINUTE === 0) {
          count = 0;
        }
      });

      dispatch(updateMailRefresher(canceler));
    } catch (error) {
      
    }
  };
};

const transformMsMailToCustom = (msMail) => {
  return Mail.fromOutlook(msMail);
};
export const transformStringRecipientsToArray = (data = '') => {
  return data
    .split(',')
    .map((mail) => mail.trim().replaceAll(' ', ''))
    .filter((el) => el.length !== 0)
    .map((address) => ({
      emailAddress: {
        address
      }
    }));
};
export const transformArrayRecipientsToString = (data = []) => {
  let result = '';

  data.forEach((el) => {
    result += `${el?.emailAddress?.address},`;
  });

  return result;
};

const transformUsersArrayToString = (data = []) => {
  let result = '';

  data.forEach((el, index) => {
    const name = el?.emailAddress?.name;
    const email = el?.emailAddress?.address;
    const isLast = data.length - 1 === index;

    if (name !== email) result += `${name} <${email}>`;
    else {
      result += `<${email}>`;
    }

    if (isLast) return;

    result += ` , `;
  });

  return result;
};
export const listToObject = (list = []) => {
  let result = {};

  list.forEach((el) => {
    result[el?.id] = el;
  });

  return result;
};

export const transformToMsEmail = (data) => {
  return {};
};

class LoadingUtil {
  key = null;
  dispatch = null;

  constructor(dispatch, key) {
    this.dispatch = dispatch;
    this.key = key;
  }

  start() {
    this.dispatch(setLoading([this.key]));
  }

  end() {
    this.dispatch(revokeLoading([this.key]));
  }
}

export const transformFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getFileFromMicrososftAttachmennts = (file) => {
  const raw = window.atob(file.contentBytes);
  const rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength)); // pass your byte response to this constructor

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([array], { type: file.contentType });

  const url = window.URL.createObjectURL(blob);
  return {
    ...file,
    type: file?.contentType,
    url
  };
};

const getMicrosoftAttachmentFromFile = (fileWithUrl) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const contentBytes = reader.result.slice(reader.result.indexOf(',') + 1);
      resolve(window.btoa(contentBytes));
    };

    reader.onerror = reject;

    reader.readAsDataURL(fileWithUrl);
  });
};

const transformFile = async (file) => {
  const binary = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const binary = reader.result;
      resolve(binary);
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
  return window.btoa(binary);
};
