import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from './../guards/RoleBasedGuard';
import { userRoles } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import GuestLayout from 'src/layouts/GuestLayout';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard') || pathname.includes('/doc');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const {
    user: { role }
  } = useAuth();

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },

        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'invite/:id', element: <InvitationPage /> },
        { path: 'audience-appointment/:id', element: <AudienceAppointmentPage /> }
      ]
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          {Object.entries(role).length === 0 || !role || role?.isGuest ? (
            <GuestLayout />
          ) : (
            <DashboardLayout role={role} />
          )}
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'app/:channelId', element: <GeneralApp /> },
        { path: 'app/:channelId/:messageId', element: <GeneralApp /> },
        { path: 'test', element: <TestPage /> },
        { path: 'accueil', element: <Accueil /> },
        { path: 'accueil/fiche', element: <AccueilFiche /> },
        {
          path: 'recap',
          element: (
            <RoleBasedGuard role={userRoles.task} action="read">
              <Checking />
            </RoleBasedGuard>
          )
        },
        {
          path: 'oldtask',
          element: (
            <RoleBasedGuard role={userRoles.oldTask} action="read">
              <OldTask />
            </RoleBasedGuard>
          )
        },
        {
          path: 'oldtask/new',
          element: (
            <RoleBasedGuard role={userRoles.oldTask} action="create">
              <OldTaskNew />
            </RoleBasedGuard>
          )
        },
        {
          path: 'contact',
          element: (
            <RoleBasedGuard role={userRoles.contact} action="read">
              <Contacts />
            </RoleBasedGuard>
          )
        },
        { path: 'billings', element: <Billings /> },

        {
          path: 'affectation',
          //element: <Affectation_v4 />,
          children: [
            {
              path: '/',
              element: <Affectation_v4 />
            },
            { path: ':affectId', element: <Affectation_v4 /> }
          ]
        },
        { path: 'task-calendar', element: <TaskCalendaire /> },
        {
          path: 'task',
          element: (
            <RoleBasedGuard role={userRoles.task} action="read">
              <V4Project />
            </RoleBasedGuard>
          ),
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/task/my-tasks" replace />
            },
            {
              path: 'my-tasks',
              element: <ProjectV4MyTasks />
            },
            {
              path: 'my-workspace',
              element: <ProjectV4Workspace />
            },
            {
              path: ':projectId',
              element: (
                <RoleBasedGuard role={userRoles.task} action="read">
                  <V4ProjectContent />
                </RoleBasedGuard>
              )
            },
            {
              path: ':projectId/:taskId',
              element: (
                <RoleBasedGuard role={userRoles.task} action="read">
                  <V4ProjectContent />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            {
              path: 'cards',
              element: (
                <RoleBasedGuard role={userRoles.users} action="read">
                  <UserCards />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard role={userRoles.users} action="read">
                  <UserCards />
                </RoleBasedGuard>
              )
            },
            {
              path: 'oldlist',
              element: (
                <RoleBasedGuard role={userRoles.users} action="read">
                  <UserList />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard role={userRoles.users} action="create">
                  <UserCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit',
              element: (
                <RoleBasedGuard role={userRoles.users} action="edit">
                  <UserCreate />
                </RoleBasedGuard>
              )
            },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },

        { path: 'calendar', element: <Calendar /> },
        { path: 'notifications', element: <NotificationPage /> },
        {
          path: 'monitoring',
          element: (
            <RoleBasedGuard role={userRoles.monitoring} action="read">
              <Monitoring />
            </RoleBasedGuard>
          )
        }
      ]
    },
    { path: 'playground', element: <Playground /> },
    { path: 'document-edit', element: <DocumentEditing /> },
    { path: 'document-edit/:id/:docKey', element: <DocumentEditing /> },
    { path: 'affectation-print', element: <AffectationPdf /> },
    {
      path: 'mail',
      children: [
        {
          path: '',
          element: <MailRefresh />
        },
        { path: 'refresh', element: <MailRefresh /> }
      ]
    },
    {
      path: 'doc',
      element: (
        <AuthGuard>
          <RoleBasedGuard role={userRoles.document} action="read">
            <DocDriver role={role} />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/doc/folders" replace />, index: true },
        {
          path: 'folders',
          element: <DocFolders basePath="/doc/folders" />,
          children: [
            { path: '/', element: <FloderView /> },
            { path: ':docId', element: <FloderView /> }
          ]
        },
        {
          path: 'trash',
          element: <DocFolders basePath="/doc/trash" />,
          children: [
            { path: '/', element: <DocTrash /> },
            { path: ':docId', element: <DocTrash /> }
          ]
        },
        {
          path: 'share-with-me',
          element: <DocFolders basePath="/doc/share-with-me" />,
          children: [
            { path: '/', element: <DocShareWithMe /> },
            { path: ':docId', element: <DocShareWithMe /> }
          ]
        },
        {
          path: 'share-by-me',
          element: <DocFolders basePath="/doc/share-by-me" />,
          children: [
            { path: '/', element: <DocShareByMe /> },
            { path: ':docId', element: <DocShareByMe /> }
          ]
        },
        {
          path: 'tags',
          element: <DocFolders basePath="/doc/tags" />,
          children: [
            { path: '/', element: <DocTags /> },
            { path: ':docId', element: <DocTags /> }
          ]
        },
        {
          path: 'send-auth',
          element: <DocAuthRequest />
        },
        {
          path: 'auth-request',
          element: <DocAuthRequest />
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      index: true,
      element: <Navigate to="dashboard" replace />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

//#region IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
//Contact
const Contacts = Loadable(lazy(() => import('../pages/dashboard/Contact')));
//Accueil
const Accueil = Loadable(lazy(() => import('./../pages/dashboard/Accueil')));
const AccueilFiche = Loadable(lazy(() => import('./../pages/dashboard/AccueilDetails')));

const V4Project = Loadable(lazy(() => import('../section/project/v4/index')));
const V4ProjectContent = Loadable(lazy(() => import('../section/project/v4/content/project_content')));
const ProjectV4MyTasks = Loadable(lazy(() => import('../section/project/v4/content/ProjectV4MyTasks')));
const ProjectV4Workspace = Loadable(lazy(() => import('../section/project/v4/content/ProjectV4Workspace')));

const OldTask = Loadable(lazy(() => import('../pages/dashboard/OldTask')));
const Checking = Loadable(lazy(() => import('../pages/Checking')));
const OldTaskNew = Loadable(lazy(() => import('../pages/dashboard/OldTaskNew')));

//User
const TaskCalendaire = Loadable(lazy(() => import('../pages/dashboard/TaskCalendar')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const NotificationPage = Loadable(lazy(() => import('../pages/dashboard/NotificationPage')));
// Main
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const Playground = Loadable(lazy(() => import('../pages/dashboard/Playground')));

//Doc Driver
const DocDriver = Loadable(lazy(() => import('../section/doc/okydriver/DocDriver')));
const DocTags = Loadable(lazy(() => import('../section/doc/okydriver/views/tags/DriverTags')));
const DocShareWithMe = Loadable(lazy(() => import('../section/doc/okydriver/views/share/DriverShare')));
const DocShareByMe = Loadable(lazy(() => import('../section/doc/okydriver/views/share/DriverMySharedView')));
const DocTrash = Loadable(lazy(() => import('../section/doc/okydriver/views/trash/DriverTrash')));

//AUTH REQUEST
const DocAuthRequest = Loadable(
  lazy(() => import('../section/doc/okydriver/views/permissions/DriverAutorizationRequest'))
);

const DocFolders = Loadable(lazy(() => import('../section/doc/okydriver/views/index')));
const FloderView = Loadable(lazy(() => import('../section/doc/okydriver/views/folder/DriverFolder')));

//Affectation
const AffectationPdf = Loadable(lazy(() => import('../pages/dashboard/AffectationPdf')));
const InvitationPage = Loadable(lazy(() => import('../pages/invitation/InvitationPage')));
const AudienceAppointmentPage = Loadable(lazy(() => import('../pages/dashboard/AccueilAudienceSetAppointment')));

const Billings = Loadable(lazy(() => import('../pages/dashboard/Billings')));
const TestPage = Loadable(lazy(() => import('../pages/TestPage')));

const Mail = Loadable(lazy(() => import('../pages/Mail_V2')));
const MailRefresh = Loadable(lazy(() => import('../pages/MailRefresh')));
const Affectation_v4 = Loadable(lazy(() => import('../pages/dashboard/affectation/Affectation_v4')));

//EDIT AND VIEW DOCUMENT
const DocumentEditing = Loadable(lazy(() => import('../pages/DocumentEditing')));

//MONITORING
const Monitoring = Loadable(lazy(() => import('../pages/dashboard/Monitoring')));
//#endregion
