import { findIndex } from 'lodash';
import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import { Icon } from '@iconify/react';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { downloadFile } from 'src/helpers/downloadFile';
import useAuth from 'src/hooks/useAuth';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { getFileFormat, getFileThumbUrl } from 'src/utils/getFileFormat';
import ExcelFileEditor from './ExcelFileEditor';
import MenuPopover from './MenuPopover';
import PdfFileEditor from './PdfFileEditor';
import WordFileEditor from './WordFileEditor';
import LightboxModal from './LightboxModal';
import CustomUploadFile from './CustomUploadFile';
import { baseColors } from 'src/constants/color';
import FilesReader from 'src/components/FilesReader';
import DocumentReader from './only-office/DocumentReader';
import { format } from 'date-fns';
import { gDate } from 'src/utils/formatTime';
import { useDriverContext } from 'src/section/doc/okydriver/context/DriverContext';
import VideoAndAudioPlayerModal from 'src/components/VideoAndAudioPlayerModal';


/**
 * Renders a custom file view component that displays a list of attachments with various functionality.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.attachments - The list of attachments to display.
 * @param {Function} props.onRemove - A function to remove an attachment.
 * @param {boolean} props.isUploading - Indicates if a file is currently being uploaded.
 * @param {Function} props.onUpdateFile - A function to update a file.
 * @param {string} [props.placeholder] - The placeholder text for the file upload area.
 * @param {boolean} [props.disableUpload] - Disables the file upload functionality.
 * @param {boolean} [props.hideDelete] - Hides the delete button for attachments.
 * @param {Function} [props.onChange] - A function to be called when the file selection changes.
 * @param {boolean} [props.disabled] - Disables the entire component.
 * @param {boolean} [props.hideAdd] - Hides the add file button.
 * @param {boolean} [props.canDeleteBlob] - Allows deleting blob attachments.
 * @param {boolean} [props.defaultOpenLightbox] - Opens the lightbox modal by default.
 * @param {boolean} [props.editable] - Enables editing functionality for attachments.
 * @param {boolean} [props.disabledWrap] - Disables the wrapping of attachments.
 * @param {number} [props.wrapLength] - The number of attachments to display before showing a "See more" button.
 */
const CustomFileView = ({
  attachments,
  onRemove,
  isUploading,
  onUpdateFile,
  placeholder = 'Télécharger vos fichiers et documents ici',
  disableUpload = false,
  hideDelete = false,
  onChange,
  disabled = false,
  hideAdd = false,
  canDeleteBlob = false,
  defaultOpenLightbox = false,
  editable = false,
  disabledWrap = false,
  wrapLength = 2
}) => {
  const { user } = useAuth();
  const [openLightbox, setOpenLightbox] = useState(defaultOpenLightbox);
  const [selectedImage, setSelectedImage] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [hasOpenedLightbox, setHasOpenedLightbox] = useState(false);
  const popoverRef = useRef();

  const imagesLightbox = useMemo(() => attachments?.map((att) => att.url), [attachments]);
  // console.log('openLightbox', openLightbox, 'defaultOpenLightbox', defaultOpenLightbox);

  const handleOpenLightbox = useCallback(
    (url) => {
      // console.log('handleOpenLightbox', url);

      const selectedImage = findIndex(imagesLightbox, (index) => index === url);
      setOpenLightbox(true);
      setSelectedImage(selectedImage);
    },
    [imagesLightbox]
  );

  useEffect(() => {
    if (defaultOpenLightbox && !hasOpenedLightbox) {
      handleOpenLightbox(imagesLightbox[0]);
      setHasOpenedLightbox(true); // Marque le lightbox comme ouvert
    }
  }, [defaultOpenLightbox, handleOpenLightbox, hasOpenedLightbox, imagesLightbox]);

  const handleCloseLightbox = () => {
    setOpenLightbox(false);
  };

  const handleOpenPopover = () => {
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <Stack direction="row" spacing={2} width={1}>
      {(!disabledWrap ? attachments?.slice(0, wrapLength) : attachments)?.map((_att, idx) => {
        const isBlob = _att?.url?.includes('blob:');
        return (
          <AttachmentItem
            key={_att?.url + idx}
            attachment={_att}
            user={user}
            isBlob={isBlob}
            hideDelete={isBlob ? !canDeleteBlob : hideDelete}
            onRemove={onRemove}
            onUpdateFile={onUpdateFile}
            handleOpenLightbox={handleOpenLightbox}
            editable={editable}
          />
        );
      })}

      {attachments?.length > wrapLength && !disabledWrap && (
        <Tooltip title="Voir plus" placement="bottom">
          <Button ref={popoverRef} onClick={handleOpenPopover} sx={{ bgcolor: 'background.paper', m: 0.5, minWidth: 90 }}>
            <Typography variant="body1" sx={{ color: 'text.primary' }}>
              +{attachments?.length - wrapLength}
            </Typography>
          </Button>
        </Tooltip>
      )}
      {popoverOpen && (
        <MenuPopover
          open={popoverOpen}
          onClose={handleClosePopover}
          anchorEl={popoverRef.current}
          sx={{ zIndex: 9999, width: 300, overflowY: 'auto', overflowX: 'hidden' }}
          arrow="top"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Stack direction="row" spacing={1} width={300} flexWrap={'wrap'} py={1}>
            {attachments?.map((_att, idx) => {
              const isBlob = _att?.url?.includes('blob:');
              return (
                <AttachmentItem
                  key={_att?.url + idx}
                  attachment={_att}
                  user={user}
                  isBlob={isBlob}
                  hideDelete={isBlob ? !canDeleteBlob : hideDelete}
                  onRemove={onRemove}
                  onUpdateFile={onUpdateFile}
                  handleOpenLightbox={handleOpenLightbox}
                  editable={editable}
                />
              );
            })}
          </Stack>
        </MenuPopover>
      )}
      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={handleCloseLightbox}
      />
    </Stack>
  );
};

export default CustomFileView;



/**
 *
 * @param {{
 *  user: Any,
 *  idx: number,
 *  attachment: Any,
 *  onRemove : Function,
 *  hideDelete: Function,
 *  onUpdateFile: Function,
 *  imagesLightbox: Function,
 *  handleOpenLightbox: Function,
 * isBlob: Boolean,
 * editable : Boolean,
 * }} props
 */
const AttachmentItem = (props) => {
  const { idx, user, onRemove, attachment, hideDelete, onUpdateFile, handleOpenLightbox, editable, isBlob } = props;
  const { loadingEdting, editingDoc } = useDriverContext();
  //console.log(attachment.url);

  const url = isBlob ? URL.createObjectURL(new Blob([attachment.url])) : attachment.url;

  //console.log('AttachmentItem', isBlob, url);

  const documentViewItem = useMemo(
    () => ({
      key: attachment.id,
      url: url,
      title: attachment.name,
      fileType: attachment.type,
      owner: attachment?.displayName,
      uploaded: format(gDate(attachment?.createAt || attachment?.lastModifiedDate || new Date()), 'yyyy-MM-dd h:mm aa')
    }),
    [attachment?.createAt, attachment?.displayName, attachment.id, attachment?.lastModifiedDate, attachment.name, attachment.type, url]
  );

  //console.log({hideDelete});

  const isNotDocument = useMemo(() => ['video', 'pdf', 'audio'].some((type) => attachment?.type?.includes(type)), [attachment?.type]);

  const deleteRef = useRef();
  const { open, handleOpen, handleClose } = useToggle();
  const [opDelete, handleOpenDelete, handleCloseDelete] = useToggleV2();
  const [hover, onHover, endHover] = useToggleV2();
  const [visible, show, hide] = useToggleV2();

  const handleDownload = () => {
    downloadFile(attachment.url, attachment.name);
  };

  const openVideoAndAudioReader = () => {
    show();
  };

  const handleOnRemove = () => {
    // console.log('handleOnRemove', attachment);
    onRemove(attachment);
    handleCloseDelete();
  };

  const handleUpdate = (newFile) => {
    onUpdateFile(attachment, {
      ...attachment,
      url: newFile.url,
      latestUpdatedBy: { name: user?.displayName, id: user.id, email: user.email, avatar: user.photoURL }
    });
  };

  return (
    <>
      <Tooltip title={attachment?.name || ''}>
        <Box
          ref={deleteRef}
          key={attachment.url}
          sx={{ position: 'relative' }}
          onMouseEnter={onHover}
          onMouseLeave={endHover}
        >
          {attachment?.type?.includes('image') ? (
            <Box
              component="img"
              src={attachment.url}
              onClick={() => handleOpenLightbox(attachment.url)}
              sx={{
                m: 0.5,
                width: 80,
                height: 80,
                minWidth: 80,
                objectFit: 'cover',
                cursor: 'pointer',
                borderRadius: 1,
                bgcolor: alpha(baseColors.BLACK, 0.05)
              }}
            />
          ) : (
            <Tooltip title={attachment.name} placement="bottom">
              <Box
                onClick={
                  attachment?.type?.includes('video') || attachment?.type?.includes('audio')
                    ? openVideoAndAudioReader
                    : ['image', 'video', 'pdf'].some((type) => attachment?.type?.includes(type))
                      ? handleOpen
                      : null
                }
                component="img"
                src={getFileThumbUrl(attachment?.type, attachment.url)}
                width={80}
                height={80}
                minWidth={80}
                sx={{ m: 0.5 }}
              />
            </Tooltip>
          )}
          {hover ? (
            <Stack
              width={1}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bgcolor: (theme) => (theme.palette.mode === 'light' ? 'white' : theme.palette.action.selected),
                borderRadius: 1,
                border: (t) => `1px solid ${t.palette.divider}`
              }}
              justifyContent="space-between"
              direction="row"
            >
              <Tooltip title="Ouvrir">
                <IconButton
                  size="small"
                  color="default"
                  disabled={(isBlob && !isNotDocument)}
                  onClick={() =>
                    attachment?.type?.includes('image')
                      ? handleOpenLightbox(attachment.url)
                      : attachment.type.includes('video') || attachment.type.includes('audio')
                        ? openVideoAndAudioReader()
                        : handleOpen()
                  }
                >
                  <Icon icon="eva:eye-fill" width={15} height={15} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Télécharger">
                <IconButton size="small" color="default" onClick={handleDownload}>
                  <Icon icon="eva:download-fill" width={15} height={15} />
                </IconButton>
              </Tooltip>
              {
                <Tooltip title="Supprimer">
                  <IconButton size="small" color="error" onClick={handleOpenDelete} disabled={hideDelete}>
                    <Icon icon="eva:trash-fill" width={15} height={15} />
                  </IconButton>
                </Tooltip>
              }
            </Stack>
          ) : null}
        </Box>
      </Tooltip>

      {opDelete && (
        <MenuPopover open={opDelete} onClose={handleCloseDelete} anchorEl={deleteRef.current} disabledArrow sx={{ zinIndex: 9999 }}>
          <Box sx={{ p: 1 }}>
            <Typography> Êtes-vous sûr de vouloir supprimer ce fichier ? </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Button color="inherit" onClick={handleCloseDelete}>
                Annuler
              </Button>
              <Button color="error" onClick={handleOnRemove}>
                {' '}
                Supprimer
              </Button>
            </Stack>
          </Box>
        </MenuPopover>
      )}
      {visible &&
        <VideoAndAudioPlayerModal
          url={attachment.url}
          type={attachment.type}
          fileType={attachment.type}
          name={attachment.name}
          open={visible}
          onClose={hide}
        />
      }
      <DocumentReader
        onClose={handleClose}
        open={Boolean(open)}
        document={documentViewItem}
        uploadName={attachment?.name}
        docKey={editingDoc?.docKey || attachment.id}
        openMode={'view'}
        loading={loadingEdting}
        editable={isBlob ? false : editable}
      />
    </>
  );
};
