class Logger {
  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production';
  }

  log(data) {
    if (this.isProduction) {
      return;
    }
  // console.log(data);
  }

  error(data) {
    if (this.isProduction) {
      return;
    }
    console.error(data);
  }
}

const logger = new Logger();

export default logger;
