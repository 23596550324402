// material
import { styled } from '@mui/material/styles';
import {
  Card,
  // Alert,
  Container,
  Stack,
  Typography
} from '@mui/material';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import Logo from 'src/components/Logo';
import { APP_INFO } from 'src/config';

// ----------------------------------------------------------------------

export const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 250,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

export const ContentStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '90vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 3),
  marginBottom: theme.spacing(5)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login">
      <AuthLayout></AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle></SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack mb={7} width={1} justifyContent="center" alignItems="center">
            <Logo
              sx={{ width: 210, aspectRatio: 3 / 2, objectFit: 'cover', mixBlendMode: 'color-burn' }}
              defaultSrc="https://firebasestorage.googleapis.com/v0/b/okydook-e2a3b.appspot.com/o/0000-logo%2Flogo-management.png?alt=media&token=eea90ca5-efff-4e16-9598-3d66934c8104"
            />
          </Stack>
          <Stack mb={10} alignItems="center">
            <Typography variant="h6">Bienvenue sur votre Plateforme Collaborative</Typography>
            <Typography variant="caption">Veuillez vous connecter</Typography>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
