import { Icon } from '@iconify/react';
import { Stack } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import BasicTabs from 'src/components/BasicTabs';
import Page from 'src/components/Page';
import useTaskProject from 'src/hooks/useTaskProject';
import V4ProjectCotentKanban from './kanban/V4ProjectCotentKanban';
import V4ProjectContentLIst from './list/V4ProjectContentLIst';
import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'src/redux/store';
import { getTaskById, persistCard, persistColumn } from 'src/redux/slices/kanban';
import { DragDropContext } from 'react-beautiful-dnd';
import V4ProjectWeekCalendar from './week_calendar/V4ProjectWeekCalendar';
import useAuth from 'src/hooks/useAuth';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import useLocalStorage from 'src/hooks/useLocalStorage';
import V4taskFilters from './V4taskFilters';
import { V4_DATE_STATE, V4_TASK_STATE, V4_ONLY_MY_TASK } from 'src/constants/task';
import { gDate } from 'src/utils/formatTime';
import { nextWeekDateFilter, thisWeekDateFilter, todayDateFilter } from 'src/constants/dateFilters';
import V4ProjectFileContent from './file/V4ProjectFileContent';
import { TaskType } from 'src/models/task_m';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { useTaskV4Context } from 'src/contexts/TaskContext';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useOneProject } from './../../../../../hooks/useProject';
import { useSnackbar } from 'notistack';
import { TASK_VISIBILITY } from 'src/constants';

const ICON_SIZE = 15;

const V4ProjectContentProvider = createContext({
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
    /** @type {TaskType[]} */
    cardsOnArray: [],
    columnsOnArray: []
  },
  loading: false,
  projectId: null,
  taskFilters: {
    state: V4_TASK_STATE.ALL,
    date: null,
    onlyMytask: false
  },
  rigths: { delete: false, edit: false, create: false },
  taskRights: { delete: false, edit: false, create: false },
  handleChangeFilter: (field, value) => { }
});

export const useV4ProjectContext = () => useContext(V4ProjectContentProvider);

/**
 * Determines whether the given project is an old version based on the project and user information.
 *
 * @param {Object} project - The project object.
 * @param {Object} user - The user object.
 * @returns {boolean|null} `true` if the project is an old version, `false` if it's not, or `null` if the project information is incomplete.
 */
const useIsOldProjectVersion = (project, user) => {
  const isOldProjectVersion = useMemo(() => {
    if (!project?.canAccess?.length && !project?.managers?.length) return null;
    else if (project?.canAccess?.length === 1 && project?.canAccess[0]?.id === project?.createBy?.id) {
      return false;
    }
    else if (project?.canAccess?.length) {
      return !project?.canAccess?.some((_user) => _user?.rights || _user?.taskRights);
    }
    else if (project?.managers?.length) {
      return !project?.managers?.some((_user) => _user?.rights || _user?.taskRights);
    }
    return false;
  }, [project]);

  return isOldProjectVersion;
};

/**
 * Determines the project rights for a given user based on the project and user information.
 *
 * @param {Object} project - The project object.
 * @param {Object} user - The user object.
 * @returns {Object} An object containing the user's project rights, including `delete`, `edit`, and `create`.
 */
export const useGetProjectRight = (project, user) => {
  const isOldProjectVersion = useIsOldProjectVersion(project, user);
  //console.log({ isOldProjectVersion });

  const rigths = useMemo(() => {
    if (project?.createBy?.id === user.uid) return { delete: true, edit: true, create: true };
    if (project?.canAccessId?.includes(user.uid)) {
      return (
        project?.canAccess?.find((_user) => _user?.id === user.uid)?.rights ||
        project?.managers?.find((_user) => _user?.id === user.uid)?.rights
      );
    }
    return { delete: false, edit: false, create: true };
  }, [project, user.uid]);

  if (isOldProjectVersion === true) {
    return { delete: true, edit: true, create: true };
  }

  return rigths;
};

/**
 * Determines the task rights for a given user based on the project and user information.
 *
 * @param {Object} project - The project object.
 * @param {Object} user - The user object.
 * @returns {Object} An object containing the user's task rights, including `delete`, `edit`, and `create`.
 */
export const useGetProjectRightForTask = (project, user) => {
  const isOldProjectVersion = useIsOldProjectVersion(project, user);

  const taskRights = useMemo(() => {
    if (project?.createBy?.id === user.uid) return { delete: true, edit: true, create: true };
    // console.log('etape 1');
    if (isOldProjectVersion === true) {
      return { delete: false, edit: true, create: true };
    }
    // console.log('etape 2');
    if (project?.canAccessId?.includes(user.uid)) {
      if (project?.managers?.find((_user) => _user?.id === user.uid)?.rights)
        return { delete: true, edit: true, create: true };
      // console.log('etape 3');
      return (
        project?.canAccess?.find((_user) => _user?.id === user.uid)?.taskRights || {
          delete: false,
          edit: false,
          create: true
        }
      );
    }
    // console.log('etape 4');

    return { delete: false, edit: true, create: true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, user?.uid]);

  return taskRights;
};

/**
 * Retrieves the task rights for a given project and user.
 *
 * @param {string} projectId - The ID of the project.
 * @returns {Object} An object containing the task rights, including `delete`, `edit`, and `create`.
 */
export const useTaskOpenWithRight = (projectId) => {
  const { project, loading } = useOneProject(projectId);

  const { user } = useAuth();
  const taskRights = useGetProjectRightForTask(project, user);
  return {
    taskRights
  }

}

export default function V4ProjectContent() {
  const { projectId, taskId } = useParams();
  const { board, loading } = useTaskProject({ projectId: projectId });
  const { currentProject: project } = useProjectContext();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [defaultPanel, setDefaultPanel] = useLocalStorage('default_project_panel', 0);

  const [taskFilters, setTaskFilters] = useState({
    state: V4_TASK_STATE.ALL,
    date: V4_DATE_STATE.ALL,
    onlyMytask: V4_ONLY_MY_TASK.ALL
  });
  const { enqueueSnackbar } = useSnackbar();


  const { openV4TaskById } = useTaskV4Context();
  const navigate = useNavigate();

  const rigths = useGetProjectRight(project, user);
  const taskRights = { create: false, edit: false, delete: false, ...useGetProjectRightForTask(project, user) };

  const handleChangeFilter = (field, value) => {
    setTaskFilters((old) => ({ ...old, [field]: value }));
  };

  const boardFilter = useMemo(() => {
    let cloneBoard = { ...board };
    let cloneCard = Object.entries(cloneBoard.cards).map(([cardId, card]) => ({ id: cardId, ...card }));
    if (taskFilters.onlyMytask) {
      if (taskFilters.onlyMytask === V4_ONLY_MY_TASK.CREATED || taskFilters.onlyMytask === V4_ONLY_MY_TASK.ASSIGNEE) {
        cloneCard = cloneCard.filter((card) => {
          if (taskFilters.onlyMytask === V4_ONLY_MY_TASK.CREATED) return card?.createdBy?.id === user.uid;
          if (taskFilters.onlyMytask === V4_ONLY_MY_TASK.ASSIGNEE)
            return card?.assignee?.find((_user) => _user?.id === user.uid);
        });
      }
    }
    //#region STATE
    if (taskFilters.state === V4_TASK_STATE.END) {
      cloneCard = cloneCard.filter((card) => card?.completed);
    }
    if (taskFilters.state === V4_TASK_STATE.NOT_END) {
      cloneCard = cloneCard.filter((card) => !card?.completed);
    }
    //#endregion

    //#region DATE
    if (taskFilters.date) {
      try {
        if (taskFilters.date === V4_DATE_STATE.ALL) {
          // cloneCard = cloneCard;
        }
        if (taskFilters.date === V4_DATE_STATE.LATE) {
          cloneCard = cloneCard.filter((card) => gDate(card?.due?.at(1)) < new Date());
        }
        if (taskFilters.date === V4_DATE_STATE.TODAY) {
          cloneCard = cloneCard.filter((card) => todayDateFilter(card?.due?.at(1)));
        }
        if (taskFilters.date === V4_DATE_STATE.THIS_WEEK) {
          cloneCard = cloneCard.filter((card) => thisWeekDateFilter(card?.due?.at(1)));
        }
        if (taskFilters.date === V4_DATE_STATE.NEXT_WEEK) {
          cloneCard = cloneCard.filter((card) => nextWeekDateFilter(card?.due?.at(0), card?.due?.at(1)));
        }
      } catch (error) {
        console.error(error);
      }
    }
    //#endregion

    let returnCards = {};
    cloneCard.forEach((card) => {
      returnCards[card?.id] = card;
    });

    return { ...cloneBoard, cards: returnCards };
  }, [board, taskFilters.onlyMytask, taskFilters.state, taskFilters.date, user.uid]);

  /**
   * Handles the logic for dragging and dropping tasks within a project board.
   *
   * @param {Object} result - The result object from the drag and drop operation.
   * @param {Object} result.destination - The destination of the dragged item.
   * @param {Object} result.source - The source of the dragged item.
   * @param {string} result.draggableId - The ID of the dragged item.
   * @param {string} result.type - The type of the dragged item (e.g. 'column', 'task-calendar').
   * @returns {void}
   */
  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (type === 'column') {
      const newColumnOrder = Array.from(board.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);
      dispatch(persistColumn(newColumnOrder, board.columnOrderId));

      return;
    }

    if (type === 'task-calendar') {
      const start = board.cards[draggableId];
      const [jour, mois, annee] = destination?.droppableId.split('/');
      const endDate = new Date(annee, mois - 1, jour);
      const newDue = [start?.due?.at(0), endDate];
      dispatch(updateTaskWithoutProject(start, { due: newDue }));
      return;
    }

    const start = board.columns[source.droppableId];
    const finish = board.columns[destination.droppableId];

    if (start.id === finish.id) {
      const updatedCardIds = [...start.cardIds];
      updatedCardIds.splice(source.index, 1);
      updatedCardIds.splice(destination.index, 0, draggableId);

      const updatedColumn = {
        ...start,
        cardIds: updatedCardIds
      };

      dispatch(
        persistCard(
          {
            columns: { ...board.columns, [updatedColumn.id]: updatedColumn }
          },
          { id: start.id, cardIds: updatedCardIds },
          { id: finish.id, cardIds: updatedCardIds }
        )
      );

      return;
    }

    const startCardIds = [...start.cardIds];

    startCardIds.splice(source.index, 1);

    const updatedStart = {
      ...start,
      cardIds: startCardIds
    };

    const finishCardIds = [...finish.cardIds];

    finishCardIds.splice(destination.index, 0, draggableId);

    const updatedFinish = {
      ...finish,
      cardIds: finishCardIds
    };

    const card = board.cards[draggableId];

    //TODO use real rights
    dispatch(
      persistCard(
        {
          columns: { ...board.columns, [updatedStart.id]: updatedStart, [updatedFinish.id]: updatedFinish }
        },
        { id: start.id, name: start.name, card, project, cardIds: startCardIds },
        { id: finish.id, name: finish.name, card, project, cardIds: finishCardIds }
      )
    );
  };

  useEffect(() => {
    if (taskId) {
      dispatch(
        getTaskById({
          taskId,
          /**
           * Handles the logic for opening a task based on the user's permissions and the task's visibility.
           *
           * @param {TaskType} task - The task object.
           */
          resolve: async (task) => {
            const { canAccessId } = task;
            // console.log({ task });
            if (canAccessId?.includes(user.uid) || task.visibility === TASK_VISIBILITY.PUBLIC) {
              navigate(`${PATH_DASHBOARD.general.tasks}/${projectId}`);
              openV4TaskById(taskId, taskRights?.create, taskRights?.edit, taskRights?.delete);
            }
            else if (task.visibility === TASK_VISIBILITY.PRIVATE) {
              enqueueSnackbar("Cette tâche est privée et vous n'avez pas les droits pour y accéder", {
                variant: 'warning'
              });
              navigate(PATH_DASHBOARD.general.tasks);
            }
            else {
              enqueueSnackbar("Vous n'avez pas les droits pour accéder à cette tâche", {
                variant: 'warning'
              });
              navigate(PATH_DASHBOARD.general.tasks);
            }
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, projectId]);

  const store = {
    board: boardFilter,
    taskFilters,
    handleChangeFilter,
    loading,
    projectId,
    rigths,
    taskRights
  };
  return (
    <Page title="Tâches & Projet">
      <V4ProjectContentProvider.Provider value={store}>
        <DragDropContext onDragEnd={onDragEnd}>
          <BasicTabs
            isCard={false}
            defaultPanel={defaultPanel}
            currentTab={defaultPanel}
            onChange={setDefaultPanel}
            headers={[
              {
                name: 'Liste',
                icon: (
                  <Icon icon="fluent:document-bullet-list-multiple-20-regular" width={ICON_SIZE} height={ICON_SIZE} />
                )
              },
              { name: 'Tableau Kanban', icon: <Icon icon="bi:kanban" width={ICON_SIZE} height={ICON_SIZE} /> },
              {
                name: 'Calendrier',
                icon: (
                  <Icon icon="material-symbols:calendar-month-outline-rounded" width={ICON_SIZE} height={ICON_SIZE} />
                )
              },
              {
                name: 'Fichiers',
                icon: <Icon icon="mdi-light:file" width={ICON_SIZE} height={ICON_SIZE} />
              }
            ]}
            beforPanel={(panel) =>
              panel !== 3 && (
                <Stack width={1} py={1} px={5} alignItems="flex-end">
                  <V4taskFilters />
                </Stack>
              )
            }
            panels={[
              {
                padding: 0,
                element: (
                  <Stack px={2}>
                    <V4ProjectContentLIst projectId={projectId} />
                  </Stack>
                )
              },
              {
                padding: 0,
                element: (
                  <Stack>
                    <V4ProjectCotentKanban />
                  </Stack>
                )
              },
              {
                padding: 0,
                element: (
                  <Stack>
                    <V4ProjectWeekCalendar user={user} projectId={projectId} />
                  </Stack>
                )
              },
              {
                padding: 0,
                element: (
                  <Stack>
                    <V4ProjectFileContent user={user} projectId={projectId} />
                  </Stack>
                )
              }
            ]}
          />
        </DragDropContext>
      </V4ProjectContentProvider.Provider>
    </Page>
  );
}
