import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MAIL_FOLDER, useMailContext } from 'src/section/mail/MailContext';

import { useToggleV2 } from 'src/hooks/useToggle';
import { useSnackbar } from 'notistack';

import { isEmpty, sum } from 'lodash';

import {
  Button as MButton,
  Stack,
  Typography,
  IconButton,
  Divider,
  InputBase,
  MenuItem,
  Box,
  Tooltip,
  Grid,
  Card,
  Autocomplete,
  Chip,
  TextField,
  alpha,
  Dialog,
  DialogContent
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Icon } from '@iconify/react';

import { Close, ArrowDropDown } from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import { MenuPopoverCenter } from 'src/components/MenuPopover';
import useFilePicker from 'src/hooks/useFilePicker';
import { getFileThumbUrl } from 'src/utils/getFileFormat';
import { humanFileSize } from 'src/utils/formatNumber';

import * as yup from 'yup';
import QuillEditor from 'src/components/editor/quill/index';
import CIconButton from 'src/components/CIconButton';
import { Avatar, Button } from '@mui/joy';
import createAvatar from 'src/utils/createAvatar';
import EllipsisText from 'src/components/EllipsisText';
import { MAIL_SEND_DOC_SIZE_LIMIT } from 'src/helpers/mailTimer';
import { getMailComposerUrl, setMailComposerUrl } from 'src/utils/url';


/**
 * A reusable Autocomplete component that allows for multiple, free-form input values.
 *
 * @param {Object} props - The component props.
 * @param {any[]} props.value - The current value of the Autocomplete.
 * @param {function} props.onChange - A callback function that is called when the Autocomplete value changes.
 * @param {string} props.placeholder - The placeholder text to display in the Autocomplete input.
 * @returns {JSX.Element} - The Autocomplete component.
 */
const AutocompleteItem = ({ value, onChange, placeholder }) => {
  const [autocompleteValue, setAutocompleteValue] = useState(value ? value : []);
  //console.log({ value, autocompleteValue });

  const handleChange = (event, newValue) => {
    setAutocompleteValue(newValue);
    onChange(newValue);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      id="tags-filled"
      options={autocompleteValue}
      value={autocompleteValue}
      autoSelect
      sx={{
        '& .MuiAutocomplete-root': {
          border: 'none',
          outlined: 'none'
        }
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            size="small"
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            sx={{ borderRadius: 1, bgcolor: (t) => alpha(t.palette.divider, t.palette.action.hover) }}
          />
        ));
      }}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} variant="standard" placeholder={placeholder} />}
    />
  );
};

/**
 * A confirmation modal that prompts the user to save a draft of a message before closing.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the modal is open.
 * @param {function} props.onClose - A callback function to close the modal.
 * @param {function} props.onConfirm - A callback function to confirm saving the draft.
 * @param {function} props.onCancel - A callback function to cancel the draft saving.
 * @returns {JSX.Element} - The confirmation modal component.
 */
const ConfirmationModal = ({ open, onClose, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack width={250} spacing={1}>
          <Typography fontSize={13} fontWeight="bold" textAlign="center">
            Souhaitez-vous enregistrer ce message en tant que brouillon ?
          </Typography>

          <Typography fontSize={10} textAlign="center" fontWeight="700">
            Le message n’a pas été envoyé et contient des modifications non enregistrées. Vous pouvez l’enregistrer en
            tant que brouillon pour pouvoir le modifier ultérieurement.
          </Typography>

          <MButton variant="contained" onClick={() => onConfirm(true)} color="primary" size="small">
            Enregistrer
          </MButton>
          <Button variant="soft" onClick={onClose} color="danger" size="sm">
            Ne pas enregistrer
          </Button>
          <Button variant="solid" onClick={onCancel} color="neutral" size="sm">
            Annuler
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};




/**
 * Converts an array of attachments to a format suitable for use in the MailComposer component.
 * 
 * @param {Object[]} attachments - An array of attachment objects.
 * @param {string} attachments[].type - The MIME type of the attachment.
 * @param {string} attachments[].name - The name of the attachment.
 * @param {number} attachments[].lastModified - The last modified timestamp of the attachment.
 * @param {Date} attachments[].lastModifiedDate - The last modified date of the attachment.
 * @param {number} attachments[].size - The size of the attachment in bytes.
 * @returns {File[]} - An array of File objects representing the attachments.
 */
const correctFormatFiles = (attachments) => {
  // console.log({ attachments });
  return attachments.map((el) => {
    const fileBites = new Blob([el], { type: el.type });
    const url = URL.createObjectURL(fileBites);
    setMailComposerUrl({ name: el.name, url });
    // console.log({ url });
    return new File(
      [fileBites],
      el.name,
      {
        lastModified: el.lastModified,
        url: url,
        lastModifiedDate: el.lastModifiedDate,
        name: el.name,
        size: el.size,
        type: el.type,
        webkitRelativePath: url
      }
    );
  });
};

export default function MailComposer({ anchor, open, onClose }) {
  const ref = useRef();

  const [show, handleOpen, handleClose] = useToggleV2();
  const { enqueueSnackbar } = useSnackbar();

  const {
    emails = [],
    sendMail,
    saveToDraft,
    updateDraft,
    folder,
    isNewDraft,
    openDraft,
    deleteDraft,
    mailComposer,
    handleChangeMailComposer,
    clearMailComposer,
    setAccount,
    account,
    readDraft,
    checkIfIsCurrentFolder
  } = useMailContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // console.log({ readDraft, mailComposer });

  const [fileBoxShow, setFileBoxShow] = useState(true);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);

  const [files, setFiles] = useState([...readDraft?.attachments || [], ...correctFormatFiles(mailComposer?.attachments || [])]);
  const [isModalExpanded, setIsModalExpanded] = useState(false);

  useEffect(() => {
    setFiles([...readDraft?.attachments || [], ...correctFormatFiles(mailComposer?.attachments || [])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailComposer.attachments]);

  // console.log({ files, mailComposer });

  const { pickFile, pickImage, fileInputProps, imageInputProps } = useFilePicker({
    onPick: (files) => {
      files.forEach((file) => {
        if (file.size > MAIL_SEND_DOC_SIZE_LIMIT) {
          enqueueSnackbar('La taille de la pièce jointe ne doit pas dépasser 10 Mo.', { variant: 'error' });
          return
        }
        else {
          setFiles((prevState) => [...prevState, file]);
        }
      });
    }
  });

  useEffect(() => {
    if (checkIfIsCurrentFolder(MAIL_FOLDER.DRAFTS) && readDraft) {
      openDraft(readDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder]);

  useEffect(() => {
    if (!mailComposer && emails?.length !== 0) {
      setAccount(emails[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails, mailComposer]);

  const handleChangeAccount = (account) => {
    setAccount(account);
    handleClose();
  };
  const handleSend = async () => {
    if (!account) {
      enqueueSnackbar("Veuillez sélectionner un compte avant d'envoyer l'e-mail.", {
        variant: 'error'
      });
      return;
    }
    setLoading(true);
    const result = [];
    await Promise.all(
      mailComposer?.to?.map(async (el) => {
        const res = await validateTarget(el);
        if (res) {
          result.push(res);
        }
      })
    );

    if (mailComposer?.to?.length === 0) {
      setLoading(false);
      enqueueSnackbar('Aucun destinataire ajouté.', { variant: 'error' });
      return;
    }

    if (result.length === mailComposer?.to?.length) {
      let body = mailComposer?.body;

      if (account?.signature) {
        body += `\n\n ${account?.signature}`;
      }

      sendMail(
        account,
        {
          ...mailComposer,
          body,
          files
        },
        () => {
          onClose();
          enqueueSnackbar('Courriel envoyé', { variant: 'success' });
          clearMailComposer();
        },
        () => {
          setLoading(false);
          enqueueSnackbar("L'envoi de l'e-mail a échoué.", { variant: 'error' });
        }
      );
    } else {
      setLoading(false);
      enqueueSnackbar(`L'un des destinataires n'est pas valide.`, { variant: 'error' });
    }
  };

  const handleRemoveFile = (file) => {
    setFiles((prevState) => [...prevState]?.filter((el) => el?.id !== file?.id));
  };
  const toggleModalSize = () => {
    setIsModalExpanded(!isModalExpanded);
  };

  const handleSaveToDraft = async () => {
    // enregistrer le mail dans les brouillons
    if (mailComposer?.to.length === 0 && mailComposer?.subject?.length === 0 && mailComposer?.body?.length === 0) return;
    if (!account) {
      enqueueSnackbar("Veuillez sélectionner un compte avant d'enregistrer l'e-mail.", {
        variant: 'error'
      });
      return;
    }

    await saveToDraft(
      account,
      {
        ...mailComposer,
        to: mailComposer?.to?.join(','),
        cc: mailComposer?.cc?.join(','),
        cci: mailComposer?.cci?.join(','),
        replyTo: mailComposer?.replyTo?.join(','),
        files
      },
      () => enqueueSnackbar('Email enregistré dans les brouillons', { variant: 'success' }),
      () => enqueueSnackbar("L'enregistrement de l'e-mail a échoué.", { variant: 'error' })
    );
  };

  const isDraftAlreadyExist = () => {
    if (isNewDraft) return false;
  };

  const handleUpdateDraft = async () => {
    // mettre a jour le mail dans les brouillons
    if (mailComposer?.to === '' && mailComposer?.subject === '' && mailComposer?.body === '') return;
    const { id } = readDraft;
    await updateDraft(account, { ...mailComposer, files, id }, () =>
      enqueueSnackbar('Email enregistré dans les brouillons', { variant: 'success' })
    );
  };

  const handleDeleteDraft = async () => {
    // supprimer le mail dans les brouillons
    if (mailComposer?.to === '' && mailComposer?.subject === '' && mailComposer?.body === '') return;
    if (!account) {
      enqueueSnackbar("Veuillez sélectionner un compte avant d'enregistrer l'e-mail.", {
        variant: 'error'
      });
      return;
    }

    await deleteDraft(account, { ...mailComposer, files }, () =>
      enqueueSnackbar('Email enregistré dans les brouillons', { variant: 'success' })
    );
  };

  const handleDraftActionConfirm = () => {
    setNeedsConfirmation(false);
    if (!account) {
      enqueueSnackbar("Veuillez sélectionner un compte avant d'enregistrer l'e-mail.", {
        variant: 'error'
      });
      setLoading(false);
      return;
    }

    if (isNewDraft) {
      handleSaveToDraft()
        .then((res) => {
          // 
        })
        .catch((err) => { })
        .finally(() => {
          onClose();
          clearMailComposer();
          setLoading(false);
        });
    } else {
      handleUpdateDraft()
        .then(() => {
          //
        })
        .catch((err) => { })
        .finally(() => {
          onClose();
          clearMailComposer();
          setLoading(false);
        });
    }
  };

  const handleDraftActionDecline = () => {
    setNeedsConfirmation(false);
    onClose();
    clearMailComposer();
    setLoading(false);
  };

  const handleDraftActionCancel = () => {
    setNeedsConfirmation(false);
    setLoading(false);
  };

  const handleChangeMailComposerArray = (key, value) => {
    handleChangeMailComposer(key, value);
  };

  const handleCloseModal = () => {
    if (mailComposer?.to.length === 0 && mailComposer?.subject === '' && mailComposer?.body === '') {
      onClose();
      clearMailComposer();
      return;
    }
    setLoading(true);

    setNeedsConfirmation(true);
    return;
  };

  if (needsConfirmation) {
    return (
      <ConfirmationModal
        onCancel={handleDraftActionCancel}
        open={needsConfirmation}
        onClose={handleDraftActionDecline}
        onConfirm={handleDraftActionConfirm}
      />
    );
  }

  // add semi transparent background when modal is expanded

  return (
    <>
      <Card
        elevation={5}
        sx={{
          position: 'absolute',
          width: 600,
          minHeight: 534,
          height: 534,
          right: 20,
          bottom: 20,
          ...(isModalExpanded && {
            height: '92vh',
            width: '80vw'
          }),
          transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out',
          alignSelf: 'center',
          zIndex: 9999,
          border: (t) => `1px solid ${t.palette.divider}`,
          overflow: 'auto'
        }}
      >
        <input {...fileInputProps} />
        <input {...imageInputProps} />

        <Stack width={1} height={1} spacing={0}>
          {loading ? (
            <Stack
              width={1}
              height={1}
              position={'absolute'}
              bgcolor={'rgba(255,255,255,0.5)'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ zIndex: 9999 }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <Stack
              bgcolor="#f2f2f2"
              px={2}
              py={1}
              borderRadius={0.5}
              direction="row"
              justifyContent="space-between"
              height="fit-content"
            >
              <Stack direction="row" spacing={1} px={2} py={1}>
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    startIcon={<Iconify icon="mingcute:send-fill" />}
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    loading={loading}
                  >
                    Envoyer
                  </LoadingButton>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton size="small" onClick={pickImage}>
                    <Iconify icon="mdi:image-add" />
                  </IconButton>
                  <IconButton size="small" onClick={pickFile}>
                    <Iconify icon="gg:attachment" />
                  </IconButton>
                </Stack>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  ref={ref}
                  size="sm"
                  variant="plain"
                  color="neutral"
                  sx={{ borderRadius: 'md', fontSize: 12 }}
                  {...(account && {
                    startDecorator: (
                      <Avatar sx={{ bgcolor: createAvatar(account?.email).color2, fontSize: 12 }} size="sm">
                        {createAvatar(account?.email).name}
                      </Avatar>
                    )
                  })}
                  endDecorator={<ArrowDropDown />}
                  onClick={handleOpen}
                >
                  <Stack component={Card} borderRadius={1} p={1}>
                    <EllipsisText text={account?.email || 'Aucun compte'} sx={{ fontSize: 12, maxWidth: 150 }} />
                  </Stack>
                </Button>

                <Stack>
                  <CIconButton size="small" onClick={toggleModalSize} title={isModalExpanded ? 'Réduire' : 'Agrandir'}>
                    {isModalExpanded ? <Icon icon={'akar-icons:reduce'} /> : <Icon icon={'eva:expand-fill'} />}
                  </CIconButton>
                </Stack>

                <Stack>
                  <CIconButton title="Fermer" size="small" onClick={handleCloseModal}>
                    <Close fontSize="small" />
                  </CIconButton>
                </Stack>
              </Stack>
            </Stack>
          )}

          <Stack pt={1} spacing={0.3}>
            <Stack spacing={1} px={2}>
              <AutocompleteItem
                value={mailComposer?.to || []}
                onChange={(value) => handleChangeMailComposerArray('to', value)}
                placeholder="À"
              />

              {error && (
                <Typography variant={'caption'} color={'red'} fontSize={12}>
                  {error}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1} px={2}>
              <AutocompleteItem
                value={mailComposer?.cc || []}
                onChange={(value) => handleChangeMailComposerArray('cc', value)}
                placeholder="Cc"
              />
              {error && (
                <Typography variant={'caption'} color={'red'} fontSize={12}>
                  {error}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1} px={2}>
              <AutocompleteItem
                value={mailComposer?.cci || []}
                onChange={(value) => handleChangeMailComposerArray('cci', value)}
                placeholder="Cci"
              />
              {error && (
                <Typography variant={'caption'} color={'red'} fontSize={12}>
                  {error}
                </Typography>
              )}
            </Stack>
            <Stack px={2}>
              <InputBase
                placeholder="Objet"
                sx={{ fontSize: 14 }}
                fullWidth
                //BUG: different value between mailComposer.subject and event.target.value
                value={mailComposer?.subject}
                onChange={(event) => handleChangeMailComposer('subject', event.target.value)}
              />
            </Stack>
            <Divider light />
          </Stack>

          <Stack width={1} px={2} py={2}>
            <QuillEditor
              simple
              noImage
              noVideo
              id="mail-comment-compo-desc-id"
              placeholder="Ecrire un message"
              value={mailComposer?.body}
              dangerouslySetInnerHTML={{ __html: mailComposer?.body }}
              onChange={(value) => handleChangeMailComposer('body', value)}
              canMention={true}
              setMensions={() => { }}
              sx={{
                border: 'none',
                bgcolor: 'grey.200',
                '& .ql-toolbar.ql-snow': {
                  bgcolor: '#FFF',
                  borderRadius: '10px 10px  0 0'
                },
                '& .ql-editor': {
                  fontSize: 13,
                  minHeight: 270,
                  maxHeight: '50vh',
                  overflow: 'auto'
                },
                '& .ql-picker-options': {
                  zIndex: 9999
                }
              }}
            />
          </Stack>

          <Stack justifyContent={'end'}>
            {!isEmpty(files) && (
              <AttachmentBox
                files={files}
                toggleShow={() => setFileBoxShow((prevState) => !prevState)}
                isShown={fileBoxShow}
                removeFile={handleRemoveFile}
              />
            )}
          </Stack>
        </Stack>

        <MenuPopoverCenter
          open={show}
          onClose={handleClose}
          anchorEl={ref.current}
          disabledArrow
          width={'fit-content'}
          zIndex={9999}
        >
          {emails.map((_compte) => (
            <MenuItem
              component={Typography}
              key={_compte?.email}
              onClick={() => handleChangeAccount(_compte)}
              dense
              whiteSpace
              textOverflow={'ellipsis'}
              noWrap
            >
              <Avatar sx={{ mr: 1, bgcolor: createAvatar(_compte?.email).color2 }} size="sm">
                {createAvatar(_compte?.email).name}
              </Avatar>
              <Typography fontSize={13}>{_compte?.email}</Typography>
            </MenuItem>
          ))}
        </MenuPopoverCenter>
      </Card>
    </>
  );
}

/**
 * A React component that renders an attachment box for a list of files in a mail composer.
 *
 * @param {Object[]} files - An array of file objects, each with properties like `name`, `size`, `type`, and `url`.
 * @param {function} toggleShow - A function to toggle the visibility of the attachment box.
 * @param {boolean} isShown - A boolean indicating whether the attachment box is currently shown.
 * @param {function} removeFile - A function to remove a file from the list of attachments.
 * @returns {JSX.Element} - The rendered attachment box component.
 */
const AttachmentBox = ({ files = [], toggleShow, isShown, removeFile }) => {
  const totalSize = useMemo(() => {
    return sum(files?.map((file) => file?.size || 0));
  }, [files]);

  // console.log(files);

  return (
    <Stack width={1} spacing={1} pb={1}>
      <Stack direction={'row'} spacing={1} px={2}>
        <Typography fontWeight={'bold'} fontSize={12}>
          {humanFileSize(totalSize)}
        </Typography>

        <Typography fontSize={12} sx={{ color: '#706d6b' }}>
          {files.length} {files.length === 1 ? 'fichier joint' : 'fichiers joints'}
        </Typography>

        <Typography fontSize={12} component={'u'} color={'blue'} sx={{ cursor: 'pointer' }} onClick={toggleShow}>
          {isShown ? 'Masquer' : 'Afficher'}
        </Typography>
      </Stack>

      {isShown && (
        <Grid container spacing={1} ml={0.3}>
          {files?.map((el, index) => (
            <Grid item key={index}>
              <FileItem file={el} key={el?.id} onRemove={removeFile} />
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
};

/**
 * A React component that renders a file item in the attachment box of a mail composer.
 *
 * @param {Object} file - The file object, with properties like `name`, `size`, `type`, and `url`.
 * @param {function} onRemove - A function to remove the file from the attachment list.
 * @returns {JSX.Element} - The rendered file item component.
 */
const FileItem = ({ file, onRemove }) => {
  const [hover, onHover, onLeave] = useToggleV2();


  return (
    <Stack
      onMouseLeave={onLeave}
      onMouseEnter={onHover}
      direction={'row'}
      spacing={1}
      width={200}
      height={40}
      px={1}
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: (t) => t.palette.divider,
        borderRadius: 1
        // bgcolor: '#f5f5f5'
      }}
    >
      <Stack width={30} height={30} alignSelf={'center'}>
        <Box
          component="img"
          src={getFileThumbUrl(file?.type, file?.url ? file?.url : getMailComposerUrl(file?.name)?.url)}
          height={30}
          width={30}
        />
      </Stack>

      <Stack width={1} height={1} justifyContent={'center'}>
        <Tooltip title={file?.name}>
          <Typography
            variant={'caption'}
            fontSize={11}
            noWrap
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            width={100}
          >
            {file?.name}
          </Typography>
        </Tooltip>
      </Stack>

      {hover ? (
        <Stack alignSelf={'center'} p={0.3} sx={{ cursor: 'pointer' }} onClick={() => onRemove && onRemove(file)}>
          <Iconify icon={'material-symbols:close'} />
        </Stack>
      ) : (
        <Stack height={1} width={80} justifyContent={'center'}>
          <Typography variant={'caption'} fontSize={9}>
            {humanFileSize(file?.size)}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

/**
 * Validates an email address using the Yup validation library.
 *
 * @param {string} email - The email address to validate.
 * @returns {Promise<string|null>} - The validated email address, or `null` if the validation failed.
 */
const validateTarget = async (email) => {
  const schema = yup.object().shape({
    email: yup.string().email("L'adresse e-mail n'est pas valide.").required("L'adresse e-mail est requise.")
  });

  try {
    await schema.validate({ email });
    // La validation a réussi, l'adresse e-mail est valide.
    return email;
  } catch (error) {
    // La validation a échoué, renvoyer l'erreur.
    return null;
  }
};
