import React from 'react';
import { Button, Stack } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { MAIL_FOLDER, useMailContext } from 'src/section/mail/MailContext';
import { gfDateTime } from 'src/utils/formatTime';

/**
 * A custom button component that renders a button with a start icon and custom styles.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.startIcon='mdi:email-outline'] - The icon to display at the start of the button.
 * @param {function} [props.onClick=null] - The function to call when the button is clicked.
 * @param {React.ReactNode} [props.children=null] - The content to display inside the button.
 * @returns {React.ReactElement} - The custom button component.
 */
const CustomButton = ({ startIcon = 'mdi:email-outline', onClick = null, children = null }) => {
  return (
    <Button
      color={'inherit'}
      size={'small'}
      sx={{ bgcolor: '#ececec', ':hover': { bgcolor: '#d4d4d4' }, px: 2 }}
      startIcon={<Iconify icon={startIcon} sx={{ color: 'dark' }} />}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

/**
 * Converts data to a file object.
 *
 * @param {Object} data - The data to be converted, containing filename, mimetype, and data.
 * @return {File} The file object created from the data.
 */
const transformDataToFileObject = (data) => {
  const byteCharacters = atob(data.data);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const file = new File([byteArray], data.filename, {
    type: data.mimetype,
    lastModified: Date.now(),
  });

  return file;
}


/**
 * A React component that renders an answer box for a mail message, allowing the user to reply, reply all, or forward the message.
 *
 * @param {Object} props - The component props.
 * @param {Object} [props.message=null] - The mail message object.
 * @param {function} [props.onReset=null] - A function to call when the user wants to reset the answer box.
 * @param {boolean} [props.isModal=false] - Whether the answer box is being displayed in a modal.
 * @param {function} [props.onCloseModal=null] - A function to call when the user wants to close the modal.
 * @param {React.RefObject} [props.modalRef=null] - A ref to the modal element.
 * @returns {React.ReactElement} - The MailDetailAnswerBox component.
 */
const MailDetailAnswerBox = ({ message = null, onReset = null, isModal = false, onCloseModal = null, modalRef = null }) => {
  const { handleChangeMailComposerMulti, openComposer, account, setAccount, setIsNewDraft, checkIfIsCurrentFolder } = useMailContext();

  // console.log('message', message);

  const handleMailComposer = async ({ to = [], replyTo = [], cc = [], cci = [], body = '', attachments = [] }) => {
    isModal && onCloseModal();
    setIsNewDraft(true);
    await handleChangeMailComposerMulti({
      to,
      replyTo,
      cc,
      cci,
      subject: `Re: ${message?.subject}`,
      body,
      attachments,
    });
    setAccount(message?.account);
    openComposer();
  };

  // console.log({ message, account });

  const isSentByMe = checkIfIsCurrentFolder(MAIL_FOLDER.SENT)


  const onAnswer = () => {
    const to = isSentByMe ? message?.to?.map((item) => item.email) : [message?.from?.email];
    handleMailComposer({
      to,
    });
  };

  const onAnswerAll = () => {
    const to = isSentByMe ? message?.to?.map((item) => item.email) : [message?.from?.email];
    handleMailComposer({
      to,
      cc: [...(message?.cc ? message?.cc?.map((item) => item?.email) : [])],
      cci: [...(message?.cci ? message?.cci?.map((item) => item?.email) : [])]
    });
  };

  const onTransfer = () => handleMailComposer({
    body: getResponsePart(),
    attachments: message?.hasAttachment ? message?.orginalMail?.attachments?.map((item) => transformDataToFileObject(item)) : []
  });

  const getResponsePart = () => {
    return `
          <br/>
          <br/>
          Le ${gfDateTime(message?.sentDate)}, ${message?.from?.name} < ${message?.from?.email} > a écrit :
          <br/>
            
          ${message?.body}
          
          `;
  };

  return (
    <Stack
      p={2}
      width={1}
      height={'fit-content'}
      bgcolor={'white'}
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderColor: (t) => t.palette.divider
      }}
    >
      <Stack direction={'row'} spacing={2}>

        <CustomButton

          startIcon='mdi:email-outline'
          onClick={onAnswer}
        >
          Répondre
        </CustomButton>
        <CustomButton

          startIcon='mdi:email-edit-outline'
          onClick={onAnswerAll}
        >
          Répondre à tous
        </CustomButton>
        <CustomButton

          startIcon='mdi:email-plus-outline'
          onClick={onTransfer}
        >
          Transférer
        </CustomButton>
      </Stack>
    </Stack>
  );
}

export default MailDetailAnswerBox;