import React, { useEffect, useMemo } from 'react';
import { Stack, Typography, Divider, IconButton, Paper, Grid, Tooltip } from '@mui/material';
import Iconify from 'src/components/Iconify';
import MailDetailAnswerBox from 'src/section/mail_v2/MailDetailAnswerBox';
import Scrollbar from 'src/components/Scrollbar';
import MailDetailAttachment from 'src/section/mail/MailDetailAttachments';
import { useMailContext } from 'src/section/mail/MailContext';
import { formatHTML } from 'src/utils/toHTML';
import { dispatch, useSelector } from 'src/redux/store';
import MailV2MessageDetailSkeleton from './MailV2MessageDetailSkeleton';
import { getMailByUId } from 'src/redux/slices/customMail';
import { gDate } from 'src/utils/formatTime';
import { MAIL_PLATFORM, MiscrosoftMeetingMessageType } from 'src/constants/mail';
import Mail from './models/Mail';
import { formatCorrectlyMailAdresse } from 'src/utils/email';

/**
 * A React component that renders an email address as a clickable link to open the email composer.
 *
 * @param {object} props - The component props.
 * @param {Mail} props.email - The email address to display.
 * @returns {React.ReactElement} - A React element representing the clickable email address.
 */
const OpenableEmail = ({ email, ...props }) => {
  const { openComposer, handleChangeMailComposerMulti, account } = useMailContext();

  const formattedEmail = formatCorrectlyMailAdresse(email);

  const openComposerWithMessage = (email) => {
    handleChangeMailComposerMulti({
      to: [email] || []
    });
    openComposer();
  };

  return (
    <Typography
      variant="caption"
      sx={{
        ml: 0,
        cursor: 'pointer',
        color: 'text.primary',

        '&:hover': {
          textDecoration: 'none',
          color: 'primary.main'
        }
      }}
      {...props}
      onClick={() => openComposerWithMessage(formattedEmail)}
    >
      {`${formattedEmail}`}
    </Typography>
  );
};

/**
 * Renders a component that displays information about a scheduled meeting attached to an email.
 *
 * @param {Mail} mail - The email object containing the meeting information.
 * @param {string} mail.meetingMessageType - The type of the meeting message.
 * @param {object} mail.startDateTime - The start date and time of the meeting.
 * @param {object} mail.endDateTime - The end date and time of the meeting.
 * @param {string} mail.subject - The subject of the email.
 * @returns {React.ReactElement} - The rendered MailProgramedEvent component.
 */
const MailProgramedEvent = ({ mail }) => {
  // console.log({ mail });

  if (!mail?.meetingMessageType || mail?.startDateTime?.dateTime === null || mail?.endDateTime?.dateTime === null) {
    return null;
  }
  return (
    <Stack direction="row" spacing={1} my={2} width={320}>
      <Grid
        container
        flexDirection={'column'}
        spacing={1}
        m={2}
        p={2}
        sx={{ border: '1px solid #e0e0e0', borderRadius: '5px' }}
      >
        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} spacing={1}>
          <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Iconify icon="mdi:schedule" mr={1} />
          </Grid>
          <Grid item>
            <Typography variant="caption" sx={{ color: 'text.primary' }}>
              {mail?.subjet} - {MiscrosoftMeetingMessageType[mail?.meetingMessageType] || mail?.meetingMessageType}
            </Typography>
          </Grid>
          <Tooltip title="Vous avez une réunion planifiée attachée à ce mail " arrow placement="top">
            <IconButton size="small" ml={2}>
              <Iconify icon="mdi:information-outline" sx={{ fontSize: '0.75rem' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} spacing={1}>
          <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Iconify icon="mdi:calendar-clock" mr={1} />
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {gDate(mail?.startDateTime?.dateTime)?.toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}{' '}
              &nbsp;
            </Typography>
            <Typography variant="caption">
              {gDate(mail?.startDateTime?.dateTime)?.toLocaleTimeString('fr-FR', {
                hour: '2-digit',
                minute: '2-digit'
              })}
              -
              {gDate(mail?.endDateTime?.dateTime)?.toLocaleTimeString('fr-FR', {
                hour: '2-digit',
                minute: '2-digit'
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

/**
 * Renders the detailed view of a mail message, including the message content, attachments, and a reply box.
 *
 * @param {Object} message - The mail message object.
 * @param {boolean} [isModal=false] - Whether the component is being rendered in a modal.
 * @param {Function} [onCloseModal=() => {}] - A callback function to be called when the modal is closed.
 * @param {React.RefObject} [modalRef=null] - A ref to the modal element.
 * @param {boolean} [open] - Whether the modal is open.
 * @returns {React.ReactElement} - The rendered MailV2MessageDetail component.
 */
const MailV2MessageDetail = ({ message, isModal = false, onCloseModal = () => { }, modalRef = null, open }) => {
  const { openComposer, handleChangeMailComposerMulti, getFolderId, folder: _folder } = useMailContext();
  const {
    folder: originalFolder,
    id: mailId,

  } = message;

  const folder = originalFolder === undefined ? _folder : originalFolder;
  const { mailByID } = useSelector((state) => state.customMail);

  const mailContent = useMemo(() => {
    if (message?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      if (mailByID[folder] && mailByID[folder][mailId]) {
        return mailByID[folder][mailId];
      }

      return { loading: true, mail: null };
    }
    return { loading: false, mail: message };
  }, [message, mailByID, folder, mailId]);

  const openComposerWithMessage = (message) => {
    handleChangeMailComposerMulti({
      to: [message?.from?.email] || []
    });
    openComposer();
  };

  useEffect(() => {
    if (message?.account?.platform === MAIL_PLATFORM.CUSTOM) {
      const _content = mailByID[folder] && mailByID[folder][mailId];
      const folderId = getFolderId(folder, message?.account) || folder;
      if (!_content?.mail) {
        dispatch(
          getMailByUId({ account: message?.account, folderType: message?.folder, folderId, messageUID: message?.mail_UID })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder, message]);

  return mailContent?.loading ? (
    <MailV2MessageDetailSkeleton />
  ) : (
    <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack
        sx={{ p: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
      >
        <Stack sx={{ p: 0, display: 'flex', justifyContent: 'left', alignItems: 'center', flexDirection: 'row' }}>
          <Stack direction="column" spacing={0}>
            {mailContent?.mail?.from ? (
              <Stack direction="row" idth={'100'} spacing={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Stack
                  width={1}
                  direction={'row'}
                  spacing={1}
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  flexWrap={'wrap'}
                >
                  <OpenableEmail email={mailContent?.mail?.from?.email} fontSize={'1rem'} fontWeight={'bold'} />
                </Stack>
              </Stack>
            ) : null}
            {mailContent?.mail?.to?.length !== 0 ? (
              <Stack direction="row" idth={'100'} spacing={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Typography variant="caption">{`À: `}</Typography>
                <Stack
                  width={1}
                  direction={'row'}
                  spacing={1}
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  flexWrap={'wrap'}
                >
                  {mailContent?.mail?.to?.map((to, idx) => (
                    <OpenableEmail key={to?.email + idx} email={to.email} />
                  ))}
                </Stack>
              </Stack>
            ) : null}

            {mailContent?.mail?.cc?.length !== 0 ? (
              <Stack
                width={1}
                direction={'row'}
                spacing={1}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                flexWrap={'wrap'}
              >
                <Typography variant="caption">{`Cc: `}</Typography>
                {mailContent?.mail?.cc?.map((cc, idx) => (
                  <OpenableEmail key={cc?.email + idx} email={cc?.email} />
                ))}
              </Stack>
            ) : null}
            {
              mailContent?.mail?.cci?.length !== 0 ? (
                <Stack
                  width={1}
                  direction={'row'}
                  spacing={1}
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  flexWrap={'wrap'}
                >
                  <Typography variant="caption">{`Cci: `}</Typography>
                  {mailContent?.mail?.cci?.map((cci, idx) => (
                    <OpenableEmail key={cci?.email + idx} email={cci?.email} />
                  ))}
                </Stack>
              ) : null
            }
            <Typography variant="caption">
              {(!mailContent?.mail?.date && !mailContent?.mail?.sentDate) ||
                isNaN(new Date(mailContent?.mail?.date || mailContent?.mail?.sentDate).getTime())
                ? ''
                : `Le ${new Date(mailContent?.mail?.date || mailContent?.mail?.sentDate).toLocaleString('fr-FR', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })}`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ width: '100%' }} />
      <Stack sx={{ p: 3 }} height={'60vh'} overflow={'auto'} flexGrow={1}>
        <Typography variant="h5">{mailContent?.mail?.subject}</Typography>

        <MailProgramedEvent mail={mailContent?.mail} />

        <Typography
          sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
          fontSize={14}
          dangerouslySetInnerHTML={{ __html: formatHTML(mailContent?.mail?.body || mailContent?.mail?.html) }}
        />

        <MailDetailAttachment mail={mailContent?.mail} />
      </Stack>
      <Stack flexGrow={1} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Scrollbar sx={{ flexGrow: 1 }}>
          <MailDetailAnswerBox
            message={mailContent?.mail}
            isModal={isModal}
            onCloseModal={onCloseModal}
            modalRef={modalRef}
          />
        </Scrollbar>
      </Stack>
    </Paper>
  );
};

export default MailV2MessageDetail;
