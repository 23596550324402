import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
import { isEmpty, omit, reject, uniq, uniqBy } from 'lodash';
import { auth } from '../../contexts/FirebaseContext';
import { NOTIFICATION_TYPES } from '../../constants';
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from 'axios';
import axiosRequest from './../../utils/axiosRequest';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { TASK_STATE_VALIDATION } from 'src/constants';
import { serverTime } from 'src/utils/serverTime';
import { getCurrentUserAccess } from 'src/helpers/user';
import { MAIL_CONFIG, NOTIF_CONFIG } from 'src/config';
import { compareArraysOfObjects } from 'src/utils/changeOnObject';
import { AUTHORIZATION_TYPE_CONVERTER } from 'src/section/doc/okydriver/annexes/helpers';

const { subject, from, domaine } = MAIL_CONFIG;

const slice = createSlice({
  name: 'notifications',
  initialState: {
    isLoading: false,
    error: false,
    tokens: [],
    notifications: []
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    gotSuccess(state) {
      state.isLoading = false;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    deleteNotification(state, action) {
      state.notifications = reject(state.notifications, { id: action.payload });
    },

    setTokens(state, action) {
      state.tokens = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { startLoading, gotSuccess, hasError, getNotificationsSuccess } = slice.actions;

//#region async actions
export const setTokens = (users) => {
  return async (dispatch) => {
    const tokens = users
      .filter((u) => u?.token || u?.mobileToken)
      .map((us) => {
        return { id: us.id, token: us.token, mobileToken: us?.mobileToken };
      });
    dispatch(slice.actions.setTokens(tokens));
  };
};

export const getAllNotification = (list, callback = null) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      dispatch(getNotificationsSuccess(list));
      callback && callback();
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

const sendFirebaseNotification = async (getState, data, canAccessWithoutSender) => {
  const allTokens = getState().notifications.tokens;

  // console.log('url', data?.action?.url);

  const tokensWithoutSender = allTokens.filter((one) => one.id !== auth.currentUser.uid);

  const canReceivNotification = [...canAccessWithoutSender].map((id) => {
    return [...tokensWithoutSender].find((tk) => tk.id === id);
  });

  const tokens = [];
  const mobileToken = [];

  canReceivNotification.forEach((us) => {
    if (us?.token) {
      tokens.push(us.token);
    }
    if (us?.mobileToken) {
      mobileToken.push(us?.mobileToken);
    }
  });

  const emit = async (tokens = [], link = 'many') => {
    if (tokens.length) {
      const desc = {
        createdAt: data?.createdAt,
        type: data?.type,
        cardTitle: data?.cardTitle,
        title: data?.title,
        by: data?.by,
        description: data?.description,
        other: { ...(data?.other || { projectName: data?.action?.projectName }) },
        action: data?.returnId && {
          id: data?.returnId,
          sub: data?.action?.projectKey,
          ...(data?.action?.title && { title: data?.action?.title }),
          ...(data?.action?.messageId && { messageId: data?.action?.messageId }),
          ...(data?.action?.messageTitle && { messageTitle: data?.action?.messageTitle })
        },
        url: data?.action?.url
      };

      await axios.post(`${NOTIF_CONFIG.fb_notif}/${link}`, {
        tokens,
        title: data.title,
        description: data?.description,
        data: desc
      });
    }
  };

  emit(tokens, 'many');
  emit(mobileToken, 'many-mobile');
};

/**
 *
 * @param {{
 * data:{
 *      createdAt: Date,
 *      isUnRead: string[],
 *      canAccessEmail: string[],
 *      canAccess: string[],
 *      description: string,
 *      title: string,
 *      type: string
 *      action: {url: string?, title: string?, id: string?, sub: string?},
 *      by: {
 *          id: string,
 *          displayName: string,
 *          photoURL: string,
 *      },
 * },
 * callback: Function
 * }}
 * @returns {(function(*): Promise<void>)|*}
 */
//TODO : is use to push notification
export const createNotification = ({ data, callback }) => {
// console.log('createNotification', { data });
  return async (dispatch, getState) => {
    dispatch(startLoading());
    try {
      const { canAccess } = data;
      const canAccessWithoutSender = canAccess.filter((id) => id !== auth.currentUser.uid);
      //console.log({ canAccessWithoutSender });

      if (canAccessWithoutSender.length === 0) {
        dispatch(gotSuccess());
        callback && callback();
        return;
      }

      // console.log(data);
      await firestore.collection('notifications').add({
        ...{ ...data, canAccess: canAccessWithoutSender },
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        }
      });

    // console.log({ canAccessWithoutSender });

      sendFirebaseNotification(getState, data, canAccessWithoutSender);

      dispatch(gotSuccess());
      callback && callback();
    } catch (error) {
      //console.error(error);
      dispatch(hasError(error));
    }
  };
};

//create Mail is use to send mail to all users
/**
 * @param {{
 * users: {
 *    id: string,
 *    displayName: string,
 *    email: string,
 * }[],
 * subject: string,
 * description: string,
 * link: string,
 * header: string
 * }}
 * @returns {(function(*): Promise<void>)|*}
 *
 */
export const createMail = ({ users, subject, description, link, header }) => {
  const currentUser = auth.currentUser;
  const currentUserEmail = currentUser?.email;
  const uniqueUsers = uniqBy(users, 'email');
  const uniqueUsersWithoutCurrentUser = uniqueUsers.filter((one) => one?.email !== currentUserEmail);
  return async (dispatch) => {
    uniqueUsersWithoutCurrentUser.map(async (pers) => {
      //console.log('pers', pers?.email , description);
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: link,
        subject: subject,
        header: header
      };

      try {
        await axiosRequest.post('/mail/template', {
          to: pers.email,
          from,
          templateId: MAIL_CONFIG.template.members,
          data
        });
      } catch (error) {
        //console.log('Error while sending mail to ', pers.email, error);
      }
    });
  };
};

export const setNotificationsAsRead = createAsyncThunk(
  'notification/mark_as_read',
  async ({ notificationIds = [] }, { dispatch, getState }) => {
    try {
      const {
        notifications: { notifications: list }
      } = getState();

      const userId = getCurrentUserAccess()?.id;
      const batch = firestore.batch();

      // console.log('notificationIds', notificationIds)

      notificationIds.forEach((id) => {
        const ref = firestore.collection('notifications').doc(id);
        batch.set(ref, { isUnRead: { [userId]: false } }, { mergeFields: [`isUnRead.${userId}`] });
      });

      await batch.commit();
    } catch (error) {
      dispatch(hasError(error));
    }
  }
);
//#endregion

//#region PROJECT
export const ProjectCreateNotification = ({ project }) => {
  return async (dispatch, getState) => {
    const { users } = getState().user;

    // console.log('ProjectCreateNotification', { project });
    let notifTitle = 'Vous avez été ajouté à un projet';
    let notifType = NOTIFICATION_TYPES.PROJECT_MANAGER;

    const { canAccessId, managerIds, managers } = project;

    let listIds = {};
    const canAccessIdManagers = managerIds || [];
    const canAccessIds = uniq([...canAccessId]).filter((id) => !canAccessIdManagers.includes(id));
    const canAccess = canAccessIds.map((oneId) => users.find((_user) => _user?.id === oneId)).filter((one) => one);

    canAccessIds.forEach((id) => (listIds[id] = true));
    const accessLink = `https://${domaine}/dashboard/task/${project?.id || ''}`;

    //console.log({managerIds, canAccessIdManagers});

    dispatch(
      createNotification({
        data: {
          title: 'Vous avez été nommé gestionnaire',
          description: 'Vous avez été ajouté au projet <<' + project?.name + '>> en tant que gestionnaire',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIdManagers,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id,
            url: accessLink
          }
        },
        callback: (e) => {
          //console.log('ProjectCreateNotification callback', { e });
        }
      })
    );

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été ajouté au projet <<' + project?.name + '>>',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id,
            url: accessLink
          }
        },
        callback: (e) => {
          //console.log('ProjectCreateNotification callback', { e });
        }
      })
    );
    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: canAccess,
        subject: subject,
        description: `Vous avez été ajouté au projet << ${project?.name} >> par ${thisUser?.displayName}`,
        link: accessLink,
        header: notifTitle
      })
    );
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `Vous avez été nommé << Gestionnaire >> du projet << ${project?.name} >> par ${thisUser?.displayName}`,
        link: accessLink,
        header: 'Vous avez été nommé gestionnaire'
      })
    );
  };
};

export const ProjectDeadlineExtendNotification = ({ project, deadline }) => {
  return async (dispatch) => {
    //console.log('ProjectDeadlineExtendNotification', { project, deadline });
    let notifTitle = 'La date limite du projet a été prolongée';
    let notifType = NOTIFICATION_TYPES.PROJECT_DEADLINE_EXTEND;
    const { canAccessId, managers, managerIds, canAccess } = project;
    let listIds = {};

    // console.log({ project });

    const canAccessIdManagers = managerIds || [];
    const canAccessIds = uniq([...canAccessId]).filter((id) => !canAccessIdManagers.includes(id));
    const description = `La date limite du projet << ${project?.name} >> a été prolongée au ${format(
      deadline,
      'dd MMMM yyyy',
      { locale: frLocale }
    )}`;
    const link = `https://${domaine}/dashboard/task/${project?.id || ''}`;

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: description,
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          other: {
            projectName: project?.name,
            deadline: format(deadline, 'dd MMMM yyyy', { locale: frLocale })
          },
          action: {
            projectKey: project.id,
            projectName: project?.name,
            url: link
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: canAccess,
        subject: subject,
        description: `La date limite du projet << ${project?.name} >> a été prolongée au ${format(
          deadline,
          'dd MMMM yyyy',
          { locale: frLocale }
        )} par ${thisUser?.displayName}`,
        link: link,
        header: notifTitle
      })
    );
  };
};

export const ProjectDeadlineExtendsPermissionNotification = ({ project, deadline }) => {
  return async (dispatch) => {
    //console.log('ProjectDeadlineExtendsPermissionNotification', { project, deadline });
    let notifTitle = 'Demande de prolongation de date limite';
    let notifType = NOTIFICATION_TYPES.PROJECT_DEADLINE_EXTEND_PERMISSION;
    const { canAccessId, managers, managerIds, canAccess } = project;
    let listIds = {};

    // console.log({ project });

    const canAccessIdManagers = managerIds || [];
    const canAccessIds = uniq([...canAccessId]).filter((id) => !canAccessIdManagers.includes(id));
    const link = `https://${domaine}/dashboard/task/${project?.id || ''}`;

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Une demande de prolongation de la date limite du projet << ${project?.name} >> a été faite`,
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id,
            projectName: project?.name,
            url: link
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `Une demande de prolongation de la date limite du projet << ${project?.name} >> a été faite par ${thisUser?.displayName}`,
        link: link,
        header: notifTitle
      })
    );
  };
};

export const ProjectUpdateNotification = ({ project, oldProject }) => {
  return async (dispatch) => {
  // console.log('ProjectUpdateNotification', { project, oldProject });
        const thisUser = auth.currentUser;

    const { canAccessId, managers, managerIds, canAccess, dataInterval } = project;

    // console.log({ canAccessId, managers, managerIds, canAccess });
    const convertManagerToManagerIds = (managers) => managers?.map((_one) => _one.id) || [];
    
  // console.log({ canAccessId, managers, managerIds, canAccess });

    const _managers = convertManagerToManagerIds(managers);
    const _oldManagers = convertManagerToManagerIds(oldProject?.managers);
    const _canAccess = oldProject?.canAccess || [];
    const _oldCanAccessId = oldProject?.canAccessId || [];

    const hasDatesChanged =
      oldProject?.dataInterval?.start?.seconds !== dataInterval?.start?.seconds ||
      oldProject?.dataInterval?.end?.seconds !== dataInterval?.end?.seconds;

    const newManagers = _managers.filter((_id) => !_oldManagers.includes(_id));
    const newAccessUsers = canAccessId.filter((_id) => !_oldCanAccessId.includes(_id));

    let notifTitle = 'Mise à jour du projet';
    let notifDescription = `Le projet << ${project?.name} >> a été mis à jour par ${thisUser?.displayName}.`;
    let notifType = NOTIFICATION_TYPES.PROJECT_UPDATE;

    if (hasDatesChanged) {
      notifTitle = 'Mise à jour des dates du projet';
      notifDescription = `Les dates du projet << ${project?.name} >> ont été mises à jour par ${thisUser?.displayName}.`;
      notifType = NOTIFICATION_TYPES.PROJECT_UPDATE;
    }

    if (newManagers.length > 0) {
      notifTitle = 'Ajout de nouveaux gestionnaires';
      notifDescription = `Vous avez été ajouté comme gestionnaire au projet << ${project?.name} >> par ${thisUser?.displayName}.`;
      notifType = NOTIFICATION_TYPES.PROJECT_ADD_USER;
    }

    if (newAccessUsers.length > 0) {
      notifTitle = 'Vous avez été ajouté au projet';
      notifDescription = `Vous avez été ajouté au projet << ${project?.name} >> par ${thisUser?.displayName}.`;
      notifType = NOTIFICATION_TYPES.PROJECT_ADD_USER;
    }

    const resAccessIds = uniq([...newAccessUsers, ...newManagers]);

    const resAcess = [];
    resAccessIds.forEach((id) => {
      const user = [...canAccess, ..._canAccess, ...managers, ...oldProject?.managers].find((u) => u.id === id);
      if (user) resAcess.push(user);
    });

    const url = `https://${domaine}/dashboard/task/${project?.id || ''}`;

    let listIds = {};
    resAccessIds.forEach((id) => (listIds[id] = true));

  // console.log({ resAccessIds, listIds });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: notifDescription,
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName: project?.name,
            projectKey: project.id,
            id: project?.id,
            url: url
          }
        }
      })
    );

    dispatch(
      createMail({
        users: resAcess,
        subject: notifTitle,
        description: notifDescription,
        link: url,
        header: notifTitle
      })
    );
  };
};

//#endregion

//#region Task

export const taskCreateNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    //console.log('taskCreateNotification', { card, projectName });
    let notifTitle = 'Une tâche a été créée';
    let notifType = NOTIFICATION_TYPES.TASK_CREATE;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'La tâche ' + card?.name + ' du projet ' + projectName + ' a été créée',
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été créée par ${thisUser?.displayName}`,
        link: url,
        header: notifTitle
      })
    );
  };
};

export const taskDeleteNotification = ({ card, projectName, project }) => {
  return async (dispatch) => {
    //console.log('taskDeleteNotification', { card, projectName });
    let notifTitle = 'Une tâche a été supprimée';
    let notifType = NOTIFICATION_TYPES.TASK_DELETE;
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    const { managers } = project;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'La tâche ' + card?.name + ' du projet ' + projectName + ' a été supprimée',
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été supprimée par ${thisUser?.displayName}`,
        link: url,
        header: notifTitle
      })
    );
  };
};

export const taskRetireNotification = ({ card, assigneChange, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Une tâche vous a été retirée';
    let notifType = NOTIFICATION_TYPES.TASK_RETRIEVE;

    let listIds = {};
    const canAccessIdManagers = assigneChange?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    //console.log({ canAccessIds, canAccessIdManagers, canAccessId: card?.canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été retiré de la tâche ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );
    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: assigneChange,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} vous a été retirée par ${thisUser?.displayName}`,
        link: url,
        header: `Une tâche vous a été retirée`
      })
    );
  };
};
//TODO: use to add notification to all users
export const taskAssigneeNotification = ({ card, assigneChange, projectName }) => {
  return async (dispatch) => {
    //console.log('taskAssigneeNotification', { card, assigneChange, projectName });
    let notifTitle = 'Une tâche vous est attribuée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_ASSIGN_USER;

    const { displayName, uid, photoURL } = auth.currentUser;
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    let listIds = {};
    const canAccessIdManagers = assigneChange?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessIds, canAccessIdManagers, canAccessId: card?.canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description:
            'Vous avez été assigné à la tâche ' + card?.name + `${projectName ? ' du projet ' + projectName : ''}`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: uid,
            displayName: displayName || '',
            name: displayName || '',
            photoURL: photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: assigneChange,
        subject: subject,
        description: `Vous avez été désigné pour la tâche "${card?.name}" dans le cadre du projet ${
          projectName ? projectName : ''
        }, par ${thisUser?.displayName}`,
        link: url,
        header: `Une tâche vous est attribuée`
      })
    );
  };
};

export const taskValidationDemandeNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    //console.log('taskValidationDemandeNotification', { card, projectName });
    let notifTitle = 'Demande de validation';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Demande de validation pour la tâche ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `Vous avez reçu une demande de validation sur la tâche ${card?.name} du projet ${projectName} par ${thisUser?.displayName}`,
        link: url,
        header: `Une demande de validation`
      })
    );
  };
};

export const taskBeginningNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche démarrée';
    let notifType = NOTIFICATION_TYPES.TASK_BEGINING;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'La tâche ' + card?.name + ' du projet ' + projectName + ' a été démarrée',
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été démarrée par ${thisUser?.displayName}`,
        link: url,
        header: notifTitle
      })
    );
  };
};

export const taskValidationRejectNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche rejetée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_REJECT;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Rejet de la tâche: ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...managers], 'email'),
        subject: subject,
        description: `La demande de validation de la tâche ${card?.name} du projet ${projectName} a été rejetée par ${thisUser?.displayName}`,
        link: url,
        header: `Demande de validation rejetée`
      })
    );
  };
};

export const taskValidationDoneNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche validée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_DONE;

    const { canAccessId, assignee, managers, createdBy } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];
    const assigneeIds = assignee?.map((_one) => _one.id) || [];
    const createdByIds = createdBy?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId, ...assigneeIds, ...createdByIds]);
    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    //console.log({ canAccessIds, canAccessIdManager, canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Tâche ${card?.name} du projet ${projectName} a marqué comme terminée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...(managers || [])], 'email'),
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été marqué comme terminée par ${thisUser?.displayName}`,
        link: url,
        header: `Tâche validée`
      })
    );
  };
};

export const taskValidationAcceptNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche validée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];
    const assigneeIds = assignee?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId], 'id');
    canAccessIds.forEach((id) => (listIds[id] = true));

    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    //console.log({ canAccessIds, canAccessIdManager, canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Tâche ${card?.name} du projet ${projectName} a été validée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...(managers || [])], 'email'),
        subject: subject,
        description: `La demande de validation de la tâche ${card?.name} du projet ${projectName} a été acceptée par ${thisUser?.displayName}`,
        link: url,
        header: `Demande de validation acceptée`
      })
    );
  };
};

export const taskDueChangeNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'L’échéance d’une tâche qui vous est attribuée a été modifiée';
    let notifType = NOTIFICATION_TYPES.TASK_DUE_CHANGE;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId]);
    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `L’échéance de la tâche ${card?.name} du projet ${projectName} a été modifiée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...(managers || [])], 'email'),
        subject: subject,
        description: `L’échéance de la tâche ${card?.name} du projet ${projectName} a été modifiée par ${thisUser?.displayName}`,
        link: url,
        header: `L’échéance d’une tâche qui vous est attribuée a été modifiée`
      })
    );
  };
};

export const taskDueAddNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'L’échéance d’une tâche qui vous est attribuée a été ajoutée';
    let notifType = NOTIFICATION_TYPES.TASK_DUE_ADD;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId]);
    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `L’échéance de la tâche << ${card?.name} >> du projet << ${projectName} >> a été ajoutée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...(managers || [])], 'email'),
        subject: subject,
        description: `L’échéance de la tâche << ${card?.name} >> du projet << ${projectName} >> a été ajoutée par ${thisUser?.displayName}`,
        link: url,
        header: `L’échéance d’une tâche qui vous est attribuée a été ajoutée`
      })
    );
  };
};

export const taskattachmentsChangeNotification = ({ card, projectName, change, attachCount }) => {
  return async (dispatch) => {
    //console.log({attachCount});
    const attachementsLength = attachCount || 0;
    const textPluriel = attachementsLength > 1 ? 'de nouvelles pièces jointes' : 'une nouvelle pièce jointe';
    const textPlurielNotif = attachementsLength > 1 ? ' ont été ajoutées' : ' a été ajoutée';
    //console.log('taskattachmentsChangeNotification', { card, projectName });
    let notifTitle = 'Une tâche que vous suivez a ' + textPluriel;
    let notifType =
      attachementsLength > 1 ? NOTIFICATION_TYPES.TASK_PIECE_JOINTES : NOTIFICATION_TYPES.TASK_PIECE_JOINTE;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId]);
    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: textPluriel + textPlurielNotif + ` de la tâche ${card?.name} du projet ${projectName}`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey,
            url: url
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...(assignee || []), ...(managers || [])], 'email'),
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a ${textPluriel} par ${thisUser?.displayName}`,
        link: url,
        header: `Une tâche que vous suivez a ${textPluriel}`
      })
    );
  };
};

export const taskUpdateNotification = createAsyncThunk(
  'notification/task/update',
  async ({ card, change, projectName, theOld = null }, { dispatch, getState }) => {
    const { currentProject } = getState().kanban;

    const taskCard = { ...card, mamangers: [...(card?.mamangers || []), ...(currentProject?.managers || [])] };

    const { canAccessId, mamangers } = card;

    let listIds = {};
    const canAccessIdManagers = mamangers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    if (change.length) {
      const stateChange = change.filter((_one) => _one?.state || false);
      const completedChange = change.filter((_one) => _one?.completed || false);
      const assigneChange = change.filter((_one) => _one?.assignee || false);
      const dueChange = change.filter((_one) => _one?.due || false);
      const attchmentChange = change.filter((_one) => _one?.attachments || false);
      const commentsAdded = change.filter((_one) => _one?.comments || false);

      if (assigneChange?.length) {
        if (theOld) {
          let oldAssinge = [...(theOld?.assignee || [])];
          let newAssigne = assigneChange[0]?.assignee || [];
          const { added, removed } = compareArraysOfObjects(oldAssinge, newAssigne);
          removed.length !== 0 &&
            dispatch(taskRetireNotification({ card: taskCard, assigneChange: removed, projectName }));
          added.length !== 0 &&
            dispatch(taskAssigneeNotification({ card: taskCard, assigneChange: added, projectName }));
        } else {
          //
        }
      }
      if (change?.length) {
        if (completedChange[0]?.completed) {
          dispatch(taskCompletedNotification({ card: taskCard, project: currentProject, projectName }));
          return;
        }

        if (stateChange[0]?.state === TASK_STATE_VALIDATION.REJECTED) {
          dispatch(taskValidationRejectNotification({ card: taskCard, change, projectName }));
          return;
        }

        if (stateChange[0]?.state === TASK_STATE_VALIDATION.ACCEPTED) {
          dispatch(taskValidationDoneNotification({ card: taskCard, projectName }));
          return;
        }
        if (stateChange[0]?.state === TASK_STATE_VALIDATION.INPROGRESS) {
          dispatch(taskBeginningNotification({ card: taskCard, change, projectName }));
          return;
        }
      }
      if (dueChange?.length) {
        if (theOld) {
          let oldDue = theOld?.due;
          if (!oldDue[0] && !oldDue[1]) {
            dispatch(taskDueAddNotification({ card: taskCard, projectName }));
          } else {
            dispatch(taskDueChangeNotification({ card: taskCard, projectName }));
          }
        }
      }
      if (attchmentChange?.length) {
        if (theOld) {
          let rest = attchmentChange[0]?.attachments;
          theOld?.attachments.forEach((_one) => (rest = rest?.filter((_as) => _as?.id !== _one?.id)));

          if (rest.length) {
            dispatch(
              taskattachmentsChangeNotification({
                card: taskCard,
                projectName,
                change: attchmentChange,
                attachCount: rest?.length
              })
            );
          }
        }
      }
    }
  }
);

export const taskMoveNotification = createAsyncThunk(
  'notification/task/move',
  async ({ card, project, oldOperationName, newOperationName }, { dispatch }) => {
    const { canAccessId, mamangers: managers, createdBy } = card;
    const thisUser = auth.currentUser;

    let canAccessIdManagers = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId, createdBy?.id]).filter(
      (_id) => _id && !isEmpty(_id) && _id !== thisUser.uid
    );

    const projectName = project?.name;

    canAccessIds.forEach((id) => (listIds[id] = true));

    const desc = `La tâche << ${card.name} >> a été déplacée par << ${auth.currentUser.displayName} >> depuis l'operation << ${oldOperationName} >> vers l'operation << ${newOperationName} >> du projet << ${project?.name} >>`;
    const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

    dispatch(
      createNotification({
        data: {
          title: `Tâche déplacée`,
          description: desc,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.TASK,
          isUnRead: listIds,
          canAccess: canAccessIds,
          returnId: card?.id,
          cardTitle: card?.name,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName: project?.name,
            projectKey: card?.projectKey || card?.idProject,
            id: card?.id,
            url: url
          }
        }
      })
    );
    dispatch(
      createMail({
        users: canAccessIds,
        subject: subject,
        description: desc,
        link: url,
        header: `La tâche "${card?.name}" a été déplacée`
      })
    );
  }
);

export const taskCreationNotification = createAsyncThunk('notification/task/new', async ({ card }, { dispatch }) => {
  const { canAccessId, managers } = card;

  //console.log({card});

  let canAccessIdManagers = managers?.map((_one) => _one.id) || [];

  let listIds = {};
  const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

  canAccessIds.forEach((id) => (listIds[id] = true));

  const desc = `Vous avez été ajouté à la tâche ${card.name} par 
     ${auth.currentUser.displayName},
      le ${format(new Date(), 'dd MMMM yyyy HH:mm', { locale: frLocale })}
    `;
  const url = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;

  //console.log({ canAccessIds, canAccessIdManagers, canAccessId: card?.canAccessId });

  dispatch(
    createNotification({
      data: {
        title: `Nouvelle tâche`,
        description: desc,
        createdAt: new Date(),
        type: NOTIFICATION_TYPES.TASK,
        isUnRead: listIds,
        canAccess: canAccessIds,
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          projectKey: card?.projectKey || card?.idProject,
          id: card?.id,
          url: url
        }
      }
    })
  );
});

export const taskDeletionNotification = createAsyncThunk(
  'notification/task/delete',
  async ({ cardId }, { dispatch, getState }) => {
    //console.log('taskDeletionNotification', { cardId });
    const {
      kanban: {
        board: { cards: cardList }
      }
    } = getState();
    const card = cardList[cardId];

    const { canAccessId, managers } = card;
    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: `Suppresion de la tache`,
          description: ` "${card.name}" `,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.TASK,
          isUnRead: listIds,
          canAccess: canAccessIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          }
        }
      })
    );
  }
);

export const taskCompletedNotification = ({ card, project, projectName }) => {
  return async (dispatch, getState) => {
    const { users } = getState()?.user;
    const { displayName, uid } = auth.currentUser;
    const thisUser = auth.currentUser;

    // console.log({name: projectName});

    let notifTitle = 'Tâche terminée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_DONE;
    let notifLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `${displayName} a marqué la tâche << ${card?.name} >> du projet << ${projectName} >> comme terminée`;

    // console.log({ description });

    const managers = project?.managers || [];
    const managersId = managers?.map((_one) => _one.id) || [];
    const canAccess = card?.canAccessId;

    const canAccessIds = uniq([...canAccess, ...managersId]);

    let listIds = {};

    const canAccessUsersWithoutMe = canAccessIds
      .map((_id) => users.find((one) => one?.id === _id))
      .filter((one) => one?.id !== uid);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: description,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          other: { projectName },
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card?.projectKey,
            taskId: card?.id,
            url: notifLink
          }
        }
      })
    );

    dispatch(
      createMail({
        users: canAccessUsersWithoutMe,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été marqué comme terminée par ${thisUser?.displayName}`,
        link: notifLink,
        header: `Tâche terminée`
      })
    );
  };
};

export const taskMentionNotification = ({
  canReceived,
  projectName,
  projectKey,
  taskId,
  taskName,
  target = 'dans le commentaire'
}) => {
  return async (dispatch) => {
    let notifTitle = 'Vous avez été mentionné';
    let notifType = NOTIFICATION_TYPES.TASK_MENTION;

    let listIds = {};
    //console.log('mentions -- ', canReceived);
    const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${projectKey}/${taskId}`;

    // console.log({target,taskName});

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Vous avez été mentionné ${target} sur la tâche "` + taskName + '" du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: taskId,
          cardTitle: taskName,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            target,
            projectName,
            projectKey: projectKey,
            taskId,
            id: taskId,
            cardId: taskId,
            detailId: taskId,
            returnId: taskId,
            taskName,
            cardName: taskName,
            url: url
          }
        },
        returnId: taskId
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: canReceived,
        subject: subject,
        description: `Vous avez été mentionné ${target} dans la tâche "${taskName}" du projet "${projectName}" par ${thisUser?.displayName}`,
        link: url,
        header: 'Vous avez été mentionné'
      })
    );
  };
};

export const taskCommentAddNotification = ({ canReceived, projectName, projectKey, taskId, taskName }) => {
  return async (dispatch) => {
    let notifTitle = 'Nouveau commentaire';
    let notifType = NOTIFICATION_TYPES.TASK_COMMENT_ADD;

  // console.log('taskCommentAddNotification', { canReceived, projectName, projectKey, taskId, taskName });

    let listIds = {};

    const canAccessIds = uniq([...canReceived.map((_one) => _one.id)]);

    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${projectKey}/${taskId}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Un nouveau commentaire a été ajouté à la tâche << ${taskName} >>`,
          createdAt: new Date(),
          type: notifType,
          returnId: taskId,
          cardTitle: taskName,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            projectKey,
            id: taskId,
            taskId,
            cardId: taskId,
            detailId: taskId,
            returnId: taskId,
            taskName,
            cardName: taskName,
            url: url
          }
        },
        returnId: taskId
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: canReceived,
        subject: subject,
        description: `Un nouveau commentaire a été ajouté à la tâche << ${taskName} >>  par ${thisUser?.displayName}`,
        link: url,
        header: 'Nouveau commentaire'
      })
    );
  };
};
//#endregion

//#region SUB TASK
/**
 * Dispatches a notification and sends an email when a sub-task is assigned to a user.
 *
 * @param {Object} params - The parameters for the notification.
 * @param {string} params.taskName - The name of the task.
 * @param {string} params.taskId - The ID of the task.
 * @param {string} params.projectId - The ID of the project.
 * @param {string} params.projectName - The name of the project.
 * @param {Object} params.subTask - The sub-task that was assigned.
 * @param {Object} [params.assignee = []] - The user that was assigned the sub-task.
 * @returns {Function} - A thunk function that dispatches the notification and sends the email.
 */
export const subTaskAssignNotification = ({ taskName, taskId, projectId, projectName, subTask, assignee = [] }) => {
  return async (dispatch) => {
    // console.log('subTaskAssignNotification', { taskName, taskId, projectId, projectName, subTask, assignee });

    let notifTitle = 'Une sous tâche vous a été assignée';
    let notifType = NOTIFICATION_TYPES.SUB_TASK_VALIDATION_ASSIGN_USER;

    let listIds = {};
    const canAccessIdManager = assignee?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManager]);

    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${projectId}/${taskId}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Vous avez été ajouté à la sous tâche ${subTask?.name}, de la tâche ${taskName} du project ${projectName}`,
          createdAt: serverTime(),
          type: notifType,
          returnId: taskId,
          cardTitle: subTask?.title,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          other: {
            taskName,
            projectName
          },
          action: {
            projectKey: projectId,
            url: url,
            taskId: taskId
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: assignee,
        subject: subject,
        description: `Une sous tâche vous a été assignée sur la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`,
        link: url,
        header: `Une sous tâche vous a été assignée`
      })
    );
  };
};

/**
 /**
 * Dispatches a notification and creates a mail when a new assignee is added to a sub-task.
 *
 * @param {Object} params - The parameters for the notification and mail.
 * @param {string} params.taskName - The name of the task.
 * @param {string} params.task - 
 * @param {string} params.projectId - The ID of the project.
 * @param {string} params.projectName - The name of the project.
 * @param {Object} params.subTask - The sub-task that has a new assignee.
 * @returns {Function} - A thunk action that dispatches the notification and creates the mail.
 */
export const subTaskNewAssigneNotificationForTaskMember = ({ taskName, task, projectId, projectName, subTask }) => {
  return async (dispatch) => {
    let notifTitle = 'Nouvelle assignation';
    let notifType = NOTIFICATION_TYPES.SUB_TASK_USER_ADDED;
    const { assignee, id: taskId } = task;
    // console.log({ assignee,taskId });

    let listIds = {};
    const canAccessIdManager = assignee?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManager]);

    canAccessIds.forEach((id) => (listIds[id] = true));
    const url = `https://${domaine}/dashboard/task/${projectId}/${taskId}`;

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Une nouvelle assignation sur la sous tâche ${subTask?.name} de la tâche ${taskName} du projet ${projectName}`,
          createdAt: serverTime(),
          type: notifType,
          returnId: taskId,
          cardTitle: subTask?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          other: {
            taskName,
            projectName
          },
          action: {
            projectKey: projectId,
            url: url,
            taskId: taskId
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: assignee,
        subject: subject,
        description: `Une nouvelle assignation a été faite sur la sous tâche ${subTask?.title} de la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`,
        link: url,
        header: `Nouvelle assignation`
      })
    );
  };
};

//#endregion

//#region blog

export const blogCreationNotification = createAsyncThunk('notification/blog/new', async ({ post }, { dispatch }) => {
  dispatch(
    createNotification({
      data: {
        title: post.title,
        description: post.body.substring(0, 30),
        createdAt: new Date(),
        type: NOTIFICATION_TYPES.BLOG,
        isBroadcast: true,
        isUnRead: null,
        canAccess: null,
        action: {
          postId: post.id
        }
      }
    })
  );
});

//#endregion

//#region audience

export const audienceNotification = ({
  audience,
  type = NOTIFICATION_TYPES.AUDIENCE,
  users = [],
  title,
  description
}) => {
  return (dispatch) => {
    const canAccessId = (audience?.personToMeet || []).map((el) => el?.id);
    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title,
          description,
          createdAt: new Date(),
          type,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root,
            audienceId: audience?.id
          }
        }
      })
    );
  };
};
export const audienceCreationNotification = createAsyncThunk(
  'notification/audience/new',
  async ({ data }, { dispatch }) => {
    const { canAccessId } = data;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: "Nouvelle demande d'audience",
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.AUDIENCE,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  }
);

export const audienceUpdateNotification = createAsyncThunk(
  'notification/audience/update',
  async ({ data }, { dispatch }) => {
    const { canAccessId } = data;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: "Maj de la demande d'audience",
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.AUDIENCE,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  }
);

//#endregion

//#region stage

export const stageCreationNotification = createAsyncThunk('notification/stage/new', async ({ data }, { dispatch }) => {
  try {
    const { docs } = await firestore.collection('users').where('roles.stage.read', '==', true).get();

    const canAccessId = [...docs.map((one) => one.id)];

    let shouldRead = {};
    canAccessId.forEach((id) => (shouldRead[id] = true));

    dispatch(
      createNotification({
        data: {
          title: 'Nouvelle demande de stage',
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.STAGE,
          isUnRead: shouldRead,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  } catch (e) {
    console.error(e);
  }
});

//#endregion

//#region chat

export const chatCreationNotification = createAsyncThunk(
  'notification/chat/new',
  async ({ data }, { dispatch, getState }) => {
    try {
      const { conversationId } = data;
      const conversations = getState().firestore.ordered['conversations'];
      const { participants, participantsId, lastMessage = '' } = conversations?.find((el) => el?.id === conversationId);
      const { displayName, uid: currentUserId } = auth.currentUser;
      const isGroup = participants.length > 2;

      const receiverIds = participantsId.filter((one) => one !== currentUserId);

      let shouldRead = {};
      receiverIds.forEach((id) => (shouldRead[id] = true));

      dispatch(
        createNotification({
          data: {
            title: isGroup ? 'Nouveau message' : displayName,
            description: lastMessage,
            createdAt: new Date(),
            type: NOTIFICATION_TYPES.CHAT,
            isUnRead: shouldRead,
            canAccess: receiverIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            action: {
              url: null,
              lastMessage
            }
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const projectConversationNotification = createAsyncThunk(
  'notification/project/chat/new',
  async ({ data }, { dispatch }) => {
    try {
      const { participantIds, lastMessage, projectId, title, description } = data;

      let shouldRead = {};

      participantIds.filter((r) => r !== lastMessage.senderId).forEach((id) => (shouldRead[id] = true));
      // const description = data.message.length > 0 ? data.message : data.attachments.length > 0 ? 'Ajout de piéce joint' : 'Un nouveau message';

      dispatch(
        createNotification({
          data: {
            title: 'Vous avez un nouveau message dans la conversation du projet: <<' + title + ' >>',
            description,
            createdAt: new Date(),
            type: NOTIFICATION_TYPES.PROJECT_CHAT,
            isUnRead: shouldRead,
            canAccess: participantIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            action: {
              projectName: title,
              projectKey: projectId,
              url: projectId
            },
            url: projectId
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const chatForsakeNotification = (notifications) => {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();

      notifications.forEach((one) => {
        const { id, canAccess, isUnRead, ...rest } = one;
        const ref = firestore.collection('notifications').doc(id);
        const currentUserId = auth.currentUser.uid;
        const newUnRead = omit(isUnRead, [currentUserId]);
        const newAccess = reject(canAccess, (id) => id === currentUserId);

        batch.set(ref, { ...rest, isUnRead: newUnRead, canAccess: newAccess });
      });

      await batch.commit();
    } catch (e) {
      console.error(e);
    }
  };
};

//#endregion

//#region RELANCE
export const sendTaskRelanceNotification = ({ sendTo, taskId, projectId, projectName, taskName, callback = null }) => {
  return async (dispatch) => {
    try {
      let notifTitle = 'Vous avez été relancé sur une tâche';
      let notifType = NOTIFICATION_TYPES.RELANCE_TASK;

      let listIds = {};

      const resAccessId = sendTo.map((_one) => _one?.id);
      const canAccessIds = uniq([...resAccessId]);

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${projectId}/${taskId}`;
      const description = `Vous avez été relancé sur la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: taskId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName,
              projectName
            },
            action: {
              projectKey: projectId,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: sendTo,
          subject: subject,
          description: description,
          link: accessLink,
          header: `Vous avez été relancé sur une tâche`
        })
      );

      callback && callback();
    } catch (error) {}
  };
};
//#endregion

/**
 *
 * @param data {{createdAt: Date, isUnRead: string[], canAccessEmail: string[], canAccess: string[], description: string, action: {url: string}, by: {id: string,displayName: string,photoURL: string,}, avatar: string, title: string, type: string}}
 * @param callback {function()}
 * @returns {(function(*): Promise<void>)|*}
 */
export const archiveNotification = (data, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(
        createNotification({
          data: { ...data, type: NOTIFICATION_TYPES.ARCHIVE, createdAt: new Date() },
          callback
        })
      );
    } catch (e) {}
  };
};

export const chatMentionNotification = ({ canReceived, conversationId }) => {
  return async (dispatch) => {
    try {
      let notifTitle = 'Vous avez été mentionné';
      let notifType = NOTIFICATION_TYPES.CHAT_MENTION;

      let listIds = {};
      const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
      const canAccessIds = uniq([...canAccessIdManagers]);

      canAccessIds.forEach((id) => (listIds[id] = true));

      const person = getCurrentUserAccess();

      const data = {
        title: notifTitle,
        description: `Vous avez été mentionné dans une coversation par ${person.name}`,
        createdAt: new Date(),
        type: notifType,
        canAccess: canAccessIds,
        isUnRead: listIds,
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          conversationId,
          userName: person.name
        }
      };
      dispatch(
        createNotification({
          data
        })
      );
    } catch (e) {
      //console.log(e);
      console.trace();
    }
  };
};

export const courielCreationNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une nouvelle affectation a été créée';
      let notifType = NOTIFICATION_TYPES.COURIEL_CREATION;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

    // console.log({ canAccessIds, canAccess });

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `Une nouvelle affectation a été créée ${couriel?.courriel_object} par ${thisUser?.displayName}`;
      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: `Une nouvelle affectation a été créée`
        })
      );

      callback && callback();
    } catch (error) {}
  };
};

export const affectationAssignNotification = ({
  affectation,
  taskId,
  projectId,
  projectName,
  taskName,
  callback = null
}) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez été assigné à une affectation';
      let notifType = NOTIFICATION_TYPES.AFFECTATION_ASSIGN;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = affectation?.defaultReceiver?.map((_one) => _one?.id);
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${affectation?.id}`;
      const description = `Vous avez été assigné à une affectation ${taskName} du projet ${projectName} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: taskId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName,
              projectName
            },
            action: {
              projectKey: projectId,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: `Vous avez été assigné à une affectation`
        })
      );

      callback && callback();
    } catch (error) {}
  };
};

export const courielToDoNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez de nouvelles affectations à traiter';
      let notifType = NOTIFICATION_TYPES.COURIEL_TO_DO;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `Vous avez de nouvelles affectations à traiter ${couriel?.courriel_object} du projet ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: `Vous avez de nouvelles affectations à traiter`
        })
      );

      callback && callback();
    } catch (error) {}
  };
};

export const courielToReviewNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      //console.log({couriel,targetIds})
      let notifTitle = 'Vous avez de nouvelles affectations à valider';
      let notifType = NOTIFICATION_TYPES.COURIEL_TO_REVIEW;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `Vous avez de nouvelles affectations à valider ${couriel?.courriel_object} de l'affectation ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: `Vous avez de nouvelles affectations à valider`
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielCoReceiverNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez de nouvelles affectations en copie';
      let notifType = NOTIFICATION_TYPES.COURIEL_CO_RECEIVER;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${couriel?.id}`;
      const description = `Vous avez de nouvelles affectations en copie ${couriel?.courriel_object} de l'affectation ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${couriel?.id}`,
          header: `Vous avez de nouvelles affectations en copie`
        })
      );

      callback && callback();
    } catch (error) {}
  };
};

export const courielDefaultReceiverNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Les Utilisateurs par défaut ont ete modifié';
      let notifType = NOTIFICATION_TYPES.COURIEL_DEFAULT_RECEIVER;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `Les Utilisateurs par défaut ont ete modifié ${couriel?.courriel_object} du projet ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: `Les Utilisateurs par défaut ont ete modifié`
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielHistoryNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été validée';
      let notifType = NOTIFICATION_TYPES.COURIEL_HISTORY;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été validée par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielArchiveNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été archivée';
      let notifType = NOTIFICATION_TYPES.COURIEL_ARCHIVE;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été archivée par ${thisUser?.displayName}`;
      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielUnArchiveNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été désarchivée';
      let notifType = NOTIFICATION_TYPES.COURIEL_UN_ARCHIVE;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));
      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été désarchivée par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );
      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielTrashNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été mis dans la corbeille';
      let notifType = NOTIFICATION_TYPES.COURIEL_TRASH;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été mis dans la corbeille par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              courielName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielUnTrashNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      //console.log({couriel,targetIds})
      let notifTitle = 'Des affectations ont été restaurées';
      let notifType = NOTIFICATION_TYPES.COURIEL_UN_TRASH;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été restaurée par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );
      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielReJectNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été rejetée';
      let notifType = NOTIFICATION_TYPES.COURIEL_REJECT;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été rejetée par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielDeleteNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été supprimée définitivement';
      let notifType = NOTIFICATION_TYPES.COURIEL_DELETE;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));
      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été supprimée définitivement par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielAssignedNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez reçu une assignation';
      let notifType = NOTIFICATION_TYPES.COURIEL_ASSIGNED;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `Vous avez reçu une assignation de ${
        thisUser?.displayName || thisUser?.email
      } pour l'affectation N° ${couriel?.save_reference}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const courielAddAttachmentNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'De nouvelles pièces jointes ont été ajoutées';
      let notifType = NOTIFICATION_TYPES.COURIEL_ADD_ATTACHMENT;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affectation/${couriel?.id}`;
      const description = `De nouvelles pièces jointes ont été ajoutées à l'affectation N° ${couriel?.save_reference} par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference
            },
            action: {
              projectKey: couriel?.id,
              url: accessLink
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

//#region DOCUMENTS

/**
 * Dispatches a notification and creates a mail when a user is added to a shared document.
 *
 * @param {Object} item - The shared document.
 * @param {Array} [targetIds=[]] - The IDs of the users who can access the shared document.
 * @param {Function} [callback] - An optional callback function to be executed after the notification and mail are created.
 * @returns {Function} - A thunk function that dispatches the notification and creates the mail.
 */
export const documentSharedNotification = ({ item, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez été ajouté à un document partagé';
      let notifType = NOTIFICATION_TYPES.DOCUMENT_SHARED;
      const notifUrl = `https://${domaine}/doc/share-with-me/${item?.id}`;
      const { users } = getState().user;

      let listIds = {};
      //console.log({item});

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Vous avez été ajouté à un document partagé par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId
            },
            action: {
              projectKey: item?.id,
              type: item?.type,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

export const filesSharedNotification = ({ item, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez été ajouté à un fichier partagé';
      let notifType = NOTIFICATION_TYPES.FILES_SHARED;
      const notifUrl = `https://${domaine}/doc/share-with-me`;
      const { users } = getState().user;

      let listIds = {};
      //console.log({item});

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Vous avez été ajouté à un fichier partagé par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId
            },
            action: {
              projectKey: item?.id,
              type: item?.type,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Dispatches a notification and sends an email when a user leaves a shared document.
 *
 * @param {Object} item - The document item that was shared.
 * @param {Array} [targetIds=[]] - An array of user IDs who have access to the document.
 * @param {Function} [callback] - An optional callback function to be executed after the notification and email are sent.
 * @returns {Function} - A thunk function that can be dispatched to execute the notification and email sending logic.
 */
export const documentSharedUserLeaveNotification = ({ item, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Un utilisateur a quitté votre document partagé';
      let notifType = NOTIFICATION_TYPES.DOCUMENT_SHARED_USER_LEAVE;
      const notifUrl = `https://${domaine}/doc/folders/${item?.id}`;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `L'utilisateur ${thisUser?.displayName} a quitté votre document partagé ${item?.name}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId
            },
            action: {
              projectKey: item?.id,
              type: item?.type,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Dispatches a notification and sends an email when a file is added to a folder.
 *
 * @param {Object} item - The file that was added to the folder.
 * @param {string} parentFolderName - The name of the folder where the file was added.
 * @param {string} parentFolderId - The ID of the folder where the file was added.
 * @param {Array} [targetIds=[]] - An array of user IDs to receive the notification.
 * @param {Function} [callback] - An optional callback function to be executed after the notification is dispatched.
 * @returns {Function} - A thunk function that dispatches the notification and email actions.
 */
export const addFileToFolderNotification = ({ item, parentFolderName, parentFolderId, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
  // console.log({item,parentFolderName,targetIds});
    try {
      let notifTitle = 'Ajout de nouveaux fichiers';
      let notifType = NOTIFICATION_TYPES.ADD_FILE_TO_FOLDER;
      const notifUrl = `https://${domaine}/doc/folders/${parentFolderId}`;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Le fichier << ${item?.name} >> a été ajouté au dossier << ${parentFolderName} >> par << ${thisUser?.displayName} >>`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId,
              parentFolderName,
              parentFolderId
            },
            action: {
              projectKey: item?.id,
              url: notifUrl,
              parentFolderId,
              fileId: item?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Dispatches a notification and sends an email when a folder is added to another folder.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.item - The folder that was added.
 * @param {string} params.parentFolder - The name of the folder where the folder was added.
 * @param {Array} [params.targetIds=[]] - The IDs of the users who have access to the folder.
 * @param {function} [params.callback] - An optional callback function to be executed after the notification and email are sent.
 * @returns {function} - A thunk function that dispatches the notification and email actions.
 */
export const addFolderToFolderNotification = ({ item, parentFolder, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Ajout d’un nouveau dossier';
      let notifType = NOTIFICATION_TYPES.ADD_FOLDER_TO_FOLDER;
      const notifUrl = `https://${domaine}/doc/folders/${item?.id}`;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Le dossier ${item?.name} a été ajouté au dossier ${parentFolder.name} par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId
            },
            action: {
              projectKey: item?.id,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Dispatches a notification and sends an email when an authorization request for an element is accepted.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.element - The element for which the authorization was accepted.
 * @param {Array} [params.targetIds=[]] - The IDs of the users who have access to the element.
 * @param {string} params.autorisationType - The type of authorization that was accepted.
 * @param {function} [params.callback] - An optional callback function to be executed after the notification and email are sent.
 * @returns {function} - A thunk function that dispatches the notification and email actions.
 */
export const sendAutorizationForElementNotification = ({ item, targetIds = [], autorisationType, callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = "Vous avez reçu une demande d'autorisation";
      let notifType = NOTIFICATION_TYPES.SEND_AUTORIZATION_FOR_ELEMENT;
      const notifUrl = `https://${domaine}/doc/auth-request`;

      const { users } = getState().user;

      let listIds = {};

    // console.log({ autorisationType });

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Vous avez reçu une demande d'autorisation de << ${AUTHORIZATION_TYPE_CONVERTER[autorisationType]} >> pour le document << ${item?.name}>> de la part de ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId
            },
            action: {
              projectKey: item?.id,
              type: item?.type,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Dispatches a notification and sends an email when an authorization request for an element is resent.
 *
 * @param {Object} item - The element for which the authorization request was resent.
 * @param {Array} [targetIds=[]] - The IDs of the users who have access to the element.
 * @param {string} autorisationType - The type of authorization that was requested.
 * @param {Function} [callback] - An optional callback function to be executed after the notification and email are sent.
 * @returns {Function} - A thunk function that dispatches the notification and email actions.
 */
export const resendAutorizationForElementNotification = ({ item, targetIds = [], autorisationType, callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = "Vous avez reçu une relance de demande d'autorisation";
      let notifType = NOTIFICATION_TYPES.RESEND_AUTORIZATION_FOR_ELEMENT;
      const notifUrl = `https://${domaine}/doc/auth-request`;
      const { users } = getState().user;

      let listIds = {};

      //console.log({item});

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Vous avez reçu une relance de demande d'autorisation de << ${AUTHORIZATION_TYPE_CONVERTER[autorisationType]} >> pour le document << ${item?.docName} >> de la part de ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.docId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.docName,
              documentReference: item?.docId
            },
            action: {
              projectKey: item?.docId,
              type: item?.type,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Dispatches a notification and sends an email when an authorization request for an element is accepted.
 *
 * @param {Object} element - The element for which the authorization request was accepted.
 * @param {Array} [targetIds=[]] - The IDs of the users who have access to the element.
 * @param {string} autorisationType - The type of authorization that was granted.
 * @param {Function} [callback] - An optional callback function to be executed after the notification and email are sent.
 * @returns {Function} - A thunk function that dispatches the notification and email actions.
 */
export const acceptedAutorizationForElementNotification = ({ element, targetIds = [], autorisationType, callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = "Votre demande d'autorisation a été acceptée";
      let notifType = NOTIFICATION_TYPES.ACCEPTED_AUTORIZATION_FOR_ELEMENT;
      const notifUrl = `https://${domaine}/doc/auth-request?type=send`;
      const { users } = getState().user;

      let listIds = {};
      //console.log({element});

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Votre demande d'autorisation de << ${AUTHORIZATION_TYPE_CONVERTER[autorisationType]} >> pour le document << ${element?.docName} >> a été acceptée par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: element?.docId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: element?.docName,
              documentReference: element?.docId
            },
            action: {
              projectKey: element?.docId,
              type: element?.type,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Dispatches actions to create a notification and send an email when an authorization request for a document is rejected.
 *
 * @param {Object} element - The document for which the authorization request was rejected.
 * @param {Array} [targetIds=[]] - An array of user IDs who can access the document.
 * @param {string} autorisationType - The type of authorization that was requested.
 * @param {Function} [callback] - An optional callback function to be executed after the actions are dispatched.
 * @returns {Function} - A thunk function that dispatches the necessary actions.
 */
export const rejectedAutorizationForElementNotification = ({ element, targetIds = [], autorisationType, callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = "Votre demande d'autorisation a été rejetée";
      let notifType = NOTIFICATION_TYPES.REJECTED_AUTORIZATION_FOR_ELEMENT;
      const notifUrl = `https://${domaine}/doc/auth-request?type=send`;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Votre demande d'autorisation de << ${AUTHORIZATION_TYPE_CONVERTER[autorisationType]} >> pour le document << ${element?.docName} >> a été rejetée par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: element?.docId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: element?.docName,
              documentReference: element?.docId
            },
            action: {
              projectKey: element?.docId,
              type: element?.type,
              url: notifUrl
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: notifUrl,
          header: notifTitle
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
};

//#endregion
